angular.module('eOpti.app.optometrist').controller('optometristAccommodationController', [
    '$scope',
    function ($scope) {
        $scope.power_flipper = [{id: 0, name: '-'}, {id: 3, name: '+-3,00'}, {id: 2.5, name: '+-2,50'}, {id: 2, name: '+-2,00'},
            {id: 1.75, name: '+-1,75'}, {id: 1.5, name: '+-1,50'}, {id: 1.25, name: '+-1,25'}, {id: 1, name: '+-1,00'},
            {id: 0.75, name: '+-0,75'}, {id: 0.5, name: '+-0,50'}, {id: 0.25, name: '+-0,25'}];

        $scope.efficiency = [{id: 20, name: '> 20 cykli/min'}, {id: 19, name: '19 cykli/min'}, {id: 18, name: '18 cykli/min'},
            {id: 17, name: '17 cykli/min'}, {id: 16, name: '16 cykli/min'},  {id: 15, name: '15 cykli/min'}, {id: 14, name: '14 cykli/min'},
            {id: 14, name: '14 cykli/min'}, {id: 13, name: '13 cykli/min'}, {id: 12, name: '12 cykli/min'}, {id: 11, name: '11 cykli/min'},
            {id: 10, name: '10 cykli/min'}, {id: 9, name: '9 cykli/min'}, {id: 8, name: '8 cykli/min'}, {id: 7, name: '7 cykli/min'},
            {id: 6, name: '6 cykli/min'}, {id: 5, name: '5 cykli/min'},  {id: 4, name: '4 cykli/min'}, {id: 3, name: '3 cykli/min'},
            {id: 2, name: '2 cykli/min'}, {id: 1, name: '1 cykli/min'}, {id: 0, name: '- cykli/min'}];
    }
]);