angular.module('eOpti.app.specifications').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.specifications', {
            abstract: true,
            url: '/specifications',
            template: '<ui-view/>'
        })
        .state('app.specifications.regeneration', {
            url: '/regenerations/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/specifications/regenerations/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationRegeneration',
                    args: [null]
                }]
            }]
        })
        .state('app.specifications.regenerationAdd', {
            url: '/regeneration/add',
            template: '<opti-regeneration-specification-create></opti-regeneration-specification-create>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationRegeneration',
                    args: ['state']
                }, {
                    name: 'specification.breadcrumb.CREATE'
                }]
            }]
        })
        .state('app.specifications.regenerationDetails', {
            url: '/regeneration/details/:id',
            template: '<opti-regeneration-specification></opti-regeneration-specification>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationRegeneration',
                    args: ['state']
                }, {
                    name: 'specification.breadcrumb.SPECIFICATION',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.specifications.sales', {
            url: '/sales/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/specifications/sales/table.html',
            controller: [ 'breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationSale',
                    args: [null]
                }]
            }]
        })
        .state('app.specifications.salesDetails', {
            url: '/sale/details/:id',
            template: '<opti-sale-specification></opti-sale-specification>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationSale',
                    args: ['state']
                },  {
                    name: 'specification.breadcrumb.SPECIFICATION',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.specifications.salesCreate', {
            url: '/sale/create',
            template: '<opti-sale-specification-create></opti-sale-specification-create>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationSale',
                    args: ['state']
                }, {
                    name: 'specification.breadcrumb.CREATE'
                }]
            }]
        })
        .state('app.specifications.tasks', {
            url: '/tasks/:page',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/specifications/tasks/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationTask',
                    args: [null]
                }]
            }]
        })
        .state('app.specifications.tasksDetails', {
            url: '/task/details/:id',
            template: '<opti-task-specification></opti-task-specification>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationTask',
                    args: ['state']
                },  {
                    name: 'specification.breadcrumb.SPECIFICATION',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.specifications.tasksCreate', {
            url: '/task/create',
            template: '<opti-task-specification-create></opti-task-specification-create>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    functions: 'specificationTask',
                    args: ['state']
                }, {
                    name: 'specification.breadcrumb.CREATE'
                }]
            }]
        })
        .state('app.specifications.turnBack', {
            url: '/turnBack',
            template: '<opti-task-turn-back-specification></opti-task-turn-back-specification>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'specification.breadcrumb.SPECIFICATIONS'
                }, {
                    name: 'specification.breadcrumb.TURNBACKSPECIFICATION'
                }]
            }]
        })
}])
