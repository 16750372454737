angular.module('eOpti.app.sms.template').controller('smsTemplateListController', [
    'breadcrumb',
    function (breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'settingsList'
        }, {
            name: 'sms.template.breadcrumb.SMS'
        }, {
            functions: 'smsTemplateList',
            args: ['nostate']
        }];

    }]);