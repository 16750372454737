angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.bonus', {
            url: '/bonus',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.bonus.show', {
            url: '/show/:department_id',
            template: '<bonus-show></bonus-show>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'bonus.breadcrumb.BONUS'
                }]
            }],
            params: {
                department_id: '0'
            }
        })
        .state('app.dictionary.bonus.edit', {
            url: '/edit',
            template: '<opti-bonus-edit></opti-bonus-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'bonus.breadcrumb.BONUS'
                }]
            }]
        })
}])
