angular.module('eOpti.app.departments').controller('departmentsListController', [
    '$scope', 'uac', 'breadcrumb',
    function ($scope, uac, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'departments',
            args: [null]
        }];

        $scope.uac = uac
    }])