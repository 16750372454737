angular.module('eOpti.app.bookkeeping').controller('bookkeepingOrderToLensDownloadController', [
    '$scope', '$http', '$state', 'breadcrumb',
    function ($scope, $http, $state, breadcrumb) {
        breadcrumb.data = [{
            name: 'bookkeeping.breadcrumb.REGISTER'
        }, {
            name: 'bookkeeping.breadcrumb.BOOKKEEPING'
        }, {
            name: 'bookkeeping.breadcrumb.ORDERTOLENS'
        }, {
            name: 'bookkeeping.breadcrumb.DOWNLOAD',
            id: $state.params.id
        }];

        $http.get('api/bookkeeping/order-to-lens/' + $state.params.id).then(function(res) {
            window.location = 'upload/bookkeeping/orderlens/' + res.data + '.xls';

            $state.go('app.bookkeeping.order-to-lens.table');
        }, function() {
            $scope.$emit('error', 'bookkeeping.notify.error.DOWNLOAD');

            $state.go('app.bookkeeping.order-to-lens.table');
        });

    }]);
