angular.module('eOpti.app.optometrist').controller('lensOptometristController', [
    '$scope', '$http', '$enum', '$state', '$filter', 'ENV',
    function ($scope, $http, $enum, $state, $filter, ENV) {
        $scope.uploadHost = ENV.uploadHost

        $scope.matching = []

        $http.post('api/optometrist/interview/contact-lens-from-patient', {
            patient_id: $scope.interview.patient_id,
            interview_id: $scope.interview.id
        }).then(res => {
            $scope.matching = res.data
        }, () => {
            $scope.$emit('error', 'optometrist.contactlens.lens.notify.error.MATCHING');
        })

        $scope.allow = {
            surface_on_the_eye: false,
            time: false,
            test_schirmer: false
        };

        $http.get('api/settings').then(function(res) {
            $scope.allow.surface_on_the_eye = res.data.contact_lens_surface_on_the_eye;
            $scope.allow.time = res.data.contact_lens_time;
            $scope.allow.test_schirmer = res.data.contact_lens_test_schirmer;
        }, function() {
            $scope.$emit('error', 'default.notify.error.SETTINGS');
        });

        $scope.contactLens = {
            type: {
                r: [],
                l: []
            },
            product: {
                r: [],
                l: []
            }
        };

        $scope.forbitten = {
            r_efron_min_2: false,
            l_efron_min_2: false
        };

        $scope.efronPoints = function(side) {
            var points = 0;

            $scope.forbitten[side + '_efron_min_2'] = false;

            angular.forEach($scope.interview.efron, function(efron) {
                points += efron.pivot[side];

                if(efron.pivot[side] >= 2) {
                    $scope.forbitten[side + '_efron_min_2'] = true;
                }
            });

            if(points) {
                $scope.interview.contactLens['efron_' + side + '_image'] = '';
            }

            return points;
        };

        $scope.eyeDiseases = function(side) {
            var _return = [];

            angular.forEach($scope.interview.contactLensDiseases, function(disease) {
                var zone = [];

                angular.forEach([1, 2, 3, 4, 5, 6 ,7, 8, 9], function(i) {
                    if(disease.pivot[side + '_zone_' + i]) {
                        zone.push(i);
                    }
                });

                if(zone.length) {
                    _return.push(disease.name + ' - ' + zone.join(', '));
                }
            });

            return _return;
        };

        var calculate = {
            round: function(power) {
                if(!power || power % 0.25 === 0) {
                    return power;
                }

                var rest = power % 0.25;

                if(rest > 0) {
                    if (rest >= 0.125) {
                        return power + (0.25 - rest);
                    } else {
                        return power - rest;
                    }
                } else {
                    rest = Math.abs(rest);

                    if(rest >= 0.125) {
                        return power - (0.25 - rest);
                    } else {
                        return power + rest;
                    }
                }
            },
            sph: function(sph, cyl) {
                if(!$scope.interview.contactLens.toric) { // jak sferyczne to es
                    if (cyl * 50 % 1 == 0) {
                        sph = sph + cyl / 2;
                    } else {
                        sph = sph + (cyl + 0.25) / 2;
                    }
                }

                return calculate.round(sph / (1 + -0.011 * sph))
                //to jest wartość BVD 11 wzięta ze strony https://coopervision.pl/specjalista/narzedzia-i-kalkulatory/optiexpert/optiexpert-web#/calculator
            },
            cyl: function(sph, cyl) {
                var plus = {
                    sph: 0,
                    cyl: 0
                },
                minus = {
                    sph: 0,
                    cyl: 0
                };

                if(cyl === 0 || !$scope.interview.contactLens.toric) {
                    return 0;
                } else if(cyl > 0) {
                    plus.sph = sph;
                    plus.cyl = cyl;

                    minus.sph = plus.sph + cyl;
                    minus.cyl *= -1;

                    plus.sph = calculate.sph(plus.sph, plus.cyl);
                    minus.sph = calculate.sph(minus.sph, minus.cyl);

                    return minus.sph - plus.sph;
                } else if(cyl < 0) {
                    minus.sph = sph;
                    minus.cyl = cyl;

                    plus.sph = minus.sph + cyl;
                    plus.cyl *= -1;

                    minus.sph = calculate.sph(minus.sph, minus.cyl);
                    plus.sph = calculate.sph(plus.sph, plus.cyl);

                    return plus.sph - minus.sph;
                }
            }, postPowers: function() {
                var r_type = null,
                    l_type = null;

                if($scope.interview.contactLens.r_type === 2) {
                    r_type = 'near';
                } else if($scope.interview.contactLens.r_type === 3) {
                    r_type = 'dist';
                }

                if($scope.interview.contactLens.l_type === 2) {
                    l_type = 'near';
                } else if($scope.interview.contactLens.l_type === 3) {
                    l_type = 'dist';
                }

                return {
                    toric: $scope.interview.contactLens.toric,

                    r_sph: r_type ? $scope.interview.contactLens.powers[r_type + '_r_sph'] : 0,
                    r_cyl: r_type ? $scope.interview.contactLens.powers[r_type + '_r_cyl'] : 0,
                    r_axs: r_type ? $scope.interview.contactLens.powers[r_type + '_r_axs'] : 0,

                    l_sph: l_type ? $scope.interview.contactLens.powers[l_type + '_l_sph'] : 0,
                    l_cyl: l_type ? $scope.interview.contactLens.powers[l_type + '_l_cyl'] : 0,
                    l_axs: l_type ? $scope.interview.contactLens.powers[l_type + '_l_axs'] : 0
                }
            }, set: function() {
                var before = {
                    dist: {
                        r: {
                            sph: $scope.interview.dist_r_sphere,
                            cyl: $scope.interview.dist_r_cylinder,
                            axs: $scope.interview.dist_r_axis
                        },
                        l: {
                            sph: $scope.interview.dist_l_sphere,
                            cyl: $scope.interview.dist_l_cylinder,
                            axs: $scope.interview.dist_l_axis
                        }
                    },
                    near: {
                        r: {
                            sph: $scope.interview.near_r_sphere,
                            cyl: $scope.interview.near_r_cylinder,
                            axs: $scope.interview.near_r_axis
                        },
                        l: {
                            sph: $scope.interview.near_l_sphere,
                            cyl: $scope.interview.near_l_cylinder,
                            axs: $scope.interview.near_l_axis
                        }
                    }
                };

                $scope.correction.matches = {
                    dist: {
                        r: {
                            sph: calculate.sph(before.dist.r.sph, before.dist.r.cyl),
                            cyl: calculate.cyl(before.dist.r.sph, before.dist.r.cyl)
                        },
                        l: {
                            sph: calculate.sph(before.dist.l.sph, before.dist.l.cyl),
                            cyl: calculate.cyl(before.dist.l.sph, before.dist.l.cyl)
                        }
                    },
                    near: {
                        r: {
                            sph: calculate.sph(before.near.r.sph, before.near.r.cyl),
                            cyl: calculate.cyl(before.near.r.sph, before.near.r.cyl)
                        },
                        l: {
                            sph: calculate.sph(before.near.l.sph, before.near.l.cyl),
                            cyl: calculate.cyl(before.near.l.sph, before.near.l.cyl)
                        }
                    }
                };

                if(!$scope.interview.contactLens.toric) {
                    $scope.interview.contactLens.r_rotation = 0;
                    $scope.interview.contactLens.l_rotation = 0;

                    $scope.interview.contactLens.powers.dist_r_cyl = 0;
                    $scope.interview.contactLens.powers.dist_r_axs = 'brak';
                    $scope.interview.contactLens.powers.dist_l_cyl = 0;
                    $scope.interview.contactLens.powers.dist_l_axs = 'brak';

                    $scope.interview.contactLens.powers.near_r_cyl = 0;
                    $scope.interview.contactLens.powers.near_r_axs = 'brak';
                    $scope.interview.contactLens.powers.near_l_cyl = 0;
                    $scope.interview.contactLens.powers.near_l_axs = 'brak';
                }

                $http.post('api/optometrists/contact-lens/products', calculate.postPowers()).then(function (res) {
                    $scope.contactLens.product = res.data;

                    var product_r_exists = false,
                        product_l_exists = false;

                    angular.forEach($scope.contactLens.product.r, function(lens) {
                        if(lens.id === $scope.interview.contactLens.r_product_id) {
                            product_r_exists = true;
                        }
                    });

                    angular.forEach($scope.contactLens.product.l, function(lens) {
                        if(lens.id === $scope.interview.contactLens.l_product_id) {
                            product_l_exists = true;
                        }
                    });

                    if(!product_r_exists) {
                        $scope.interview.contactLens.r_product_id = 0;
                        $scope.interview.contactLens.r_product_name = '';
                    }

                    if(!product_l_exists) {
                        $scope.interview.contactLens.l_product_id = 0;
                        $scope.interview.contactLens.l_product_name = '';
                    }
                }, function (error) {
                    $scope.$emit('error', 'optometrist.contactlens.lens.notify.error.LOADPRODUCT');
                });
            }
        };

        $scope.calculate = calculate;

        $scope.$watchGroup(['interview.dist_r_sphere', 'interview.dist_r_cylinder', 'interview.dist_r_axis',
            'interview.dist_l_sphere', 'interview.dist_l_cylinder', 'interview.dist_l_axis',
            'interview.near_r_sphere', 'interview.near_r_cylinder', 'interview.near_r_axis',
            'interview.near_l_sphere', 'interview.near_l_cylinder', 'interview.near_l_axis'], function(powers, old) {
            if(!(old[1] || old[4] || old[7] || old[10]) && (powers[1] || powers[4] || powers[7] || powers[10])) {
                $scope.interview.contactLens.toric = true;

                calculate.set();
            } else if((old[1] || old[4] || old[7] || old[10]) && !(powers[1] || powers[4] || powers[7] || powers[10])) {
                $scope.interview.contactLens.toric = false;

                calculate.set();
            }
        });

        let prefix_osdi = 'optometrist.tips.osdi.'

        $scope.tips_osdi = {
            info: [
                prefix_osdi + 'info.' + 1
            ],
            instruction: [
                prefix_osdi + 'instruction.' + 1
            ],
            procedure: [
                prefix_osdi + 'procedure.' + 1
            ]
        }

        $scope.$watch('interview.dist_r_add', function(add) {
            $enum.get('InterviewContactLensType').then(function(res) {
                $scope.contactLens.type.r = res;

                if(!add) {
                    $scope.contactLens.type.r.pop();

                    if($scope.interview.contactLens.r_type === 4) {
                        $scope.interview.contactLens.r_type = 0;
                    }
                }
            });
        });

        $scope.$watch('interview.dist_l_add', function(add) {
            $enum.get('InterviewContactLensType').then(function(res) {
                $scope.contactLens.type.l = res

                if(!add) {
                    $scope.contactLens.type.l.pop()

                    if($scope.interview.contactLens.l_type === 4) {
                        $scope.interview.contactLens.l_type = 0
                    }
                }
            })
        })

        $scope.$watchGroup(['interview.contactLens.r_type', 'interview.contactLens.l_type'], function(types, oldTypes) {
            if(types[0] !== oldTypes[0] || types[1] !== oldTypes[1]) {
                calculate.set();

                if (types[0] === 0 || types[0] === 1) {
                    $scope.interview.contactLens.r_product_id = 0
                } else if (types[0] === 4) {
                    $scope.interview.contactLens.r_product_id = 1000000
                }

                if (types[1] === 0 || types[1] === 1) {
                    $scope.interview.contactLens.l_product_id = 0
                } else if (types[1] === 4) {
                    $scope.interview.contactLens.l_product_id = 1000000
                }
            }
        })

        $scope.$watch('interview.contactLens.r_product_id', function(id, old) {
            if(id > 0 && id < 1000000) {
                angular.forEach($scope.contactLens.product.r, function(product) {
                    if(product.id == id) {
                        $scope.interview.contactLens.r_product_name = product.name
                    }
                })
            }

            if(id !== old && (!id || id === 1000000)) {
                $scope.interview.contactLens.r_product_name = ''
            }
        })

        $scope.$watch('interview.contactLens.l_product_id', function(id, old) {
            if(id > 0 && id < 1000000) {
                angular.forEach($scope.contactLens.product.l, function(product) {
                    if(product.id == id) {
                        $scope.interview.contactLens.l_product_name = product.name
                    }
                })
            }

            if(id !== old && (!id || id === 1000000)) {
                $scope.interview.contactLens.l_product_name = ''
            }
        })

        $scope.scope_axs = (product_id, cyl) => { // to samo w OptiOptometristsDetailsLensCalculator.vue
            if([25360, 32439].includes(product_id) && [-0.75, -1.25, -1.75, -2.25].includes(cyl)) {
                return ['brak'].concat(_.range(0, 180 + 1, 10))
            }

            return []
        }

        let closest = (tab, num) => {
            let curr = tab[0],
                diff = Math.abs(num - curr)

            for(let val = 0; val < tab.length; val++) {
                let newdiff = Math.abs(num - tab[val])

                if(newdiff < diff) {
                    diff = newdiff
                    curr = tab[val]
                }
            }
            return curr
        }, change_cyl = side => {
            ['near', 'dist'].forEach(dist => {
                if(($scope.interview.contactLens[side + '_product_id'] === 25360 || $scope.interview.contactLens[side + '_product_id'] === 32439) &&
                    ![-2.25, -1.75, -1.25, -0.75].includes($scope.interview.contactLens.powers[dist + '_' + side + '_cyl'])) {
                    $scope.interview.contactLens.powers[dist + '_' + side + '_cyl'] =
                        closest([-2.25, -1.75, -1.25, -0.75], $scope.interview.contactLens.powers[dist + '_' + side + '_cyl'])
                }
            })
        }, parse_axs = tab => {
            if(tab.length) {
                let _return = []

                for(let i = 1; i < tab.length - 1; ++i) {
                    _return[i - 1] = parseInt(tab[i])
                }

                return _return
            }

            return []
        }, change_axs = side => {
            ['dist', 'near'].forEach(dist => {
                let axs = $scope.scope_axs($scope.interview.contactLens[side + '_product_id'], $scope.interview.contactLens.powers[dist + '_' + side + '_cyl'])

                if($scope.interview.contactLens[side + '_product_id'] !== 25360 && $scope.interview.contactLens[side + '_product_id'] !== 32439) {
                    $scope.interview.contactLens.powers[dist + '_' + side + '_axs'] = $scope.interview[dist + '_' + side + '_axis']
                } else if(axs.length) {
                    $scope.interview.contactLens.powers[dist + '_' + side + '_axs'] =
                        closest(parse_axs(axs), $scope.interview[dist + '_' + side + '_axis']).toString()
                } else {
                    $scope.interview.contactLens.powers[dist + '_' + side + '_axs'] = 'brak'
                }
            })
        }

        $scope.changeDistance = side => {
            let opposite = side === 'r' ? 'l' : 'r'

            if(!$scope.interview.contactLens[opposite + '_type']) {
                $scope.interview.contactLens[opposite + '_type'] = $scope.interview.contactLens[side + '_type']
            }
        }

        let getCurrentName = (site, id) => {
            let _return = ''

            angular.forEach($scope.contactLens.product[site], product => {
                if(product.id === id) {
                    _return = product.name
                }
            })

            return _return
        }, createOppositeName = site => {
            let opposite = site === 'r' ? 'l' : 'r',
                current_name = getCurrentName(site, $scope.interview.contactLens[site + '_product_id']),
                powers = calculate.postPowers()

            if(powers[opposite + '_sph']) {
                return current_name.replace($filter('number')(powers[site + '_sph'], 2), $filter('number')(powers[opposite + '_sph'], 2))
            }

            return current_name
        }

        $scope.changeProduct = side => {
            change_cyl(side)
            change_axs(side)

            let opposite = side === 'r' ? 'l' : 'r'

            if(!$scope.interview.contactLens[opposite + '_product_id']) {
                let opposite_name = createOppositeName(side),
                    _exists = false

                angular.forEach($scope.contactLens.product[opposite], contactLens => {
                    if(contactLens.name === opposite_name) {
                        _exists = contactLens.id
                    }
                })

                if(_exists) {
                    $scope.interview.contactLens[opposite + '_product_id'] = _exists

                    change_cyl(opposite)
                    change_axs(opposite)
                }
            }
        }

        $scope.next = () => {
            $scope.interview.contactLens.r_product_id = 0
            $scope.interview.contactLens.r_product_name = ''
            $scope.interview.contactLens.r_product_order = false
            $scope.interview.contactLens.r_provided_contact_lens = false

            $scope.interview.contactLens.l_product_id = 0
            $scope.interview.contactLens.l_product_name = ''
            $scope.interview.contactLens.l_product_order = false
            $scope.interview.contactLens.l_provided_contact_lens = false

            $scope.interview.contactLens.matching = 0

            $scope.interview.contactLens.comfort_rating = -1

            $scope.interview.contactLens.powers.dist_r_visus = '-'
            $scope.interview.contactLens.powers.dist_l_visus = '-'
            $scope.interview.contactLens.powers.dist_r_cz = '0'
            $scope.interview.contactLens.powers.dist_l_cz = '0'
            $scope.interview.contactLens.powers.dist_binvisus = '-'

            $scope.interview.contactLens.powers.near_r_visus = '-'
            $scope.interview.contactLens.powers.near_l_visus = '-'
            $scope.interview.contactLens.powers.near_binvisus = '-'
        }

        $scope.disabledSurvey = () => { // ankieta OSDI, efron, choroby
            return ((!$scope.interview.contactLens.order_contact_lens || $scope.interview.status !== 3 ||
                $scope.interview.contactLens.r_product_order || $scope.interview.contactLens.l_product_order) &&
                !$scope.interview.contactLens.control_visit) || $scope.interview.contactLens.matching > 0 || !$scope.interview.contactLens.order_contact_lens;
        };

        $scope.disabledFieldRewrite = side => { // pola wpisane moce i pola rodzaj soczewki
            return $scope.interview.contactLens[side + '_product_order'] || !$scope.interview.contactLens.order_contact_lens ||
                $scope.interview.contactLens.r_provided_contact_lens || $scope.interview.contactLens.l_provided_contact_lens ||
                !$scope.interview.contactLens.order_contact_lens;
        };

        $scope.disabledOrder = side => { // przycisk zamawiam
            return $scope.interview.contactLens[side + '_product_order'] || !$scope.interview.contactLens.order_contact_lens ||
                !$scope.interview.contactLens[side + '_product_id'] ||
                ($scope.interview.contactLens[side + '_product_id'] === 1000000 && !$scope.interview.contactLens[side + '_product_name']) ||
                ($scope.interview.contactLens.powers['dist_' + side + '_cyl'] && $scope.interview.contactLens.powers['dist_' + side + '_axs'] === 'brak') ||
                ($scope.interview.contactLens.powers['near_' + side + '_cyl'] && $scope.interview.contactLens.powers['near_' + side + '_axs'] === 'brak') ||
                $scope.interview.contactLens.r_provided_contact_lens || $scope.interview.contactLens.l_provided_contact_lens ||
                !$scope.interview.contactLens.order_contact_lens;
        };

        $scope.disabledProductSelect = side => { // pola do wyboru produktów
            return $scope.interview.contactLens[side + '_product_order'] || !$scope.interview.contactLens.order_contact_lens ||
                $scope.interview.contactLens[side + '_type'] === 0 || $scope.interview.contactLens[side + '_type'] === 1 ||
                $scope.interview.contactLens[side + '_type'] === 4 ||
                $scope.interview.contactLens.r_provided_contact_lens || $scope.interview.contactLens.l_provided_contact_lens;
        };

        $scope.disabledProductInput = side => { // pola do wpisania produktów
            return $scope.interview.contactLens[side + '_product_order'] || !$scope.interview.contactLens.order_contact_lens ||
                $scope.interview.contactLens[side + '_product_id'] !== 1000000 ||
                $scope.interview.contactLens.r_provided_contact_lens || $scope.interview.contactLens.l_provided_contact_lens ||
                !$scope.interview.contactLens.order_contact_lens;
        };

        $scope.disabledCentration = () => {
            return ((!$scope.interview.contactLens['r_provided_contact_lens'] && !$scope.interview.contactLens['l_provided_contact_lens'])
                && !$scope.interview.contactLens.control_visit) || $scope.interview.contactLens.matching > 0 || !$scope.interview.contactLens.order_contact_lens;
        };

        $scope.matchLens = () => { // soczewki dopasowane
            let prefix = 'optometrist.contactlens.matchlens.',
                change = true

            if(!$scope.interview.contactLens.order_contact_lens) {
                $scope.$emit('error', prefix + 'ORDER')

                change = false
            }

            if(!$scope.interview.contactLens.r_centration || !$scope.interview.contactLens.l_centration ||
                !$scope.interview.contactLens.r_movable || !$scope.interview.contactLens.l_movable ||
                ($scope.interview.contactLens.toric && !($scope.interview.contactLens.r_rotation && $scope.interview.contactLens.l_rotation)) ||
                !$scope.interview.contactLens.r_cover || !$scope.interview.contactLens.l_cover) {
                $scope.$emit('error', prefix + 'MATCH')

                change = false
            }

            if($scope.interview.contactLens.powers.dist_binvisus === '-' || $scope.interview.contactLens.powers.near_binvisus === '-' ||
                $scope.interview.contactLens.powers.near_r_visus === '-' || $scope.interview.contactLens.powers.near_l_visus === '-' ||
                $scope.interview.contactLens.powers.dist_r_cz === '0' || $scope.interview.contactLens.powers.dist_l_cz === '0') {
                $scope.$emit('error', prefix + 'VISUS')

                change = false
            }

            if($scope.interview.contactLens.comfort_rating === -1) {
                $scope.$emit('error', prefix + 'COMFORT')

                change = false
            }

            if(change) {
                $scope.interview.contactLens.matching = 1
                $scope.interview.contactLens.matching_date = moment().format('YYYY-MM-DD')
            }
        }

        $scope.noMatchLens = () => {
            $scope.interview.contactLens.matching = 2
            $scope.interview.contactLens.matching_date = moment().format('YYYY-MM-DD')
        }

        $scope.$watch('interview.contactLens.control_visit_date', (nVal, oVal) => {
            if(nVal && nVal != oVal) {
                if(Date.parse(nVal) - Date.parse($filter('date')(new Date(), 'yyyy-MM-dd')) < 0) {
                    $scope.interview.contactLens.control_visit_date = ''

                    $scope.$emit('error', 'patient.notify.error.PREVIOUSAPPOITMENT')
                }
            }
        })

        $scope.goVisitControl = () => {
            $http.get('api/optometrist/interview/go-visit-control/' + $scope.interview.id).then(res => {
                $state.go('app.optometrist.details', {id : res.data})

                $scope.$emit('success', 'optometrist.contactlens.controlvisit.CREATE')
            }, () => {
                $scope.$emit('error', 'optometrist.contactlens.controlvisit.error.CREATE')
            })
        }

        $scope.updateCorrection = correction => {
            $scope.correction = correction
        }

        $scope.updateContactLens = contactLens => {
            $scope.contactLens = contactLens
        }
    }
])