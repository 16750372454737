angular.module('eOpti.app.tests').controller('testResponsibilityDetailsController', [
    '$scope', '$http', '$state', 'breadcrumb', 'tests.service',
    function ($scope, $http, $state, breadcrumb, tests) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            defaults: 'testsResponsibilitiesList'
        }, {
            functions: 'testResponsibility',
            args: [null, $state.params.id]
        }, {
            name: 'test.breadcrumb.QUESTIONS'
        }];

        $scope.responsibility = {};

        tests.responsibilities.resource.get({responsibilityId: $state.params.id}, function(res) {
            $scope.responsibility = res;

            $scope.loading = Object.keys($scope.responsibility).length ? 'hide' : 'empty';
        }, function(error) {
            $scope.loading = 'error';
        });
    }
]);