/**
 * Override angular-breadcrumb to refresh when change detected
 */
angular.module('ncy-angular-breadcrumb').config(['$provide', function ($provide) {
    $provide.decorator('ncyBreadcrumbDirective', [
        '$delegate', '$interval', '$breadcrumb', '$rootScope',
        function ($delegate, $interval, $breadcrumb, $rootScope) {
            var directive = $delegate[0];

            directive.compile = function () {
                return function (scope, element, attr) {
                    var _this = this,
                        _arguments = arguments;

                    directive.link.post.apply(_this, _arguments);
                    //
                    //$interval(function () {
                    //    directive.link.post.apply(_this, _arguments);
                    //}, 5000);

                    scope.$watch(function () {
                        return $rootScope.toState;
                    }, function (newValue) {
                        directive.link.post.apply(_this, _arguments);
                    }, true);
                };
            }

            return $delegate;
        }
    ]);

    $provide.decorator('ncyBreadcrumbLastDirective', [
        '$delegate', '$rootScope', '$breadcrumb', '$interpolate', '$interval',
    function ($delegate, $rootScope, $breadcrumb, $interpolate, $interval) {
        var directive = $delegate[0];

        directive.compile = function(cElement, cAttrs) {
            // Override the default template if ncyBreadcrumbLast has a value
            var template = cElement.attr(cAttrs.$attr.ncyBreadcrumbLast);
            if(template) {
                cElement.html(template);
            }

            return {
                post: function postLink(scope) {
                    var labelWatchers = [];

                    var getExpression = function(interpolationFunction) {
                        if(interpolationFunction.expressions) {
                            return interpolationFunction.expressions;
                        } else {
                            var expressions = [];
                            angular.forEach(interpolationFunction.parts, function(part) {
                                if(angular.isFunction(part)) {
                                    expressions.push(part.exp);
                                }
                            });
                            return expressions;
                        }
                    };

                    var registerWatchers = function(labelWatcherArray, interpolationFunction, viewScope, step) {
                        angular.forEach(getExpression(interpolationFunction), function(expression) {
                            var watcher = viewScope.$watch(expression, function() {
                                step.ncyBreadcrumbLabel = interpolationFunction(viewScope);
                            });
                            labelWatcherArray.push(watcher);
                        });
                    };

                    var deregisterWatchers = function(labelWatcherArray) {
                        angular.forEach(labelWatcherArray, function(deregisterWatch) {
                            deregisterWatch();
                        });
                        labelWatcherArray = [];
                    };

                    var renderLabel = function() {
                        deregisterWatchers(labelWatchers);
                        var viewScope = $breadcrumb.$getLastViewScope();
                        var lastStep = $breadcrumb.getLastStep();
                        if(lastStep) {
                            scope.ncyBreadcrumbLink = lastStep.ncyBreadcrumbLink;
                            if (lastStep.ncyBreadcrumb && lastStep.ncyBreadcrumb.label) {
                                var parseLabel = $interpolate(lastStep.ncyBreadcrumb.label);
                                scope.ncyBreadcrumbLabel = parseLabel(viewScope);
                                // Watcher for further viewScope updates
                                // Tricky last arg: the last step is the entire scope of the directive !
                                registerWatchers(labelWatchers, parseLabel, viewScope, scope);
                            } else {
                                scope.ncyBreadcrumbLabel = lastStep.name;
                            }
                        }
                    };

                    $rootScope.$on('$viewContentLoaded', function () {
                        renderLabel();
                    });

                    scope.$watch(function () {
                        return $rootScope.toState;
                    }, function (newValue) {
                        template = cElement.attr(cAttrs.$attr.ncyBreadcrumbLast);
                        if(template) {
                            cElement.html(template);
                        }
                        renderLabel();
                    }, true);

                    //$interval(function() {
                    //    template = cElement.attr(cAttrs.$attr.ncyBreadcrumbLast);
                    //    if(template) {
                    //        cElement.html(template);
                    //    }
                    //    renderLabel();
                    //}, 5000);

                    // View(s) may be already loaded while the directive's linking
                    renderLabel();
                }
            };
        }

        directive.link = function(scope, element, attr) {
            console.log('fsdk');
        }

        return $delegate;
    }]);

}]);