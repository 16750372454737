angular.module('eOpti.app.settings').controller('socialSettingsController', [
    '$scope',
    '$http',
    'formValidate',
    function ($scope, $http, formValidate) {
        $scope.formValidate = formValidate;
        $scope.isCentralDepartment = $scope.uac.user.activeDepartmentId === 91;
        $scope.rows = [];
        $scope.lock = true;

        $scope.social = {
            type: null,
            facebook: {
                login: '',
                password: '',
                confirm: '',
                link: '',
                doesnt_have: false
            },
            instagram: {
                login: '',
                password: '',
                confirm: '',
                link: '',
                doesnt_have: false
            },
            gmail: {
                login: '',
                password: '',
                confirm: '',
                doesnt_have: false
            }
        };

        if($scope.isCentralDepartment) {
            $http.get('api/social-media/all').then(function(res) {
                $scope.rows = res.data;
            }, function() {
                $scope.$emit('error', 'settings.social.notify.error.LOAD');
            });
        } else {
            $http.get('api/social-media').then(function(res) {
                $scope.social.facebook.login = res.data.facebook_login;
                $scope.social.facebook.password = res.data.facebook_password;
                $scope.social.facebook.link = res.data.facebook_link;
                $scope.social.facebook.doesnt_have = res.data.facebook_doesnt_have;

                $scope.social.instagram.login = res.data.instagram_login;
                $scope.social.instagram.password = res.data.instagram_password;
                $scope.social.instagram.link = res.data.instagram_link;
                $scope.social.instagram.doesnt_have = res.data.instagram_doesnt_have;

                $scope.social.gmail.login = res.data.gmail_login;
                $scope.social.gmail.password = res.data.gmail_password;
                $scope.social.gmail.doesnt_have = res.data.gmail_doesnt_have;
            }, function() {
                $scope.$emit('error', 'settings.social.notify.error.LOAD');
            });
        }

        $scope.submitFacebook = function() {
            $scope.social.type = 'facebook';

            $http.post('api/social-media/save', $scope.social).then(function() {
                $scope.$emit('success', 'settings.social.notify.SAVE');
            }, function() {
                $scope.$emit('error', 'settings.social.notify.error.SAVE');
            });
        };

        $scope.submitInstagram = function() {
            $scope.social.type = 'instagram';

            $http.post('api/social-media/save', $scope.social).then(function() {
                $scope.$emit('success', 'settings.social.notify.SAVE');
            }, function() {
                $scope.$emit('error', 'settings.social.notify.error.SAVE');
            });
        };

        $scope.submitGMail = function() {
            $scope.social.type = 'gmail';

            $http.post('api/social-media/save', $scope.social).then(function() {
                $scope.$emit('success', 'settings.social.notify.SAVE');
            }, function() {
                $scope.$emit('error', 'settings.social.notify.error.SAVE');
            });
        };
    }
]);
