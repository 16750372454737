angular.module('eOpti.validate').directive('pwMatch', [
    function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {

                var firstPassword = '#' + attrs.pwMatch;

                ctrl.$validators.pwmatch = function(input) {
                    if(!input) {
                        return true;
                    }

                    return input === $(firstPassword).val();
                };

                elem.add(firstPassword).on('input', function() {
                    ctrl.$validate();
                });
            }
        }
    }
]);