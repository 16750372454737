angular.module('eOpti.app.tests').controller('testResponsibilityAddController', [
    '$scope', '$http', '$state', 'breadcrumb', 'tests.service', 'formValidate', 'error',
    function ($scope, $http, $state, breadcrumb, tests, formValidate, errorValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            defaults: 'testsResponsibilitiesList'
        }, {
            name: 'test.breadcrumb.ADD'
        }];

        $scope.formValidate = formValidate;

        $scope.form = {
            name: '',
            role_ids: []
        };

        $scope.roles = [];

        $http.get('api/roles').then(function(res) {
            $scope.roles = res.data;

            $scope.formResponsibility.$setValidity('roles', false);
        }, function(error) {
            $scope.$emit('error', 'role.notify.error.LOADPERMISSION');
        });

        $scope.setRole = function() {
            $scope.form.role_ids = [];

            angular.forEach($scope.roles, function(role) {
                if(role.checked) {
                    $scope.form.role_ids.push(role.id);
                }
            });

            $scope.formResponsibility.$setValidity('roles', $scope.form.role_ids.length > 0 ? true : false);
        };

        $scope.addResponsibility = function () {
            tests.responsibilities.resource.save($scope.form, function () {
                $scope.$emit('success', 'test.responsibility.notify.SAVE');

                $state.go('app.test.responsibilities.table', {
                    id: $state.params.id
                });
            }, function (error) {
                errorValidate.request(error, 'test.responsibility.notify.error.SAVE');
            });
        };
    }
]);