angular.module('eOpti.app.bookkeeping').controller('bookkeepingOrderToLensController', [
    '$scope', '$http', '$stateParams', 'breadcrumb',
    function ($scope, $http, $stateParams, breadcrumb) {
        breadcrumb.data = [{
            name: 'bookkeeping.breadcrumb.REGISTER'
        }, {
            name: 'bookkeeping.breadcrumb.BOOKKEEPING'
        }, {
            name: 'bookkeeping.breadcrumb.ORDERTOLENS'
        }];

    }]);
