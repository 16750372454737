angular.module('eOpti.app.register.return').config(
    [
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider
                .state('app.register.expense', {
                    abstract: true,
                    url: '/expense',
                    template: '<ui-view/>'
                })
                .state('app.register.expense.current', {
                    abstract: true,
                    url: '/current',
                    template: '<ui-view/>'
                })
                .state('app.register.expense.current.add', {
                    url: '/add',
                    template: '<opti-register-expense-current-add></opti-register-expense-current-add>',
                    controller: [
                        'breadcrumb', function (breadcrumb) {
                            breadcrumb.data = [{
                                last: 2
                            }, {
                                name: 'register.breadcrumb.REGISTER'
                            }, {
                                name: 'register.breadcrumb.EXPENSE'
                            }, {
                                name: 'register.breadcrumb.EXPENSECURRENT'
                            }, {
                                name: 'register.breadcrumb.ADD'
                            }];
                        }
                    ]
                })
                .state('app.register.expense.bank', {
                    abstract: true,
                    url: '/bank',
                    template: '<ui-view/>'
                })
                .state('app.register.expense.bank.add', {
                    url: '/add',
                    templateUrl: '/app/views/register/expense/bank-add.html',
                    controller: 'register.expense.bank.add'
                });
        }
    ]
);
