angular.module('eOpti.app.regenerations').config(['$stateProvider', function($stateProvider) {
    let generateRegenerationStatus = () => {
        let statuses = ['department', 'sentwarehouse', 'receivewarehouse', 'sentspecialist', 'receivespecialist',
            'assembly', 'sentdepartment', 'receivedepartment', 'done', 'cancel', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.regenerations.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: 10,
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/regenerations/table/' + status + '.html',
                controller: 'regeneration.' + status
            })
        })
    }

    $stateProvider
        .state('app.regenerations', {
            url: '/regenerations',
            abstract: true,
            template: '<ui-view/>'
        })

    generateRegenerationStatus()

    $stateProvider
        .state('app.regeneration', {
            url: '/regeneration',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.regeneration.details', {
            url: '/details/:id',
            template: '<opti-regeneration-details v-on-update-breadcrumb="updateBreadcrumb"></opti-regeneration-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'regeneration.breadcrumb.REGENERATIONS'
                }, {
                    functions: 'regenerations',
                    args: null
                }, {
                    functions: 'regeneration',
                    args: [null, $state.params.id]
                }]

                $scope.updateBreadcrumb = status_name => {
                    breadcrumb.data[1].args = status_name
                }
            }]
        })
        .state('app.regeneration.add', {
            url: '/add',
            template: '<opti-regeneration-add></opti-regeneration-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                    breadcrumb.data = [{
                        name: 'regeneration.breadcrumb.REGENERATIONS'
                    }, {
                        functions: 'regenerations',
                        args: 'regeneration.status.DEPARTMENT'
                    }, {
                        name: 'regeneration.breadcrumb.ADD'
                    }]
                }
            ],
            params: {
                extra: null
            }
        })
        .state('app.regeneration.edit', {
            url: '/edit/:id',
            template: '<opti-regeneration-edit v-on-update-breadcrumb="updateBreadcrumb"></opti-regeneration-edit>',
            controller: [
                '$scope', '$state', 'breadcrumb', function ($scope, $state, breadcrumb) {
                    breadcrumb.data = [{
                        name: 'regeneration.breadcrumb.REGENERATIONS'
                    }, {
                        functions: 'regenerations',
                        args: null
                    }, {
                        functions: 'regeneration',
                        args: ['state', $state.params.id]
                    }, {
                        name: 'regeneration.breadcrumb.EDIT'
                    }]

                    $scope.updateBreadcrumb = status_name => {
                        breadcrumb.data[1].args = status_name
                    }
                }
            ]
        })
}])