angular.module('eOpti.app.entries').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.entries', {
            abstract: true,
            url: '/entries',
            template: '<ui-view/>'
        })
        .state('app.entries.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<opti-entries-missing-days></opti-entries-missing-days></opti-entries-missing-days>' +
                '<div class="row"><div class="col-xs-12"><base-table end-point="/api/entries/table" ' +
                'header="entries.breadcrumb.LIST" sub-header="" ref="app.entries.list"><btn default icon="fa fa-plus" ' +
                'ui-sref="app.entries.add">entries.breadcrumb.ADD</btn></base-table></div></div><script>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    functions: 'entriesList',
                    args: [null]
                }]
            }]
        })

        .state('app.entries.add', {
            url: '/add',
            template: '<opti-entries-add></opti-entries-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    functions: 'entriesList',
                    args: ['state']
                }, {
                    name: 'entries.breadcrumb.ADD'
                }]
            }]
        })
}])