angular.module('eOpti.app.marketing').controller('marketingEditController', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'marketing.service', 'error',
    function ($scope, $http, $state, breadcrumb, formValidate, marketing, errorValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'marketingList'
        },  {
            name: 'marketing.breadcrumb.MARKETING',
            id: $state.params.id
        }, {
            name: 'marketing.breadcrumb.EDIT'
        }]

        $scope.formValidate = formValidate

        $scope.loadingAttachment = []

        $scope.loadingMarketing = 'loading'

        let watch = () => {
            $scope.$watch('marketing.period', period => {
                if(!period) {
                    $scope.marketing.from = null
                    $scope.marketing.to = null
                }
            })
        }

        marketing.resource.get({marketingId: $state.params.id}, res => {
            $scope.marketing = res

            if($scope.marketing.upload) {
                $scope.marketing.upload = '../marketing/' + $scope.marketing.upload
            }

            if($scope.marketing.from && $scope.marketing.to) {
                $scope.marketing.period = true
            }

            $scope.marketing.department_ids = []

            angular.forEach($scope.marketing.departments, department => {
                $scope.marketing.department_ids.push(department.id)
            })

            angular.forEach($scope.marketing.uploads, upload => {
                upload.upload = '../marketing/' + upload.upload
            })

            if(!$scope.marketing.uploads.length) {
                $scope.addAttachment()
            }

            watch()

            $scope.loadingMarketing = $scope.marketing ? 'hide' : 'empty'
        }, () => {
            $scope.loadingMarketing = 'error'
        })

        $scope.addAttachment = () => {
            $scope.marketing.uploads.push({
                upload: ''
            })
        }

        $scope.removeAttachment = (uploads, index) => {
            if(index || (!index && uploads.length > 1)) {
                uploads.splice(index, 1)
            } else {
                uploads[index].upload = ''
            }
        }

        $scope.editMarketing = () => {
            marketing.resource.update($scope.marketing, () => {
                $scope.$emit('success', 'marketing.info.UPDATED')

                $state.previous('app.marketing.list')
            }, error => {
                errorValidate.request(error)
            })
        }

        $scope.getDepartmentIds = ids => {
            $scope.marketing.department_ids = ids
        }

    }])