angular.module('eOpti.app.layout').controller('layoutDetailsController', [
    '$scope',
    '$http',
    '$state',
    'breadcrumb',
    'uac',
    function($scope, $http, $state, breadcrumb, uac) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: ['state']
        }, {
            functions: 'layoutDetails',
            args: [$state.params.id, null, null]
        }, {
            functions: 'layoutList',
            args: ['all']
        }]

        //TODO: praktycznie to ten sam kod co layoutAllDetailsController.js

        $scope.layout_id     = $state.params.id
        $scope.department    = null
        $scope.department_id = null

        $scope.additionalRow = (row, columns) => {
            let columnCount = 0,
                shouldCount = true,
                count       = 0,
                rowSpanned  = 0,
                authors     = id => 'brak'

            angular.forEach(columns, (column, idx) => {
                if(column.id === 'smile_author') {
                    authors = id => {
                        let author = column.options.filter((val) => val.key === id)[0]
                        return (author ? author.value : false) || 'brak'
                    }
                }

                if(column.visible) {
                    count++
                }

                if(column.visible && shouldCount) {
                    columnCount += 1
                }

                if(column.should_row_span) {
                    shouldCount = false
                    rowSpanned++
                }
            })

            if(columnCount > 0) columnCount--

            let text = ''
            if(row.delivery_type) {
                if(uac.user.activeRole.admin) {
                    text = `<div>
                                <number class="form-control" model="row" field="smile_facing" callback="fire('editSmile', { row: row })">
                                    <small class="text-muted">${authors(row.smile_author)} @ ${row.smile_changed}</small> 
                                    Facing <opti-enum v-props-enum="'CategoryDeliveryType'" v-props-id="row.delivery_type"></opti-enum>: <span style="display:inline-block;width:4em">{{row.smile_facing}} {{row.supply}}</span>
                                </number>
                            </div>`
                } else {
                    text = `<div>
                                <small class="text-muted">${authors(row.smile_author)} @ ${row.smile_changed}</small> 
                                Facing <opti-enum v-props-enum="'CategoryDeliveryType'" v-props-id="row.delivery_type"></opti-enum>: <span style="display:inline-block;width:4em">{{row.smile_facing}} {{row.supply}}</span>
                            </div>`
                }
            }

            let html = `<td class="task-icons" style="text-align: right" colspan="${columnCount}">${text}</td>`;

            for(let i = 0; i < count - columnCount - rowSpanned; i++) {
                html += '<td></td>'
            }

            return html
        }

        $scope.editSmile = row => {
            $http.post('api/layout/smile/update', {
                id: row.id,
                facing: row.smile_facing
            }).then(res => {
                row.smile_facing = res.data.facing
            })
        }

        $scope.events = (event, data) => {
            if (event === 'editSmile') {
                $scope.editSmile(data.row)
            }
        }

        $scope.renderers = {
            grid(value, row, column) {
                if(!value) return ''

                let links = []
                value.split(',').forEach(object => {
                    let link  = object.split('='),
                        names = link[1].split(':')

                    links.push({
                        id: link[0],
                        name: names[0],
                        column: parseInt(names[1]) + 1,
                        size: link[2],
                        department: link[3]
                    })
                })

                let total = 0,
                     html  = ''

                links.forEach(link => {
                    html += `<a class="link" ui-sref="app.layout.grid({ open: ${link.id}, departmentId: ${link.department} })">
                                <div>
                                    <span>${link.name} </span>
                                    <span class="text-sm text-muted" style="font-weight: normal">kolumna ${link.column}</span>
                                    <span class="pull-right" style="width: 2em; text-align: right;">${link.size}</span>
                                    <div class="clearfix"></div>
                                </div>
                             </a>`
                    total += parseInt(link.size)
                })

                if(html && total && links.length > 1) {
                    html += `<div style="border-top: 1px solid black">
                                <span>Razem</span>
                                <span class="pull-right" style="width: 2em; text-align: right;">${total}</span>
                                <div class="clearfix"></div>
                            </div>`
                }

                return html
            }
        }

        $http.get('api/layout/get-department/' + $scope.layout_id).then(res => {
            $scope.department          = res.data.name
            $scope.departmentId        = res.data.id
            breadcrumb.data[2].args[1] = res.data.name
        }, () => {
            $scope.$emit('error', 'default.notify.error.DEFAULT')
        })

    }
])
