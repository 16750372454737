angular.module('eOpti.app.settings').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.dictionary.starprotocol', {
                    url: '/star-protocol',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.dictionary.starprotocol.add', {
                    url: '/add',
                    template: '<opti-dictionary-star-protocol-add></opti-dictionary-star-protocol-add>',
                    controller: [
                        'breadcrumb', function (breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                name: 'star.breadcrumb.STARLOOX'
                            }, {
                                name: 'star.breadcrumb.PROTOCOLADD'
                            }]
                        }
                    ]
                })
        }
    ]
);
