angular.module('eOpti.app.tests').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.tests', {
            url: '/tests/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/tests/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'test.breadcrumb.TESTS'
                }]
            }]
        })
        .state('app.test', {
            url: '/test',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.add', {
            url: '/add',
            templateUrl: 'app/views/tests/add.html',
            controller: 'testAddController'
        })
        .state('app.test.edit', {
            url: '/:id/edit',
            templateUrl: 'app/views/tests/edit.html',
            controller: 'testEditController'
        })
        .state('app.test.details', {
            url: '/:id/questions',
            template: '<opti-test-details></opti-test-details>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    defaults: 'testsList'
                }, {
                    functions: 'test',
                    args: [null, $state.params.id]
                }, {
                    name: 'test.breadcrumb.QUESTIONS'
                }]
            }]
        })

        .state('app.test.do', {
            url: '/:id/do',
            templateUrl: 'app/views/tests/do/question.html',
            controller: 'testDoQuestionController'
        })
        .state('app.test.results', {
            url: '/results',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.results.table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/tests/result/table.html',
            controller: ['breadcrumb',function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    defaults: 'testsList'
                }, {
                    name: 'test.breadcrumb.RESULTS'
                }]
            }]
        })
        .state('app.test.results.details', {
            url: '/details/:test_result_id',
            templateUrl: 'app/views/tests/result/details.html',
            controller: 'testsResultDetailsController'
        })
        .state('app.test.responsibilities', {
            url: '/responsibilities',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.responsibilities.table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/tests/responsibilities/table.html',
            controller: 'testsResponsibilitiesListController'
        })
        .state('app.test.responsibility', {
            url: '/responsibility',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.responsibility.add', {
            url: '/add',
            templateUrl: 'app/views/tests/responsibilities/add.html',
            controller: 'testResponsibilityAddController'
        })
        .state('app.test.responsibility.edit', {
            url: '/:id/edit',
            templateUrl: 'app/views/tests/responsibilities/edit.html',
            controller: 'testResponsibilityEditController'
        })
        .state('app.test.responsibility.details', {
            url: '/:id/details',
            templateUrl: 'app/views/tests/responsibilities/details.html',
            controller: 'testResponsibilityDetailsController'
        })
        .state('app.test.responsibility.question', {
            url: '/:id/question',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.responsibility.question.add', {
            url: '/add',
            templateUrl: 'app/views/tests/responsibilities/questions/add.html',
            controller: 'questionAddController'
        })
        .state('app.test.responsibility.question.edit', {
            url: '/:question_id/edit',
            templateUrl: 'app/views/tests/responsibilities/questions/edit.html',
            controller: 'questionEditController'
        })
        .state('app.test.assign', {
            url: '/assign',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.assign.edit', {
            url: '/:id/edit',
            templateUrl: 'app/views/tests/assign.html',
            controller: 'testAssignEditController'
        })
        .state('app.test.questions', {
            url: '/questions',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.test.questions.table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/tests/questions/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    defaults: 'testsList'
                }, {
                    defaults: 'testsResponsibilitiesList'
                }, {
                    name: 'test.breadcrumb.QUESTIONS'
                }]
            }]
        })
}])