angular.module('eOpti.app.sales').controller('saleDetailsController', [
    '$scope', '$rootScope', '$http', '$state', 'breadcrumb', 'dialogs', 'receiptNoticeService',
    function($scope, $rootScope, $http, $state, breadcrumb, dialogs, receiptNotice) {
        breadcrumb.data = [{
            defaults: 'salesList'
        }, {
            name: 'sale.breadcrumb.SALE',
            id: $state.params.id
        }]

        $scope.mode = $state.current.name === 'app.register.return.sale.details' ? 'return' : 'sale'

        $scope.sale = {}

        let load = () => {
            $scope.loadingSale = 'loading'

            $http.get('api/sale/' + $state.params.id).then(res => {
                $scope.sale = res.data

                receiptNotice.get('sale', $state.params.id).then(res => {
                    $scope.noticeSale = res
                })

                receiptNotice.get('salereturn', $state.params.id).then(res => {
                    $scope.noticeSalereturn = res
                })

                $scope.loadingSale = Object.keys($scope.sale).length ? 'hide' : 'empty'
            }, () => {
                $scope.loadingSale = 'error'
            })
        }

        load()

        $scope.goToOrder = () => {
            $http.post('api/carts/order/add', $scope.sale).then(res => {
                //TODO: gdy będzie przepisane w Vue to rozsunąć koszyk i z res.data pobrać dane do koszyka

                $state.reload()

                window.localStorage.setItem('cart-active', 1)
                $rootScope.offsideOpen = true

                $scope.$emit('success', 'carts.notify.SAVEMULTI')
            }, () => {
                $scope.$emit('error', 'sale.items.notify.error.SENDTOORDER')
            })
        }

        $scope.goToPrint = () => {
            window.open('api/register/return/sale/print/' + $scope.sale.id)
        }

        $scope.returned = false

        $scope.sum = items => {
            let price = quantity = value = discount = discountPercent = valueAfterDiscount = returns = 0

            angular.forEach(items, item => {
                price += parseFloat(item.price)
                quantity += parseInt(item.quantity)
                value += parseFloat(item.value)
                discount += parseFloat(item.discount)
                discountPercent += parseFloat(item.discountPercent)
                valueAfterDiscount += parseFloat(item.valueAfterDiscount)

                if(item.quantity < 0) {
                    $scope.returned = true
                    returns += parseFloat(item.valueAfterDiscount)
                }
            })

            return {
                price: Math.round(price * 100) / 100,
                quantity: quantity,
                value: Math.round(value * 100) / 100,
                discount: Math.round(discount * 100) / 100,
                discountPercent: Math.round((!value ? 0 : discount * 100 / value) * 100) / 100,
                valueAfterDiscount: quantity && !valueAfterDiscount ? 0.01 : Math.round(valueAfterDiscount * 100) / 100,
                returns: Math.round(returns * 100) / 100 * -1
            }
        }

        $scope.goReturn = () => {
            $state.go('app.register.return.sale.add', {
                saleId: $state.params.id
            })
        }

        $scope.goReclamation = () => {
            $state.go('app.sale.reclamation.add', {
                saleId: $state.params.id
            })
        }

        let getProductType = attributes => {
            let _return = ''

            angular.forEach(attributes, attribute => {
                if(attribute.attributeId === 7) {
                    _return = attribute.value
                }
            })

            return _return
        }

        $scope.createRegeneration = item => {
            dialogs.confirm('sale.regeneration.confirm.MESSAGE', {
                className: 'ngdialog-theme-plain',
                data: {
                    translate: true,
                    confirm: 'sale.regeneration.confirm.YES',
                    confirmIcon: 'icon icon-magic-wand',
                    cancel: 'sale.regeneration.confirm.NO'
                }
            }).then(() => {
                $state.go('app.regeneration.add', {
                    extra: {
                        patient: Object.keys($scope.sale).includes('patient') ? $scope.sale.patient : {},
                        product: item.product,
                        product_name: item.product.name,
                        product_brand: item.product.manufacturer,
                        product_type: getProductType(item.product.attributes),
                        source: 2,
                        source_id: $scope.sale.id
                    }
                })
            })
        }

        $scope.updateNotes = notes => {
            $scope.sale.notes = notes
        }

    }
])