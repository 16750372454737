angular.module('eOpti.app.settings').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.dictionary.doctors', {
                    url: '/doctors',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.dictionary.doctors.table', {
                    url: '/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/dictionary/doctor/table.html',
                    controller: [
                        'breadcrumb', function (breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                name: 'dictionary.doctor.breadcrumb.DOCTORS'
                            }]
                        }
                    ]
                })

            $stateProvider
                .state('app.dictionary.doctor', {
                    url: '/doctor',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.dictionary.doctor.add', {
                    url: '/add',
                    template: '<opti-dictionary-doctor-add></opti-dictionary-doctor-add>',
                    controller: [
                        'breadcrumb', function (breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                defaults: 'doctorList'
                            }, {
                                name: 'dictionary.doctor.breadcrumb.ADD'
                            }]
                        }
                    ]
                })
                .state('app.dictionary.doctor.edit', {
                    url: '/:id/edit',
                    template: '<opti-dictionary-doctor-edit></opti-dictionary-doctor-edit>',
                    controller: [
                        '$state','breadcrumb',
                        function ($state, breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                defaults: 'doctorList'
                            }, {
                                name: 'dictionary.doctor.breadcrumb.DOCTOR',
                                id: $state.params.id
                            }]
                        }
                    ]
                })
                .state('app.dictionary.doctor.delete', {
                    url: '/:id/delete',
                    template: '<opti-dictionary-doctor-delete></opti-dictionary-doctor-delete>',
                    controller: [
                        '$state', 'breadcrumb', function ($state, breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                defaults: 'doctorList'
                            }, {
                                name: 'dictionary.doctor.breadcrumb.DOCTOR',
                                id: $state.params.id
                            }, {
                                name: 'dictionary.doctor.breadcrumb.DELETE'
                            }]
                        }
                    ]
                })
        }
    ]
)
