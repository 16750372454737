angular.module('eOpti.app.sales').controller('patientListMatchingMultilevelController', [
    '$scope', '$http', '$attrs', '$parse', function($scope, $http, $attrs, $parse) {
    $scope.contactLens = {}
    $scope.fetching = true

    if($attrs.id) {
        let id = $parse($attrs.id)

        $http.get('api/optometrist/interview/powers/' + id($scope)).then(res => {
            $scope.contactLens = res.data
            $scope.fetching = false
        }, () => {
            $scope.$emit('error', 'optometrist.notify.error.POWERS')
        })
    }
}])