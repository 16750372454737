angular.module('eOpti.app.messages').controller('messages.view.create', [
    '$scope',
    '$http',
    '$state',
    '$auth',
    'uac',
    'FileUploader',
    'dialogs',
    'error',
    'ENV',
    function ($scope, $http, $state, $auth, uac, FileUploader, dialogs, errorValidate, ENV) {
        $scope.message = {
            topic: '',
            members: [],
            content: '',
            files: []
        }

        $scope.prepareMessage = () => {
            $scope.message.files = []

            angular.forEach($scope.files, file => {
                $scope.message.files.push({
                    name: file.file.name,
                    link: file.tmpLink
                })
            })
        }

        $scope.upload = new FileUploader({
            url: 'api/room/upload',
            alias: 'uploadMessage',
            autoUpload: true,
            headers: {
                'Authorization': 'Bearer ' + $auth.getToken()
            }
        })

        $scope.files = [];

        $scope.refreshFile = () => {
            $scope.files = []

            angular.forEach($scope.upload.queue, upload => {
                $scope.files.push(upload)
            })
        }

        $scope.typeIcon = type => {
            let imgs = ['jpg', 'jpeg', 'gif', 'png'],
                icon = 'fa-paperclip'

            angular.forEach(imgs, img => {
                if(('image/' + img) === type) {
                    icon = 'fa-camera'
                }
            })

            return icon
        }

        $scope.upload.onAfterAddingFile = item => {
            $scope.refreshFile()
        }

        $scope.fileRemove = item => {
            $http.get('api/room/upload/remove/' + item.tmpLink).then(res => {
                $scope.upload.removeFromQueue($scope.upload.getIndexOfItem(item))

                $scope.refreshFile()
            }, () => {
                $scope.$emit('error', 'timeline.notify.error.NOUPLOAD')
            })
        }

        $scope.upload.onProgressItem = (item, progress) => {
            if(progress === 100) {
                $scope.refreshFile()
            }
        }

        $scope.upload.onErrorItem = (item, response, status, headers) => {
            if (status !== 200) {
                $scope.upload.removeFromQueue(item)

                $scope.refreshFile()

                angular.forEach(response.uploadPrescription, res => {
                    $scope.$emit('error', res)
                })
            }
        }

        $scope.upload.onCompleteItem = (item, response, status, headers) => {
            if (response.info && response.info !== 'fail') {
                item.tmpLink = response.info
            } else {
                $scope.upload.removeFromQueue(item)

                $scope.$emit('error', 'timeline.notify.error.UPLOAD')
            }

            $scope.refreshFile()
        }

        $scope.showAttachment = item => {
            dialogs.confirm(null, {
                template: 'app/views/_dialogs/patient/prescription/scan-with-delete.html',
                className: 'ngdialog-theme-plain',
                data: {
                    confirmPanel: 'panel-warning',
                    className: 'ngdialog-theme-plain',
                    link: ENV.uploadHost + '/' + item.tmpLink,
                    name: item.file.name,
                    isImg: $scope.typeIcon(item.file.type) === 'fa-camera'
                }
            }).then(() => {
                dialogs.confirm('timeline.delete.CONFIRM', {
                    className: 'ngdialog-theme-plain',
                    data: {
                        translate: true,
                        confirm: 'timeline.delete.YES',
                        confirmIcon: 'fa fa-fw fa-trash',
                        cancel: 'timeline.delete.NO'
                    }
                }).then(() => {
                    $scope.fileRemove(item)
                })
            })
        }

        $scope.createLoading = 'hide'

        $scope.create = () => {
            $scope.prepareMessage()

            if($scope.message.members.length < 2) {
                $scope.$emit('error', 'messages.notify.error.MEMBERS')
            } else if(!$scope.message.topic) {
                $scope.$emit('error', 'messages.notify.error.TOPIC')
            } else if(!$scope.message.content) {
                $scope.$emit('error', 'messages.notify.error.MESSAGE')
            } else {
                $scope.createLoading = 'loading'

                $http.post('api/room/create', $scope.message).then(() => {
                    $state.go('app.messages.view.' + ($scope.message.important ? 'starred' : 'sent'))

                    if($scope.message.important) {
                        $scope.getBadges()
                    }

                    $scope.createLoading = 'hide'

                    $scope.$emit('success', 'messages.notify.ADD')
                }, error => {
                    $scope.createLoading = 'hide'

                    errorValidate.request(error, 'messages.notify.error.ADD')
                })
            }
        }

        $scope.getDepartmentIds = ids => {
            $scope.message.members = ids
        }
    }
])