angular.module('eOpti.app.payments').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.payments', {
            url: '/payments',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.payments.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/payments/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    name: 'payment.card.breadcrumb.PAYMENTCARDS'
                }]
            }]
        })
        .state('app.payments.add', {
            url: '/add',
            template: '<opti-dictionary-payments-add></opti-dictionary-payments-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'paymentsList'
                }, {
                    name: 'payment.card.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.payments.edit', {
            url: '/payments/:id/edit',
            template: '<opti-dictionary-payments-edit></opti-dictionary-payments-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'paymentsList'
                }, {
                    name: 'payment.card.breadcrumb.PAYMENTCARD',
                    id: $state.params.id
                }, {
                    name: 'payment.card.breadcrumb.EDIT'
                }]
            }]
        })
}])