angular.module('eOpti.app.tasks.sent').controller('sentTaskController', [
    '$scope', '$http', '$state', '$localStorage', 'breadcrumb', 'dialogs', 'taskListIcons',
    function ($scope, $http, $state, $localStorage, breadcrumb, dialogs, taskListIcons) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'tasks.breadcrumb.TASKS'
        }, {
            functions: 'tasks',
            args: 'tasks.status.SENT'
        }]

        $scope.disabledBtn = false

        $scope.sendReceive = () => {
            $scope.disabledBtn = true

            $http.post('api/tasks/send/receive', {
                receive: $localStorage['task_to_send_checkbox'],
                smses: $localStorage['task_sms_checkbox']
            }).then(res => {
                $state.go($state.current, {}, {reload: true})

                dialogs.confirm(null, {
                    template: 'app/views/_dialogs/task/receive-task-send-sms.html',
                    className: 'ngdialog-theme-plain',
                    data: {
                        confirmPanel: 'panel-purple',
                        className: 'ngdialog-theme-plain',
                        response: res.data
                    }
                })

                $scope.disabledBtn = false
            }, () => {
                $scope.$emit('error', 'sms.dialog.notify.error.NOSENDRECEIVE')

                $scope.disabledBtn = false
            })
        }

        $scope.additionalRow = taskListIcons.getRenderer()

        $scope.prescription_id = null

        $scope.$on('preview', (env, value) => {
            $scope.prescription_id = null

            setTimeout(() => {
                $scope.prescription_id = value

                $scope.$apply()
            }, 1)
        })
    }])
