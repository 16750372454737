angular.module('eOpti.app.marketing').controller('marketingDeleteController', [
    '$scope', '$http', '$state', 'breadcrumb', 'marketing.service', 'dialogs',
    function ($scope, $http, $state, breadcrumb, marketing, dialogs) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'marketingList'
        }, {
            name: 'marketing.breadcrumb.MARKETING',
            id: $state.params.id
        }, {
            name: 'marketing.breadcrumb.DELETE'
        }];

        $scope.loadingMarketing = 'loading';

        dialogs.confirm('marketing.confirm.delete.MESSAGE', {
            className: 'ngdialog-theme-plain',
            data: {
                confirm: 'marketing.confirm.delete.YES',
                confirmIcon: 'fa fa-fw fa-check',
                cancel: 'marketing.confirm.delete.NO'
            }
        }, 'true').then(function() {
            marketing.resource.remove({marketingId: $state.params.id}, function() {
                $scope.$emit('success', 'marketing.info.DELETED');

                $state.previous('app.marketing.list');
            }, function() {
                $scope.loadingMarketing = 'error';
            });
        }, function() {
            $state.previous('app.marketing.list');
        });

    }]);