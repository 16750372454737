angular.module('eOpti.app.regenerations').controller('regeneration.sentwarehouse', [
    '$scope', 'breadcrumb', 'regenerationTableRenderers',
    function ($scope, breadcrumb, regenerationTableRenderers) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'regeneration.breadcrumb.REGENERATIONS'
        }, {
            functions: 'regenerations',
            args: 'regeneration.status.SENTWAREHOUSE'
        }];

        $scope.renderers = regenerationTableRenderers.getRenderers();

    }]);