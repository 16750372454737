angular.module('eOpti.app.messages').controller('messages.view.details', [
    '$scope',
    '$http',
    '$state',
    '$auth',
    '$filter',
    'uac',
    'FileUploader',
    'dialogs',
    'ENV',
    function ($scope, $http, $state, $auth, $filter, uac, FileUploader, dialogs, ENV) {
        $scope.room = null
        $scope.message_loading = 'loading'

        $scope.uploadHost = ENV.uploadHost

        $scope.showReadButton = uac.user.activeRole.admin && uac.user.activeDepartmentId !== 91 && uac.user.activeDepartmentId !== 49
        console.log($scope.showReadButton)

        let activeDepartmentId = uac.user.activeDepartmentId === 149 ? 91 : uac.user.activeDepartmentId

        let progress = function (message) {
            if (message.departmentId == activeDepartmentId) {
                let result = {
                    own: true,
                    length: {
                        read: message.read.read,
                        unread: message.read.unread
                    },
                    style: {
                        read: {width: '0%'},
                        unread: {width: '0%'}
                    }
                }

                let sum = result.length.read + result.length.unread,
                    widthRead = parseInt(result.length.read * 100 / sum),
                    widthUnread = 100 - widthRead

                result.readStyle = {width: widthRead + '%'}
                result.unreadStyle = {width: widthUnread + '%'}

                return result
            }
        }

        var parse_data = function(data) {
            $scope.room = data

            $scope.info.sth_no_read = false
            $scope.info.owner = $scope.room.messages[0].department.id == activeDepartmentId
            $scope.info.important = $scope.room.important

            $scope.room.members.unshift({
                departmentId: $scope.room.messages[0].departmentId,
                department: {
                    name: $scope.room.messages[0].department.name
                }
            })

            angular.forEach($scope.room.messages, function(message) {
                message.progress = progress(message)
            })
        }

        $http.get('api/room/' + $state.params.id).then(function(res) {
             parse_data(res.data);

             $scope.message_loading = 'hide'
        }, function() {
            $scope.message_loading = 'error'
        })

        $scope.upload = new FileUploader({
            url: 'api/room/upload',
            alias: 'uploadMessage',
            autoUpload: true,
            headers: {
                'Authorization': 'Bearer ' + $auth.getToken()
            }
        })

        $scope.files = []

        $scope.refreshFile = function () {
            $scope.files = []

            angular.forEach($scope.upload.queue, function (upload, key) {
                $scope.files.push(upload)
            })
        }

        $scope.isImg = function (link) {
            var type = ['jpeg', 'jpg', 'png', 'gif'],
                result = false

            angular.forEach(type, function (t) {
                if (link.substring(link.length - 3) === t || link.substring(link.length - 4) === t) {
                    result = true
                }
            })

            return result
        }

        $scope.typeIcon = function(type) {
            var imgs = ['jpg', 'jpeg', 'gif', 'png'],
                icon = 'fa-paperclip'

            angular.forEach(imgs, function(img) {
                if(('image/' + img) === type) {
                    icon = 'fa-camera'
                }
            })

            return icon
        }

        $scope.upload.onAfterAddingFile = function (item) {
            $scope.refreshFile()
        }

        $scope.fileRemove = function (item) {
            $http.get('api/room/upload/remove/' + item.tmpLink).then(function (res) {
                $scope.upload.removeFromQueue($scope.upload.getIndexOfItem(item));

                $scope.refreshFile();
            }, function (error, status) {
                $scope.$emit('error', 'timeline.notify.error.NOUPLOAD')
            })
        }

        $scope.upload.onProgressItem = function (item, progress) {
            if (progress == 100) {
                $scope.refreshFile()
            }
        }

        $scope.upload.onErrorItem = function (item, response, status, headers) {
            $scope.upload.removeFromQueue(item)

            $scope.refreshFile()

            angular.forEach(response.uploadPrescription, function (res) {
                $scope.$emit('error', res)
            })
        }

        $scope.upload.onCompleteItem = function (item, response, status, headers) {
            if (response.info && response.info !== 'fail') {
                item.tmpLink = response.info
            } else {
                $scope.upload.removeFromQueue(item)

                $scope.$emit('error', 'timeline.notify.error.UPLOAD')
            }

            $scope.refreshFile()
        }

        $scope.showAttachment = function (item) {
            dialogs.confirm(null, {
                template: 'app/views/_dialogs/patient/prescription/scan-with-delete.html',
                className: 'ngdialog-theme-plain',
                data: {
                    confirmPanel: 'panel-warning',
                    className: 'ngdialog-theme-plain',
                    link: ENV.uploadHost + '/' + item.tmpLink,
                    name: item.file.name,
                    isImg: $scope.typeIcon(item.file.type) === 'fa-camera'
                }
            }).then(function () {
                dialogs.confirm('timeline.delete.CONFIRM', {
                    className: 'ngdialog-theme-plain',
                    data: {
                        translate: true,
                        confirm: 'timeline.delete.YES',
                        confirmIcon: 'fa fa-fw fa-trash',
                        cancel: 'timeline.delete.NO'
                    }
                }).then(function () {
                    $scope.fileRemove(item)
                })
            })
        }

        $scope.showAttachmentPreview = function (item) {
            dialogs.confirm(null, {
                template: 'app/views/_dialogs/patient/prescription/scan.html',
                className: 'ngdialog-theme-plain',
                data: {
                    translate: true,
                    confirmPanel: 'panel-warning',
                    className: 'ngdialog-theme-plain',
                    link: '/upload/message/tmp/' + item.tmpLink
                }
            })
        }

        $scope.btn_reply = true;

        $scope.btn_reply_show = function() {
            $scope.btn_reply = false
        }

        $scope.reply_loading = 'hide'

        $scope.reply = function () {
            $scope.reply.files = []

            angular.forEach($scope.files, function(file) {
                $scope.reply.files.push({
                    name: file.file.name,
                    link: file.tmpLink
                })
            })

            $scope.reply_loading = 'loading'

            $http.post('api/room/update', {
                roomId: $scope.room.id,
                content: $scope.reply.content,
                files: $scope.reply.files
            }).then(function (res) {
                parse_data(res.data)

                $scope.files = []
                $scope.reply.files = []
                $scope.reply.content = ''
                $scope.upload.clearQueue()

                $scope.btn_reply = true;

                $scope.reply_loading = 'hide';

                $scope.$emit('success', 'messages.notify.ADD')
            }, function () {
                $scope.reply_loading = 'hide'

                $scope.$emit('error', 'messages.notify.error.ADD')
            })
        }

        $scope.checkIfUserRead = function(message) {
            let _return = false

            if(message.department.id == activeDepartmentId) {
                _return = true
            } else {
                angular.forEach(message.read_in_my_department, function(user_read) {
                    if(user_read.id === uac.user.id) {
                        _return = true
                    }
                })
            }

            return _return
        }

        $scope.disabled_btn_read = false

        $scope.goCheck = function (message_id) {
            $scope.disabled_btn_read = true

            $http.get('api/room/message/read/' + message_id).then(function (res) {
                parse_data(res.data)

                $scope.getBadges()

                $scope.$emit('success', 'messages.notify.READ')

                $scope.disabled_btn_read = false
            }, function() {
                $scope.$emit('error', 'messages.notify.error.READ')

                $scope.disabled_btn_read = false
            })
        }

        $scope.infoUnread = function (message_id) {
            dialogs.confirm(null, {
                template: 'app/views/_dialogs/message-inforead.html',
                className: 'ngdialog-theme-plain',
                data: {
                    className: 'ngdialog-theme-plain'
                },
                controller: ['$scope', function(inner) {
                    $http.get('api/room/message/read/users-list/' + message_id).then(function(res) {
                        inner.data = res.data
                    }, function() {
                        $scope.$emit('error', 'messages.notify.error.USERREADLIST')
                    });
                }]
            })
        }

        $scope.$on('$destroy', function() {
            $scope.info.sth_no_read = false
            $scope.info.owner = false
        })
    }
])
