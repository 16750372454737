angular.module('eOpti.app.dashboard').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dashboard', {
            url: '/dashboard',
            template: '<opti-dashboard></opti-dashboard>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.DASHBOARD'
                }]
            }]
        })
}])