angular.module('eOpti.app.download').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.downloads', {
            url: '/downloads',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.downloads.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'change_date',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/downloads/table.html',
            controller: 'download.table'
        })
        .state('app.download', {
            url: '/download',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.download.details', {
            url: '/details/:id',
            params: {
                from: false
            },
            template: '<opti-downloading-details></opti-downloading-details>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    functions: 'downloadList',
                    args: ['state']
                }, {
                    name: 'download.breadcrumb.DOWNLOADING'
                }]
            }]
        })
        .state('app.download.add', {
            url: '/add',
            templateUrl: 'app/views/downloads/add.html',
            controller: 'download.add'
        })
        .state('app.download.edit', {
            url: '/edit/:id',
            templateUrl: 'app/views/downloads/edit.html',
            controller: 'download.edit'
        })
        .state('app.download.delete', {
            url: '/delete/:id',
            templateUrl: 'app/views/downloads/delete.html',
            controller: 'download.delete'
        })
        .state('app.download.history', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'created_at',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/downloads/history.html',
            controller: 'download.history'
        })
}])