angular.module('eOpti.app.tests').controller('questionEditController', [
    '$scope', '$http', '$state', '$timeout', 'breadcrumb', 'tests.service', 'formValidate', 'error',
    function ($scope, $http, $state, $timeout, breadcrumb, tests, formValidate, errorValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            defaults: 'testsResponsibilitiesList'
        }, {
            functions: 'testResponsibility',
            args: ['state', $state.params.id]
        }, {
            functions: 'testQuestion',
            args: [$state.params.question_id]
        }, {
            name: 'test.breadcrumb.EDIT'
        }];

        $scope.formValidate = formValidate;

        $scope.responsibility = {questions: []};
        $scope.form = {};
        $scope.max = 0;
        $scope.time = {
            minute: 0,
            second: 0
        };

        var parseTime = function() {
            $scope.time = {
                minute: parseInt($scope.form.time / 60),
                second: $scope.form.time % 60
            };
        };

        $scope.responsibilities = [];

        tests.responsibilities.resource.query(function(res) {
            $scope.responsibilities = res;
        });

        tests.responsibilities.resource.get({responsibilityId: $state.params.id}, function(res) {
            $scope.responsibility = res;

            angular.forEach($scope.responsibility.questions, function(q) {
                if($state.params.question_id == q.id) {
                    $scope.form = q;

                    if($scope.form.picture) {
                        $scope.form.picture = '../test/question/' + $scope.form.picture;
                    }

                    parseTime();
                }
            });

            $scope.$watch('form', function(form) {
                $scope.max = Math.max(parseInt(form.pointsA), parseInt(form.pointsB),
                    parseInt(form.pointsC), parseInt(form.pointsD));
            }, true);

            $scope.$watch('time', function(time) {
                $scope.form.time = time.minute * 60 + time.second;
            }, true);

            $scope.loading = Object.keys($scope.form).length ? 'hide' : 'empty';
        }, function(error) {
            $scope.loading = 'error';
        });

        $scope.editQuestionTest = function () {
            tests.questions.resource.update($scope.form, function () {
                $scope.$emit('success', 'test.question.notify.EDIT');

                $state.go('app.test.responsibility.details', {
                    id: $scope.form.tests_responsibility_id
                });
            }, function (error) {
                errorValidate.request(error, 'test.question.notify.error.EDIT');
            });
        };

        $scope.existsQuestion = false;

        var timeout = false;

        $scope.$watch('form.question', function(question) {
            if(question) {
                if(timeout) {
                    $timeout.cancel(timeout);
                }

                timeout = $timeout(function() {
                    $http.post('api/validate/dbmatch', {
                        data: 'test_questions,question,' + $scope.form.id + ',id',
                        compare: question
                    }).then(function (res) {
                        $scope.existsQuestion = res.data.info == 'ok' ? false : true;
                    }, function (error) {
                        scope.$emit('error', 'default.notify.ERRORDBMATCH');
                    });
                }, 500);
            } else {
                $scope.existsQuestion = false;
            }
        });
    }
]);