angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.previous-lenses-contact-lenses', {
            url: '/previous-lenses-contact-lenses',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.previous-lenses-contact-lenses.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: `<div class="row">
               <div class="col-xs-12">
                  <base-table end-point="api/dictionary/previous-lenses-contact-lenses/table" 
                        header="sidebar.settings.PREVIOUS_LENSES_CONTACT_LENSES" sub-header="" 
                        ref="app.dictionary.previous-lenses-contact-lenses.table">
                        <btn default icon="fa fa-plus" ui-sref="app.dictionary.previous-lenses-contact-lens.add">
                            {{'dictionary.previousLensesContactLenses.table.action.ADD'|translate}}
                        </btn>
                  </base-table>
               </div>
            </div>`,
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'services.breadcrumb.DICTIONARY'
                }, {
                    functions: 'previousLensesContactLensesList',
                    args: [null]
                }]
            }]
        })

        .state('app.dictionary.previous-lenses-contact-lens', {
            url: '/previous-lenses-contact-lens',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.previous-lenses-contact-lens.add', {
            url: '/add',
            template: '<opti-dictionary-previous-lenses-contact-lenses-add></opti-dictionary-previous-lenses-contact-lenses-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'services.breadcrumb.DICTIONARY'
                }, {
                    functions: 'previousLensesContactLensesList',
                    args: ['state']
                }, {
                    name: 'dictionary.previousLensesContactLenses.table.action.ADD'
                }]
            }]
        })
        .state('app.dictionary.previous-lenses-contact-lens.edit', {
            url: '/:id/edit',
            template: '<opti-dictionary-previous-lenses-contact-lenses-edit></opti-dictionary-previous-lenses-contact-lenses-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'services.breadcrumb.DICTIONARY'
                }, {
                    functions: 'previousLensesContactLensesList',
                    args: ['state']
                }, {
                    name: 'dictionary.previousLensesContactLenses.breadcrumb.LENSES',
                    id: $state.params.id
                }, {
                    name: 'dictionary.previousLensesContactLenses.breadcrumb.EDIT'
                }]
            }]
        })
}])
