angular.module('eOpti.app.layout').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.layout', {
            url: '/layout',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.layout.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/table.html',
            controller: 'layoutListController'
        })
        .state('app.layout.details', {
            url: '/:id/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/details.html',
            controller: 'layoutDetailsController'
        })
        .state('app.layout.all', {
            url: '-all/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/all-table.html',
            controller: 'layoutAllDetailsController'
        })
        .state('app.layout.ranges', {
            url: '-ranges/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/ranges-table.html',
            controller: 'layoutRangesController'
        })
        .state('app.layout.range', {
            url: '-range',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.layout.range.add', {
            url: '/add',
            params: {
                error_id: null
            },
            template: '<opti-layout-range-add></opti-layout-range-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'layoutList',
                    args: ['state']
                }, {
                    functions: 'layoutRanges',
                    args: ['state']
                }, {
                    name: 'layout.breadcrumb.RANGEADD'
                }]
            }]
        })
        .state('app.layout.range.edit', {
            url: '/:id/edit',
            template: '<opti-layout-range-edit></opti-layout-range-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'layoutList',
                    args: ['state']
                }, {
                    functions: 'layoutRanges',
                    args: ['state']
                }, {
                    name: 'layout.breadcrumb.RANGEEDIT',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.layout.range.delete', {
            url: '/:id/delete',
            template: '<opti-layout-range-delete></opti-layout-range-delete>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'layoutList',
                    args: ['state']
                }, {
                    functions: 'layoutRanges',
                    args: ['state']
                }, {
                    name: 'layout.breadcrumb.RANGEDELETE',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.layout.alerts', {
            url: '-alerts/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/alerts-table.html',
            controller: 'layoutAlertsController'
        })
        .state('app.layout.blocks', {
            url: '-blocks/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/blocks-table.html',
            controller: 'layoutBlocksController'
        })
        .state('app.layout.supply', {
            url: '-supply/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/layout/supply-table.html',
            controller: 'layoutSupplyListController'
        })
        .state('app.layout.request', {
            url: '-request/:layout_id/:id',
            templateUrl: 'app/views/layout/request.html',
            controller: 'layoutRequestController'
        })
        .state('app.layout.grid', {
            url: '-grid/:departmentId',
            params: {
                open: false
            },
            templateUrl: 'app/views/layout/view.html',
            controller: 'viewController'
        })
        .state('app.layout.grid.unit', {
            url: '/unit/:id',
            params: {
                select: null
            },
            template: '<opti-layout-unit ng-if="selection" v-props-selection="selection" v-props-navi="navi" v-props-details-id="detailsId"></opti-layout-unit><div data-ui-view="" data-autoscroll="false"></div>'
        })
        .state('app.layout.grid.unit.products', {
            url: '/products/:brandId',
            params: {
                random: Math.random()
            },
            template: '<opti-layout-products v-on-set-navi-loading="updateNaviLoading"></opti-layout-products>'
        })
        .state('app.layout.grid.object', {
            url: '/object/:id',
            templateUrl: 'app/views/layout/objects/config.html',
            controller: 'objectConfigController',
            resolve: {
                model: ['$http', '$stateParams', ($http, $stateParams) => {
                    return $http.get('api/layout/object/' + $stateParams.id)
                }]
            }
        })
        .state('app.layout.grid.advertisement', {
            url: '/object/:id',
            templateUrl: 'app/views/layout/objects/upload.html',
            controller: 'objectConfigController',
            resolve: {
                model: ['$http', '$stateParams', ($http, $stateParams) => {
                    return $http.get('api/layout/object/' + $stateParams.id)
                }]
            }
        })
}])