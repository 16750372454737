angular.module('eOpti.app.bugtrack').config(['$stateProvider', function($stateProvider) {
    let generateErrorStatus = () => {
        let statuses = ['new', 'confirmed', 'duringchange', 'solved', 'checked', 'closed', 'froze', 'cancelled', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.errors.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: '10',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/bugtrack/table/' + status + '.html',
                controller: ['breadcrumb', function(breadcrumb) {
                    breadcrumb.data = [{
                        last: 2
                    }, {
                        name: 'default.breadcrumb.OTHERS'
                    }, {
                        name: 'bugtrack.breadcrumb.ERRORS'
                    }, {
                        functions: 'errors',
                        args: 'bugtrack.status.' + status.toUpperCase()
                    }]
                }]
            })
        })
    }

    $stateProvider
        .state('app.errors', {
            url: '/errors',
            abstract: true,
            template: '<ui-view/>'
        })

    generateErrorStatus()

    $stateProvider
        .state('app.error', {
            url: '/error',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.error.details', {
            url: '/details/:id',
            template: '<opti-errors-details v-on-status-name-was-changed="statusNameWasChanged"></opti-errors-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'bugtrack.breadcrumb.ERRORS'
                }, {
                    functions: 'errors',
                    args: null
                }, {
                    functions: 'error',
                    args: [$state.params.id]
                }]

                $scope.statusNameWasChanged = statusName => {
                    breadcrumb.data[3].args = statusName
                }
            }]
        })
        .state('app.error.changelog', {
            url: '/changelog',
            template: '<opti-errors-changelog></opti-errors-changelog>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'bugtrack.breadcrumb.ERRORS'
                }, {
                    name: 'bugtrack.breadcrumb.CHANGELOG'
                }]
            }]
        })
}])
