angular.module('eOpti.app.tasks').controller('packsController', [
    '$scope', '$http', '$stateParams', 'breadcrumb', '$sce', 'dialogs', '$storage',
    function($scope, $http, $stateParams, breadcrumb, $sce, dialogs, $storage) {
        breadcrumb.data = [{
            functions: 'catalog',
            args: ['state']
        }, {
            functions: 'packs',
            args: [null]
        }]

        $scope.youtube = pack => {
            pack.link = '/video/' + pack.id + '.mp4'

            dialogs.confirm(null, {
                template: 'app/views/_dialogs/task/pack-youtube.html',
                className: 'ngdialog-theme-plain', //ngdialog-full-width
                data: {
                    confirmPanel: 'panel-primary',
                    name: pack.name,
                    link: pack.link

                }
            })
        }

        $storage.get('packIcon').then(val => {
            let icons = {}

            angular.forEach(val.groups, group => {
                angular.forEach(group, subgroup => {
                    angular.forEach(subgroup, item => {
                        angular.forEach(item, i => {
                            if(typeof i.iconId !== 'undefined') {
                                icons[i.iconId] = i.name
                            }
                        })
                    })
                })
            })

            $scope.icons = icons
        })

        $storage.get('package').then(pack => {
            let delivery_time = {}

            angular.forEach(pack, p => {
                delivery_time [p.info.packId] = p.info.delivery_time
            })

            $scope.delivery_time = delivery_time
        })

        $scope.renderers = {
            details(value) {
                let string = ''

                value = value.includes(',') ? value.split(',') : [value]
                value.forEach(val => {
                    let name = $scope.icons[val] || ''
                    string += `<img style="width:44px;margin:1px" src="img/task/pack/${val}.png" 
                        ng-click="renderers.youtube({ id: ${val}, name: '${name}' })">`
                })

                return string
            },

            youtube: $scope.youtube
       }
    }])

