angular.module('eOpti.app.marketing').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.marketing', {
                    url: '/marketing',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.marketing.list', {
                    url: '/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/marketing/table.html',
                    controller: 'marketingListController'
                })
                .state('app.marketing.add', {
                    url: '/add',
                    templateUrl: 'app/views/marketing/add.html',
                    controller: 'marketingAddController'
                })
                .state('app.marketing.edit', {
                    url: '/action/:id/edit',
                    templateUrl: 'app/views/marketing/edit.html',
                    controller: 'marketingEditController'
                })
                .state('app.marketing.delete', {
                    url: '/action/:id/delete',
                    templateUrl: 'app/views/marketing/delete.html',
                    controller: 'marketingDeleteController'
                })
                .state('app.marketing.link', {
                    url: '/action/:id/link',
                    controller: ['$state', 'marketing.service', function($state, marketing) {
                        marketing.resource.get({marketingId: $state.params.id}, function(marketing) {
                            angular.forEach(marketing.uploads, function(upload) {
                                window.open('upload/marketing/' + upload.upload, '_blank');
                            });
                        }, function() {
                            $scope.$emit('error', 'marketing.notify.ERRORLOADDATA');
                        });

                        $state.previous();
                    }]
                })
        }
    ]
);
