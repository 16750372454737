angular.module('eOpti.app.excel').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.excel', {
            url: '/excel',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.excel.history-list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/excel/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'excelhistory.breadcrumb.HISTORY'
                }]
            }]
        })
        .state('app.excel.history', {
            url: '/excel/history',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.excel.history.details', {
            url: '/details/:id',
            template: '<opti-report-excel-history-downloading></opti-report-excel-history-downloading>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'excelhistory.breadcrumb.HISTORY',
                    state: 'app.excel.history-list'
                }, {
                    name: 'download.breadcrumb.DOWNLOADING'
                }]
            }]
        })
}])
