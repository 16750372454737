angular.module('eOpti.app.tests').controller('testAssignEditController', [
    '$scope', '$http', '$state', 'breadcrumb', 'tests.service', 'formValidate',
    function ($scope, $http, $state, breadcrumb, tests, formValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            functions: 'test',
            args: ['state', $state.params.id]
        }, {
            name: 'test.breadcrumb.ASSIGN'
        }]

        $scope.formValidate = formValidate

        $scope.user_ids = []
        $scope.disabled = []

        $scope.getUserIds = user_ids => {
            $scope.user_ids = user_ids
        }

        tests.resource.get({testId: $state.params.id}, res => {
            $scope.test = res

            angular.forEach($scope.test.assignUsers, assignUser => {
                $scope.user_ids.push(assignUser.id);
            })

            $http.get('api/tests/assign/disabled-checked/' + $state.params.id).then(res => {
                $scope.disabled = res.data.result_user

                $scope.loading = Object.keys($scope.test).length ? 'hide' : 'empty'
            }, () => {
                $scope.loading = 'error'
            })
        })

        $scope.editTestUsers = () => {
            $http.post('api/tests/assign/edit', {
                test_id: $scope.test.id,
                user_ids: $scope.user_ids
            }).then(res => {
                if(res.data.status) {
                    $state.go('app.tests')

                    $scope.$emit('success', 'test.assign.notify.SAVE')
                } else {
                    $scope.$emit('error', 'test.assign.notify.error.SAVE')
                }
            }, () => {
                $scope.$emit('error', 'test.assign.notify.error.SAVE')
            })
        }
    }
])