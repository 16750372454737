angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.specialists', {
            url: '/specialists',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.specialists.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/dictionary/specialist/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    name: 'dictionary.specialist.breadcrumb.SPECIALISTS'
                }]
            }]
        })

    $stateProvider
        .state('app.dictionary.specialist', {
            url: '/specialist',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.specialist.add', {
            url: '/add',
            template: '<opti-dictionary-specialist-add></opti-dictionary-specialist-add>',
            controller: ['breadcrumb', function (breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'specialistList'
                }, {
                    name: 'dictionary.specialist.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.dictionary.specialist.edit', {
            url: '/:id/edit',
            template: '<opti-dictionary-specialist-edit></opti-dictionary-specialist-edit>',
            controller: [
                '$state', 'breadcrumb',
                function ($state, breadcrumb) {
                    breadcrumb.data = [{
                        defaults: 'settingsList'
                    }, {
                        name: 'settings.breadcrumb.DICTIONARY'
                    },
                        {
                        defaults: 'specialistList'
                    }, {
                        functions: 'specialistList',
                        args: [$state.params.id]
                    }]
                }
            ]
        })
        .state('app.dictionary.specialist.delete', {
            url: '/:id/delete',
            template: '<opti-dictionary-specialist-delete></opti-dictionary-specialist-delete>',
            controller: [
                '$state', 'breadcrumb', function ($state, breadcrumb) {
                    breadcrumb.data = [{
                        defaults: 'settingsList'
                    }, {
                        name: 'settings.breadcrumb.DICTIONARY'
                    },
                        {
                        defaults: 'specialistList'
                    }, {
                        functions: 'specialistList',
                        args: [$state.params.id]
                    }, {
                        name: 'dictionary.specialist.breadcrumb.DELETE'
                    }]
                }
            ]
        })
}])
