angular.module('eOpti.app.register').controller('registerHistoryController', [
    '$scope', '$http', '$state', '$filter', '$localStorage', 'formValidate', 'breadcrumb', 'months', 'uac',
    function ($scope, $http, $state, $filter, $localStorage, formValidate, breadcrumb, months, uac) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'register.breadcrumb.REGISTER'
        }, {
            functions: 'register',
            args: ['nostate', 'register.breadcrumb.HISTORY']
        }];

        $scope.formValidate = formValidate;

        $scope.result = {
            from: null,
            to: null
        };

        $scope.isToday = function () {
            if ($filter('date')(new Date(), 'yyyy-MM-dd') === $scope.result.from &&
                $scope.result.from === $scope.result.to) {
                return true;
            }

            return false;
        };

        $scope.loadingReceipts = 'hide';

        $scope.goCalculate = function () {
            $scope.loadingReceipts = 'loading';

            $http.post('api/register/calculate', $scope.search).then(function (res) {
                $scope.result = res.data;

                $scope.loadingReceipts = $scope.result.receipts.length ? 'hide' : 'empty';
            }, function (error) {
                $scope.loadingReceipts = 'error';
            });
        };

        var start = function () {
            $scope.search = {
                type: 1,
                from: $filter('date')(new Date(), 'yyyy-MM-dd'),
                to: $filter('date')(new Date(), 'yyyy-MM-dd'),
                year: parseInt(new Date().getFullYear()),
                month: parseInt(new Date().getMonth()) + 1,
                days: 1,
                action: 0,
                mode: $state.current.name.substr($state.current.name.lastIndexOf('.') + 1)
            };

            if ($scope.search.mode === 'history') {
                if ('receipts' in $localStorage) {
                    $scope.search = $localStorage['receipts'];

                    $scope.goCalculate();
                } else {
                    $localStorage['receipts'] = $scope.search;
                }
            } else if ($scope.search.mode === 'current') {
                breadcrumb.data[2].args[1] = 'register.breadcrumb.CURRENT';

                $scope.search = {
                    type: 0,
                    from: $filter('date')(new Date(), 'yyyy-MM-dd'),
                    to: $filter('date')(new Date(), 'yyyy-MM-dd'),
                    year: parseInt(new Date().getFullYear()),
                    days: 1,
                    action: 0,
                    mode: 'current'
                };

                $scope.goCalculate();
            }
        };

        $scope.filtrAction = function (prop) {
            return function (item) {
                switch ($scope.result.action) {
                    case -1:
                        return item[prop] < 0;
                        break;
                    case 1:
                        return item[prop] >= 0;
                        break;
                    default:
                        return true;
                }
            }
        };

        $scope.goCloseDay = function () {
            $http.get('api/register/closeday/0').then(function (res) {
                if (res.data.status === 'OK') {
                    $scope.$emit('success', 'register.closeday.notify.CLOSEDDAY');
                } else {
                    $scope.$emit('error', 'register.closeday.notify.error.CLOSEDDAY');
                }

                $scope.goCalculate();
            }, function () {
                $scope.$emit('error', 'register.closeday.notify.error.CLOSEDDAY');
            });
        };

        $scope.midnight = false;

        $http.get('api/register/midnight').then(function(res) {
            $scope.midnight = res.data.status;

            $scope.$watch('midnight', function(nVal, oVal) {
                if(nVal != oVal) {
                    $http.get('api/register/midnight/' + (nVal ? 1 : 0)).then(function(res) {
                        $scope.midnight = !!parseInt(res.data.status);

                        $scope.$emit('success', 'register.midnight.notify.' +
                            ($scope.midnight ? 'NOCLOSED' : 'CLOSED'));
                    }, function() {
                        $scope.$emit('error', 'register.midnight.notify.error.CLOSE');
                    });
                }
            });
        }, function() {
            $scope.$emit('error', 'register.midnight.notify.error.LOAD');
        });

        $scope.focusType = function (type) {
            $scope.search.type = type;
        };

        $scope.years = [];
        $scope.months = [];

        var years = [],
            firstYear = 2016,
            startMonth = 1;

        var generateMonth = function () {
            var m = months.get(),
                tmp = [];

            if ($scope.search.year === new Date().getFullYear()) {
                var currentMonths = months.getToMonth(new Date().getMonth() + 1);

                angular.forEach(m, function (month, index) {
                    if (currentMonths.indexOf(month) !== -1) {
                        tmp.push(month);
                    }
                });

                m = tmp;
                tmp = [];
            }

            if ($scope.search.year === firstYear) {
                var firstYearMonths = months.getFromMonth(startMonth);

                angular.forEach(m, function (month) {
                    if (firstYearMonths.indexOf(month) !== -1) {
                        tmp.push(month);
                    }
                });

                m = tmp;
            }

            if (m.indexOf($scope.search.month) === -1) {
                $scope.search.month = m[0];
            }

            $scope.months = months.parse(m);
        };

        var generateOptions = function (startYear) {
            for (var y = parseInt(new Date().getFullYear()); y >= parseInt(startYear); --y) {
                years.push(y);
            }

            $scope.years = years;

            start();

            generateMonth();
        };

        if (!$localStorage['receipts-first-year-department']) {
            $localStorage['receipts-first-year-department'] = {};
        }

        var generate = {
            years: function () {
                var departmentId = uac.user.activeDepartmentId;

                if (Object.keys($localStorage['receipts-first-year-department']).indexOf(departmentId) !== -1) {
                    firstYear = $localStorage['receipts-first-year-department'][departmentId]['firstYear'];
                    startMonth = $localStorage['receipts-first-year-department'][departmentId]['startMonth'];

                    generateOptions(firstYear);
                } else {
                    $http.get('api/register/getFirstYear').then(function (res) {
                        firstYear = res.data.firstYear;
                        startMonth = res.data.startMonth;

                        generateOptions(firstYear);
                        $localStorage['receipts-first-year-department'][departmentId] = {
                            firstYear: firstYear,
                            startMonth: startMonth
                        };
                    }, function (error) {
                        console.error('Nie można pobrać roku - weźmie 2006');

                        generateOptions(firstYear);
                    });
                }


            },

            months: function () {
                var result = [];

                for (var m = 1; m <= 12; ++m) {
                    result.push(m);
                }

                return result;
            }
        };

        generate.years();

        $scope.$watch('search.year', function (nVal) {
            if (typeof $scope.search !== 'undefined') {
                generateMonth();
            }
        });

        $scope.$watch('search.days', function(nVal, oVal) {
            if(nVal !== oVal) {
                $scope.focusType(1);
            }
        });

        $scope.printPayment = function(id) {
            window.open('api/register/expenses/bank/print/' + id);
        };
    }
]);
