angular.module('eOpti.app.warehouse.orders').config(['$stateProvider', function($stateProvider) {
    let generateOrdersStatus = () => {
        let statuses = ['cancelled', 'accepted', 'confirmed', 'sent', 'done', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.warehouse.orders.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: '10',
                    pageSizeWarehouse: '20',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/warehouse/orders/table/' + status + '.html',
                controller: ['$scope', '$http', '$localStorage', 'breadcrumb', 'uac', function($scope, $http, $localStorage, breadcrumb, uac) {
                    breadcrumb.data = [{
                        last: 2
                    }, {
                        name: 'orders.breadcrumb.WAREHOUSE'
                    }, {
                        name: 'orders.breadcrumb.ORDERS'
                    }, {
                        functions: 'orders',
                        args: 'orders.status.' + status.toUpperCase()
                    }]

                    if(status === 'confirmed' && uac.permission('orders.admin')) {
                        $scope.renderers = {
                            id: (value, row) => {
                                return '<a class="link" ui-sref="app.warehouse.order.details({id: ' + value + '})">' +
                                    value + '</a>' + (row.wz_id ? ' <i class="fa fa-file-word-o text-success" ' +
                                    'title="{{\'orders.table.title.ICONWZ\'|translate}}"></i>' : '')
                            }
                        }

                        if(!window.localStorage.getItem('ngStorage-create_wz_checkbox')) {
                            //dlatego, że zdefiniowane jest to OptiOrdersDetails.vue
                            $localStorage['create_wz_checkbox'] = []
                        }

                        let _finally = () => {
                            $localStorage['create_wz_checkbox'] = []
                            $scope.$broadcast('table-refresh')
                        }

                        $scope.goCreateWZs = () => {
                            $http.post('api/warehouse/order/wz/group/create', {
                                order_ids: $localStorage['create_wz_checkbox']
                            }).then(res => {
                                _finally()

                                $scope.$emit('notify', {
                                    message: res.data.message,
                                    translate: false,
                                    status: 'success'
                                })

                                window.open(res.data.link, '_blank')
                            }, () => {
                                _finally()

                                $scope.$emit('error', 'orders.notify.error.CREATEGROUPWZ')
                            })
                        }
                    }
                }]
            })
        })
    }

    $stateProvider.state('app.warehouse.orders.toric', {
        templateUrl: 'app/views/warehouse/orders/table/toric.html',
        url: '/toric/:page/:search',
        params: {
            page: '1',
            pageSize: '10',
            sortBy: 'id',
            sortOrder: 'desc',
            search: {
                array: true
            },
            columns: {
                array: true
            }
        },
        controller: 'toricOrderController'
    })

    $stateProvider
        .state('app.warehouse.orders', {
            url: '/orders',
            abstract: true,
            template: '<ui-view/>'
        })

    generateOrdersStatus()

    $stateProvider
        .state('app.warehouse.order', {
            abstract: true,
            url: '/order',
            template: '<ui-view/>'
        })
        .state('app.warehouse.order.details', {
            url: '/{id:[0-9]+}',
            template: '<opti-orders-details v-on-update-breadcrumb="updateBreadcrumb"></opti-orders-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'orders.breadcrumb.ORDERS'
                }, {
                    functions: 'orders',
                    args: null
                }, {
                    functions: 'order',
                    args: [$state.params.id]
                }]

                $scope.updateBreadcrumb = status_name => {
                    breadcrumb.data[3].args = status_name
                }
            }]
        })
        .state('app.warehouse.order.add', {
            url: '/add',
            template: '<opti-cart-order-add></opti-cart-order-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'orders.breadcrumb.ORDERS'
                }, {
                    name: 'orders.breadcrumb.ADD'
                }]
            }]
        })

    $stateProvider
        .state('app.warehouse.order.wz', {
            url: '/wz',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.order.wz.details', {
            url: '/{id:[0-9]+}',
            template: '<opti-orders-wz-details></opti-orders-wz-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'orders.breadcrumb.ORDERS'
                }, {
                    functions: 'wz',
                    args: ['state']
                }, {
                    functions: 'wzId',
                    args: [$state.params.id, null]
                }]
            }]
        })
        .state('app.warehouse.order.wz.list', {
            url: '/list/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                pageSizeWarehouse: '20',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/warehouse/ordersWzTable.html',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'orders.breadcrumb.ORDERS'
                }, {
                    functions: 'wz',
                    args: [null]
                }]
            }]
        })
        .state('app.warehouse.order.wz.add', {
            url: '/add',
            template: '<opti-orders-wz-add-edit></opti-orders-wz-add-edit>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'orders.breadcrumb.ORDERS'
                }, {
                    functions: 'wz',
                    args: ['state']
                }, {
                    name: 'orders.wz.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.warehouse.order.wz.edit', {
            url: '/{id:[0-9]+}/edit',
            template: '<opti-orders-wz-add-edit v-props-wz-id="wz_id"></opti-orders-wz-add-edit>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'orders.breadcrumb.ORDERS'
                }, {
                    functions: 'wz',
                    args: ['state']
                }, {
                    functions: 'wzId',
                    args: [$state.params.id, 'state']
                }, {
                    name: 'orders.wz.breadcrumb.EDIT'
                }]

                $scope.wz_id = parseInt($state.params.id)
            }]
        })
}])