angular.module('eOpti.app.optometrist').controller('optometristParalyticController', [
    '$scope',
    function ($scope) {
        $scope.step3_result = 'optometrist.extra.paralytic.red.right.NONE';

        $scope.$watchGroup(['interview.paralytic.step1_type', 'interview.paralytic.step2_type',
            'interview.paralytic.step3_type'], function(nVal) {
            var prefix = 'optometrist.extra.paralytic.red.';

            if(nVal[0] === 1 && nVal[1] === 1 && nVal[2] === 1) {
                $scope.step3_result = prefix + 'left.LSD';
            } else if(nVal[0] === 1 && nVal[1] === 1 && nVal[2] === 2) {
                $scope.step3_result = prefix + 'left.LPD';
            } else if(nVal[0] === 1 && nVal[1] === 2 && nVal[2] === 1) {
                $scope.step3_result = prefix + 'right.PSG';
            } else if(nVal[0] === 1 && nVal[1] === 2 && nVal[2] === 2) {
                $scope.step3_result = prefix + 'left.LPG';
            } else if(nVal[0] === 2 && nVal[1] === 1 && nVal[2] === 1) {
                $scope.step3_result = prefix + 'right.PPG';
            } else if(nVal[0] === 2 && nVal[1] === 2 && nVal[2] === 1) {
                $scope.step3_result = prefix + 'left.LPD';
            } else if(nVal[0] === 2 && nVal[1] === 2 && nVal[2] === 2) {
                $scope.step3_result = prefix + 'right.PSD';
            } else if(nVal[0] === 0 || nVal[1] === 0 || nVal[2] === 0) {
                $scope.step3_result = prefix + 'left.NONE';
            }
        });
    }
]);