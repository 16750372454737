angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.reclamationservices', {
            url: '/reclamation-services',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.reclamationservices.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/dictionary/reclamationservice/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    name: 'dictionary.reclamationservice.breadcrumb.RECLAMATIONSERVICES'
                }]
            }]
        })

    $stateProvider
        .state('app.dictionary.reclamationservice', {
            url: '/reclamation-service',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.reclamationservice.add', {
            url: '/add',
            template: '<opti-dictionary-reclamation-services-add></opti-dictionary-reclamation-services-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                    breadcrumb.data = [{
                        defaults: 'settingsList'
                    }, {
                        name: 'settings.breadcrumb.DICTIONARY'
                    },
                        {
                        defaults: 'reclamationServiceList'
                    }, {
                        name: 'dictionary.reclamationservice.breadcrumb.ADD'
                    }]
                }
            ]
        })
        .state('app.dictionary.reclamationservice.edit', {
            url: '/:id/edit',
            template: '<opti-dictionary-reclamation-services-edit></opti-dictionary-reclamation-services-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'reclamationServiceList'
                }, {
                    functions: 'reclamationService',
                    args: [$state.params.id]
                }]
            }]
        })
        .state('app.dictionary.reclamationservice.delete', {
            url: '/:id/delete',
            template: '<opti-dictionary-reclamation-services-delete></opti-dictionary-reclamation-services-delete>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'reclamationServiceList'
                }, {
                    functions: 'reclamationService',
                    args: [$state.params.id]
                }, {
                    name: 'dictionary.reclamationservice.breadcrumb.DELETE'
                }]
            }]
        })
}])
