/**
 * We're overriding angular-ui default pagination to allow us for global disable of whole control
 * attr: disable="someModel"
 */
angular.module('ui.bootstrap.pagination').config(['$provide', function ($provide) {
    $provide.decorator('paginationDirective', [
        '$delegate',
        function ($delegate) {
            var directive = $delegate[0];

            angular.extend(directive.scope, {
                disable: '='
            });

            return $delegate;
        }]);
}]);
/**
 * Replacing default template with ours that uses $directiveScope.disable
 */
angular.module("template/pagination/pagination.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("template/pagination/pagination.html",
        "<span><ul class=\"pagination\">\n" +
        "  <li ng-if=\"boundaryLinks\" ng-class=\"{disabled: noPrevious() || disable}\"><a href ng-click=\"disable ? angular.noop() : selectPage(1)\">{{'default.pagination.FIRST'|translate}}</a></li>\n" +
        "  <li ng-if=\"directionLinks\" ng-class=\"{disabled: noPrevious() || disable}\"><a href ng-click=\"disable ? angular.noop() : selectPage(page - 1)\">{{'default.pagination.PREVIOUS'|translate}}</a></li>\n" +
        "  <li ng-repeat=\"page in pages track by $index\" ng-class=\"{active: page.active, disabled: disable}\"><a href ng-click=\"disable ? angular.noop() : selectPage(page.number)\">{{page.text}}</a></li>\n" +
        "  <li ng-if=\"directionLinks\" ng-class=\"{disabled: noNext() || disable}\"><a href ng-click=\"disable ? angular.noop() : selectPage(page + 1)\">{{'default.pagination.NEXT'|translate}}</a></li>\n" +
        "  <li ng-if=\"boundaryLinks\" ng-class=\"{disabled: noNext() || disable}\"><a href ng-click=\"disable ? angular.noop() : selectPage(totalPages)\">{{'default.pagination.LAST'|translate}}</a></li>\n" +
        "</ul>\n" +
        "<ul class=\"pagination\">\n" +
        "  <li ng-class=\"{disabled: totalPages === 1}\"><number ng-model=\"model.page\" ng-disabled=\"totalPages === 1\" name=\"page\" model=\"$parent.criteria\" field=\"page\" callback=\"$parent.paginate()\">{{'default.pagination.PAGE'|translate}}: {{page}} / {{totalPages}}</number></li>\n" +
        "</ul></span>");
}]);