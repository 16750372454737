angular.module('eOpti.app.tasks').controller('grinderyEditTaskController', [
    '$scope',
    '$http',
    '$state',
    'breadcrumb',
    'formValidate',
    function ($scope, $http, $state, breadcrumb, formValidate) {
        breadcrumb.data = [{
            defaults: 'settingsList'
        }, {
            name: 'settings.breadcrumb.DICTIONARY'
        }, {
            defaults: 'grinderyScoreTypeList'
        }, {
            name: 'tasks.grindery.breadcrumb.SCORE',
            id: $state.params.id
        }, {
            name: 'tasks.grindery.breadcrumb.EDIT'
        }];

        $scope.formValidate = formValidate;

        $scope.grindery = {};

        $http.get('api/tasks/grindery/' + $state.params.id).then(function(res) {
            $scope.grindery = res.data;

            $scope.loadingGrindery = 'hide';
        }, function() {
            $scope.loadingGrindery = 'error';
        });

        $scope.goEdit = function() {
            $http.post('api/tasks/grindery/edit', $scope.grindery).then(function() {
                $scope.loadingGrindery = 'loading';

                $scope.$emit('success', 'tasks.grindery.notify.EDIT');

                $state.go('app.tasks-grindery.list');
            }, function() {
                $scope.loadingGrindery = 'loading';

                $scope.$emit('danger', 'tasks.grindery.notify.error.EDIT');
            });
        };
    }
]);
