angular.module('eOpti.app.tests').controller('testEditController', [
    '$scope', '$http', '$state', 'breadcrumb', 'tests.service', 'formValidate', 'error',
    function($scope, $http, $state, breadcrumb, tests, formValidate, errorValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            functions: 'test',
            args: ['state', $state.params.id]
        }, {
            name: 'test.breadcrumb.EDIT'
        }]

        $scope.formValidate = formValidate

        $scope.test = {}

        $scope.responsibilities = {}

        $scope.time = 0

        tests.resource.get({testId: $state.params.id}, function(res) {
            $scope.test = res;
            $scope.test.questions_ids = [];

            tests.responsibilities.resource.query(function(res) {
                $scope.responsibilities = res;

                angular.forEach($scope.test.questions, function(quantity) {
                    $scope.test.questions_ids.push(quantity.id);
                });

                angular.forEach($scope.responsibilities, function(responsibility) {
                    responsibility.quantity = 0;

                    angular.forEach(responsibility.questions, function(question) {
                        if($scope.test.questions_ids.indexOf(question.id) > -1) {
                            question.checked = 1;

                            ++responsibility.quantity;
                            $scope.time += question.time;
                        }
                    });
                });

                $scope.formTest.$setValidity('questions', $scope.test.questions_ids.length > 0 ? true : false);

                $scope.loadingResponsibility = Object.keys($scope.responsibilities).length ? 'hide' : 'empty';
            }, function(error) {
                $scope.loadingResponsibility = 'error';
            });

            $scope.loading = Object.keys($scope.test).length ? 'hide' : 'empty';
        });

        $scope.setQuestion = function() {
            var someChecked = false;

            $scope.test.questions_ids = [];
            $scope.time = 0;

            angular.forEach($scope.responsibilities, function(responsibility) {
                responsibility.quantity = 0;

                angular.forEach(responsibility.questions, function(question) {
                    if(question.checked) {
                        someChecked = true;

                        ++responsibility.quantity;
                        $scope.time += question.time;

                        $scope.test.questions_ids.push(question.id);
                    }
                });
            });

            $scope.formTest.$setValidity('questions', someChecked ? true : false);
        };

        $http.get('api/roles').then(function(res) {
            $scope.roles = res.data;
        }, function(error) {
            $scope.$emit('error', 'test.create.notify.error.LOADROLES');
        });

        $scope.editTest = function () {
            tests.resource.update($scope.test, function () {
                $scope.$emit('success', 'test.create.notify.NAMEEDIT');

                $state.go('app.tests');
            }, function (error) {
                errorValidate.request(error, 'test.create.notify.error.NAMEEDIT');
            });
        };
    }
]);