angular.module('eOpti.app.sms.template').controller('smsTemplateEditController', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'sms.template.service', 'error',
    function ($scope, $http, $state, breadcrumb, formValidate, template, errorValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'settingsList'
        }, {
            name: 'sms.template.breadcrumb.SMS'
        }, {
            functions: 'smsTemplateList',
            args: ['state']
        }, {
            name: 'sms.template.breadcrumb.TEMPLATE',
            id: $state.params.id
        }, {
            name: 'sms.template.breadcrumb.EDIT'
        }];

        $scope.formValidate = formValidate;

        $scope.tags = {};

        $scope.pills = [];

        $scope.template = {
            body: '',
            type: 0
        };

        template.resource.get({templateId: $state.params.id}, function(res) {
            $scope.template = res;

            $scope.loadingSmsTemplate = $scope.template ? 'hide' : 'empty';
        }, function() {
            $scope.loadingSmsTemplate = 'error';
        });

        template.tagi().then(function(res) {
            $scope.tags = res;

            $scope.pills = Object.keys($scope.tags);

            $scope.preview = template.preview($scope.template.body, res);
        }, function() {
            $scope.$emit('error', 'sms.template.notify.error.LOADTAG');
        });

        $scope.$watch('template.body', function(nVal, oVal) {
            $scope.preview = template.preview(nVal, $scope.tags);
        });

        $scope.max = template.max;

        $scope.editTemplate = function () {
            template.resource.update($scope.template, function () {
                $scope.$emit('success', 'sms.template.notify.EDITED');

                $state.go('app.sms.template.list');
            }, function (error) {
                errorValidate.request(error, 'sms.template.notify.error.EDITED');
            });
        };

        $scope.addTag = function(name, allow) {
            var position = angular.element('textarea#body')[0].selectionStart;

            if(allow) {
                var before = $scope.template.body.substring(0, position),
                    after = $scope.template.body.substring(position, $scope.template.body.length);

                $scope.template.body = before + name + after;
            }
        };
    }]);