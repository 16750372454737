angular.module('eOpti.app.warehouse.returns').config(['$stateProvider', function($stateProvider) {
    let generateReturnsStatus = () => {
        let statuses = ['placed', 'done', 'cancelled', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.warehouse.returns.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: '10',
                    pageSizeWarehouse: '20',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/warehouse/returns/table/' + status + '.html',
                controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                    breadcrumb.data = [{
                        last: 2
                    }, {
                        name: 'returns.breadcrumb.WAREHOUSE'
                    }, {
                        name: 'returns.breadcrumb.RETURNS'
                    }, {
                        functions: 'returns',
                        args: 'returns.status.' + status.toUpperCase()
                    }]

                    $scope.renderers = {
                        comments(value) {
                            return value
                        }
                    }
                }]
            })
        })
    }

    $stateProvider
        .state('app.warehouse.returns', {
            url: '/returns',
            abstract: true,
            template: '<ui-view/>'
        })

    generateReturnsStatus()

    $stateProvider
        .state('app.warehouse.return', {
            abstract: true,
            url: '/return',
            template: '<ui-view/>'
        })
        .state('app.warehouse.return.details', {
            url: '/{id:[0-9]+}',
            template: '<opti-sendback-details v-on-status-name-was-changed="statusNameWasChanged"></opti-sendback-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'returns.breadcrumb.WAREHOUSE'
                }, {
                    name: 'returns.breadcrumb.RETURNS'
                }, {
                    functions: 'returns',
                    args: null
                }, {
                    functions: 'return',
                    args: [$state.params.id]
                }]

                $scope.statusNameWasChanged = statusName => {
                    breadcrumb.data[3].args = statusName
                }
            }]
        })
        .state('app.warehouse.return.add', {
            url: '/add',
            template: '<opti-cart-sendback-add></opti-cart-sendback-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'returns.breadcrumb.WAREHOUSE'
                }, {
                    name: 'returns.breadcrumb.RETURNS'
                }, {
                    name: 'returns.breadcrumb.ADD'
                }]
            }]
        })
}])
