angular.module('eOpti.app.warehouse.products').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.warehouse.products', {
            abstract: true,
            url: '/products',
            template: '<ui-view/>'
        })

        .state('app.warehouse.products.export', {
            url: '/export',
            template: '<opti-product-export></opti-product-export>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.EXPORT'
                }]
            }]
        })

        .state('app.warehouse.products.import', {
            url: '/import',
            template: '<opti-product-import></opti-product-import>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.IMPORT'
                }]
            }]
        })

        .state('app.warehouse.products.delivery', {
            url: '/delivery',
            template: '<opti-product-delivery></opti-product-delivery>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.DELIVERY'
                }]
            }]
        })

        .state('app.warehouse.products.lp', {
            url: '/lp',
            template: '<opti-product-lp></opti-product-lp>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.LP'
                }]
            }]
        })

        .state('app.warehouse.products.selection', {
            url: '/selection',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<opti-products-selection></opti-products-selection>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SELECTION'
                }]
            }]
        })

        .state('app.warehouse.products.supplier_info_import', {
            url: '/supplier-info-import',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<opti-supplier-product-info-import></opti-supplier-product-info-import>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_INFO_IMPORT'
                }]
            }]
        })

        .state('app.warehouse.products.selection.table', {
            url: '/:page/:search',
            parent: 'app.warehouse.products.selection'
        })

        .state('app.warehouse.products.more_database', {
            url: '/more-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/more/table" ' +
                'ref="app.warehouse.products.more_database" ' +
                'header="product.supplier_database.MORE_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                {
                    name: 'More'
                }]
            }]
        })
        .state('app.warehouse.products.smile_database', {
            url: '/smile-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/smile/table" ' +
                'ref="app.warehouse.products.smile_database" ' +
                'header="product.supplier_database.SMILE_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                    {
                        name: 'Smile'
                    }]
            }]
        })
        .state('app.warehouse.products.kering_database', {
            url: '/kering-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/kering/table" ' +
                'ref="app.warehouse.products.smile_database" ' +
                'header="product.supplier_database.KERING_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                    {
                        name: 'Kering'
                    }]
            }]
        })
        .state('app.warehouse.products.thelios_database', {
            url: '/thelios-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/thelios/table" ' +
                'ref="app.warehouse.products.thelios_database" ' +
                'header="product.supplier_database.THELIOS_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                    {
                        name: 'Thelios'
                    }]
            }]
        })
        .state('app.warehouse.products.einar_database', {
            url: '/einar-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/einar/table" ' +
                'ref="app.warehouse.products.einar_database" ' +
                'header="product.supplier_database.EINAR_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                    {
                        name: 'Einar'
                    }]
            }]
        })
        .state('app.warehouse.products.ic_berlin_database', {
            url: '/ic-berlin-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/ic_berlin/table" ' +
                'ref="app.warehouse.products.ic_berlin_database" ' +
                'header="product.supplier_database.IC_BERLIN_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                    {
                        name: 'Ic Berlin'
                    }]
            }]
        })
        .state('app.warehouse.products.kaleos_database', {
            url: '/kaleos-database',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'product_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/supplier_database/kaleos/table" ' +
                'ref="app.warehouse.products.kaleos_database" ' +
                'header="product.supplier_database.KALEOS_HEADING"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    name: 'product.breadcrumb.PRODUCTS'
                }, {
                    name: 'product.breadcrumb.SUPPLIER_DATABASES.HEADING',
                },
                    {
                        name: 'Kaleos'
                    }]
            }]
        })

        //attributes
        .state('app.warehouse.products.attributes', {
            abstract: true,
            url: '/attributes',
            template: '<ui-view/>'
        })
        .state('app.warehouse.products.attributes.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/warehouse/productsAttributesTable.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'attributes.product.breadcrumb.ATTRIBUTES'
                }]
            }]
        })
        .state('app.warehouse.products.attributes.add', {
            url: '/add',
            template: '<opti-dictionary-product-attributes-add></opti-dictionary-product-attributes-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    defaults: 'productsAttributesList'
                }, {
                    name: 'attributes.product.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.warehouse.products.attributes.edit', {
            url: '/attribute/:id/edit',
            template: '<opti-dictionary-product-attributes-edit></opti-dictionary-product-attributes-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    defaults: 'productsAttributesList'
                }, {
                    name: 'attributes.product.breadcrumb.ATTRIBUTE',
                    id: $state.params.id
                }, {
                    name: 'attributes.product.breadcrumb.EDIT'
                }]
            }]
        })

        .state('app.warehouse.eshop', {
            url: '/eshop/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/shopware/products/table" ' +
                    'ref="app.warehouse.eshop" ' +
                    'header="eShop.product.breadcrumb.HEADER"></base-table>',
            controller: ['breadcrumb', function (breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'eShop.breadcrumb.HEADING'
                }]
            }]
        })
        .state('app.warehouse.eshop-attributes', {
            url: '/eshop/attributes',
            template: '<opti-eshop-attributes v-on-card-was-changed="cardWasChanged"></opti-eshop-attributes>',
            controller: ['$scope', 'breadcrumb', function ($scope, breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'eShop.breadcrumb.HEADING'
                }, {
                    name: 'eShop.product.breadcrumb.PRODATTRIBUTES'
                }]

                $scope.cardWasChanged = card_translate => {
                    breadcrumb.data[2].name = card_translate
                }
            }]
        })

        .state('app.warehouse.eshop-product', {
            url: '/eshop-product/:id',
            template: '<opti-eshop-product-detail></opti-eshop-product-detail>',
            controller: ['breadcrumb', function (breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'eShop.breadcrumb.HEADING'
                }]
            }]
        })
}])