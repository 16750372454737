angular.module('eOpti.app.tasks').controller('opticTaskController', [
    '$scope',
    '$http',
    '$state',
    '$timeout',
    '$document',
    'breadcrumb',
    'formValidate',
    function ($scope, $http, $state, $timeout, $document, breadcrumb, formValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'tasks.breadcrumb.TASKS'
        }, {
            name: 'tasks.breadcrumb.OPTIC'
        }];

        $scope.formValidate = formValidate;

        $scope.optic = {
            task: '',
            extra: [],
            pin: '',
            note: ''
        };

        var focus = function() {
            $timeout(function () {
                $('#task').focus();
            });
        };

        $scope.addOptic = function() {
            $http.post('api/task/optic/grindery', $scope.optic).then(function(res) {
                if(Object.keys(res.data).indexOf('errors') > -1) {
                    res.data.errors.forEach(function(error) {
                        $scope.$emit('error', error);
                    });
                } else {
                    $scope.$emit('notify', {
                        status: 'success',
                        timeout: 10000,
                        translate: false,
                        message: res.data
                    });

                    $scope.optic = {
                        task: '',
                        extra: [],
                        pin: '',
                        note: ''
                    };

                    focus();
                }
            }, function() {
                $scope.$emit('error', 'tasks.optic.notify.error.SAVE');
            });
        };

        focus();

        $document.on('keydown', function(e) {
            if(e.which === 13) {
                for(var i = 0; i < 4; ++i) {
                    if($('#task' + i).is(':focus')) {
                        if(i === 3) {
                            focus();
                        } else if($('#task' + i).is(':focus')) {
                            $('#task' + (i + 1)).focus();

                            break;
                        }
                    }
                }
            }
        });

        $scope.fieldRequired = function() {
            var _return = true;

            $scope.optic.extra.forEach(function(id) {
                if(id) {
                    _return = false;
                }
            });

            return _return;
        };
    }
]);
