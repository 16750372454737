angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.pictograms', {
            url: '/pictograms',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.pictograms.coatings-edit', {
            url: '/coatings/edit',
            template: '<opti-dictionary-pictograms-coatings-edit></opti-dictionary-pictograms-coatings-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'sidebar.settings.COATINGS'
                }]
            }]
        })

    $stateProvider
        .state('app.dictionary.pictograms.icons', {
            url: '/icons',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.pictograms.icons.list', {
            url: '/{page}/{search}',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/pictograms/icons/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'pictogramsIconsList',
                    args: []
                }]
            }]
        })

    $stateProvider
        .state('app.dictionary.pictograms.icon', {
            url: '/icon',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.pictograms.icon.add', {
            url: '/add',
            template: '<opti-dictionary-pictograms-icon-add></opti-dictionary-pictograms-icon-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'pictogramsIconsList',
                    args: ['state']
                }, {
                    name: 'dictionary.pictogram.breadcrumb.icons.ADD'
                }]
            }]
        })
        .state('app.dictionary.pictograms.icon.edit', {
            url: '/edit/:id',
            template: '<opti-dictionary-pictograms-icon-edit></opti-dictionary-pictograms-icon-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'pictogramsIconsList',
                    args: ['state']
                }, {
                    name: 'dictionary.pictogram.breadcrumb.icons.PICTOGRAM',
                    id: $state.params.id
                }, {
                    name: 'dictionary.pictogram.breadcrumb.icons.EDIT'
                }]
            }]
        })
}])