angular.module('eOpti.app.coupons').controller('coupon.add', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'error', 'coupons.service',
    function ($scope, $http, $state, breadcrumb, formValidate, errorValidate, coupons) {
        breadcrumb.data = [{
            functions: 'couponsList',
            args: ['state']
        }, {
            name: 'coupons.action.ADD'
        }]

        $scope.formValidate = formValidate

        $scope.loading = 'hide'

        $scope.coupon = {
            expiration_date: '2100-01-01'
        }

        $scope.updateDepartment = department => {
            $scope.coupon.salon_id = department.id
        }

        $scope.addCoupon = () => {
            if(moment($scope.coupon.expiration_date).startOf('d').isBefore(moment().startOf('d'))) {
                $scope.$emit('error', 'coupons.validate.EXPBEFOREDATE')
            } else {
                $scope.loading = 'loading'

                coupons.resource.save($scope.coupon, () => {
                    $scope.$emit('success', 'coupons.notify.SAVE')

                    $state.go('app.coupons.table')

                    $scope.loading = 'hide'
                }, error => {
                    errorValidate.request(error, 'coupons.notify.error.SAVE')

                    $scope.loading = 'hide'
                })
            }
        }
    }])
