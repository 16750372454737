angular.module('eOpti.app.schedule').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.schedule', {
            abstract: true,
            url: '/schedule',
            template: '<ui-view/>'
        })
        .state('app.schedule.calendar', {
            url: '/calendar/:department_id/:view/:date/:budget',
            params: {
                department_id: '0',
                view: 'day',
                date: moment().format('YYYY-MM-DD'),
                budget: '0'
            },
            templateUrl: 'app/views/schedule/calendar.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'schedule.breadcrumb.SCHEDULE'
                }]
            }]
        })
}])