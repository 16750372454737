angular.module('eOpti.app.warehouse.smile').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.warehouse.smile', {
            url: '/smile',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.smile.table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/warehouse/smileTable.html',
            controller: ['$scope', '$http', 'breadcrumb', function($scope, $http, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'smile.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'smileList',
                    args: [null]
                }]
            }]
        })
        .state('app.warehouse.smile.details', {
            url: '/details/{id:[0-9]+}',
            template: '<opti-smile-more-details v-on-update-breadcrumb="updateBreadcrumb"></opti-smile-more-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'shipments.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'smileList',
                    args: ['state']
                }, {
                    name: 'smile.breadcrumb.SMILE',
                    id: $state.params.id
                }]

                $scope.updateBreadcrumb = type => {
                    breadcrumb.data[3].name = 'smile.breadcrumb.type.' + type.toUpperCase()
                }
            }]
        })
}])
