import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'

angular.module('eOpti.app.bookkeeping').controller('bookkeepingExpensesTableController', [
    '$scope', '$http', '$stateParams', '$localStorage', 'breadcrumb', 'uac', 'dialogs',
    function ($scope, $http, $stateParams, $localStorage, breadcrumb, uac, dialogs) {
        breadcrumb.data = [{
            name: 'bookkeeping.breadcrumb.REGISTER'
        }, {
            name: 'bookkeeping.breadcrumb.BOOKKEEPING'
        }, {
            functions: 'bookkeepingExpensesList',
            args: [null]
        }]

        if(!('bookkeeping_expenses_checkbox' in $localStorage)) {
            $localStorage['bookkeeping_expenses_checkbox'] = []
        }

        $scope.countAverage = () => {
            $http.get('api/bookkeeping/expenses/countAverage').then(() => {
                $scope.$emit('success', 'bookkeeping.expenses.notify.COUNTED')
            })
        }

        $scope.countForecast = () => {
            dialogs.confirm(null, {
                template: 'app/views/bookkeeping/expenses/forecast.html',
                className: 'ngdialog-theme-plain',
                controller: ['$scope', function($innerScope) {
                    $innerScope.loading = 'hide'

                    $innerScope.select = {
                        quarters: _.range(1, 4 + 1),
                        years: _.range(moment().isoWeekYear() - 3, moment().isoWeekYear() + 1 + 1)
                    }

                    $innerScope.quarter = BudgetHelper.getQuarterByWeek(moment())
                    $innerScope.year = moment().isoWeekYear()

                    $innerScope.submitForecast = () => {
                        $innerScope.loading = 'loading'

                        $http.post('api/reports/expenses-forecast', {
                            quarter: $innerScope.quarter,
                            year: $innerScope.year
                        }).then(() => {
                            $innerScope.loading = 'hide'

                            $scope.$emit('success', 'bookkeeping.expenses.forecast.notify.CONVERT')

                            $innerScope.closeThisDialog()
                        }, () => {
                            $innerScope.loading = 'hide'

                            $scope.$emit('error', 'bookkeeping.expenses.forecast.notify.error.CONVERT')
                        })
                    }
                }]
            })
        }

        $scope.user = uac.user

        $scope.saveChecked = () => {
            $scope.post = [];

            $localStorage['bookkeeping_expenses_checkbox'].forEach(row => {
                if(row.before_identified != row.identified) {
                    $scope.post.push({
                        id: row.id,
                        identified: row.identified
                    })
                }
            })

            $http.post('api/bookkeeping/expenses/save-checked', {
                data: $scope.post
            }).then(() => {
                $localStorage['bookkeeping_expenses_checkbox'] = []

                $scope.$broadcast('table-refresh')

                $scope.$emit('success', 'bookkeeping.expenses.notify.CHECKED')
            }, () => {
                $scope.$emit('error', 'bookkeeping.expenses.notify.error.CHECKED')
            })
        }

        let findKeyRow = row => {
            let _return = false

            $localStorage['bookkeeping_expenses_checkbox'].forEach((r, k) => {
                if(row.id === r.id) {
                    _return = k;
                }
            })

            return _return
        }

        $scope.renderers = {
            identified(value, row, column) {
                let key = findKeyRow(row)

                if(key === false) {
                    $localStorage['bookkeeping_expenses_checkbox'].push(row)

                    row.identified = !!row.identified
                    row.before_identified = row.identified
                } else {
                    let old = $localStorage['bookkeeping_expenses_checkbox'][key].identified,
                        before_old = $localStorage['bookkeeping_expenses_checkbox'][key].before_identified

                    $localStorage['bookkeeping_expenses_checkbox'].splice(key, 1)
                    $localStorage['bookkeeping_expenses_checkbox'].push(row)

                    row.identified = old
                    row.before_identified = before_old
                }

                if(row.source === 'accounting' && row.identified) {
                    return '<i class="fa fa-check"></i>'
                }

                return '<checkbox ng-model="row.identified" name="identified{{row.id}}"></checkbox>'
            }
        }

    }])
