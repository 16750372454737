angular.module('eOpti.app.tests').controller('testsResponsibilitiesListController', [
    '$scope', '$http', 'breadcrumb', 'formValidate', 'tests.service', 'error',
    function($scope, $http, breadcrumb, formValidate, tests, errorValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            name: 'test.breadcrumb.RESPONSIBILITIES'
        }]

        $scope.formValidate = formValidate;

        $scope.form = {
            name: '',
            role_ids: []
        };

        $scope.roles = [];

        $http.get('api/roles').then(function(res) {
            $scope.roles = res.data;

            $scope.formResponsibility.$setValidity('roles', false);
        }, function(error) {
            $scope.$emit('error', 'role.notify.error.LOADPERMISSION');
        });

        $scope.setRole = function() {
            $scope.form.role_ids = [];

            angular.forEach($scope.roles, function(role) {
                if(role.checked) {
                    $scope.form.role_ids.push(role.id);
                }
            });

            $scope.formResponsibility.$setValidity('roles', $scope.form.role_ids.length > 0 ? true : false);
        };

        var reset = function() {
            $scope.form.name = '';
            $scope.form.role_ids = [];

            angular.forEach($scope.roles, function(role) {
                role.checked = false;
            });

            $scope.formResponsibility.$setValidity('roles', $scope.form.role_ids.length > 0 ? true : false);
        };

        $scope.addResponsibility = function () {
            tests.responsibilities.resource.save($scope.form, function () {
                $scope.$emit('success', 'test.responsibility.notify.SAVE');

                reset();

                $scope.$broadcast('table-refresh');
            }, function (error) {
                errorValidate.request(error, 'test.responsibility.notify.error.SAVE');
            });
        };
    }
]);