angular.module('eOpti.app.layout').controller('layoutBlocksController', [
    '$scope',
    'breadcrumb',
    function ($scope, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: []
        }, {
            functions: 'layoutList',
            args: ['all']
        }]

        $scope.renderers = {
            grid: function (value, row, column) {
                if (!value) {
                    return ''
                }

                return `<layout-map department-id="${value}" />`
            }
        }
    }
])
