angular.module('eOpti.app.warehouse').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.warehouse.supply', {
            url: '/supply',
            abstract: true,
            template: '<ui-view>'
        })
        .state('app.warehouse.supply.order-by-admin-assign', {
            url: '/order-by-admin-assign',
            template: '<opti-supply-order-by-admin-assign></opti-supply-order-by-admin-assign>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'orderByAdminList',
                    args: 'state'
                }, {
                    name: 'supply.breadcrumb.ASSIGN'
                }]
            }]
        })
        .state('app.warehouse.supply.list', {
            url: '/list',
            template: '<opti-supply-list></opti-supply-list>',
            controller: ['breadcrumb', 'uac', function(breadcrumb, uac) {
                breadcrumb.data = uac.user.activeDepartmentId === 49 ? [{
                    functions: 'supplyList',
                    args: null
                }] : [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'orderByAdminList',
                    args: null
                }]
            }]
        })
        .state('app.warehouse.supply.expected', {
            url: '/expected/:supplier_id',
            template: '<opti-supply-expected></opti-supply-expected>',
            controller: ['breadcrumb', 'uac', function(breadcrumb, uac) {
                breadcrumb.data = uac.user.activeDepartmentId === 49 ? [{
                    last: 2
                }, {
                    functions: 'supplyList',
                    args: 'state'
                }, {
                    name: 'supply.breadcrumb.EXPECTED'
                }] : [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'orderByAdminList',
                    args: 'state'
                }, {
                    name: 'supply.breadcrumb.EXPECTED'
                }]
            }]
        })
        .state('app.warehouse.supply.received', {
            templateUrl: 'app/views/supply/received.html',
            url: '/received/:supplier_id/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'supplyId',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            controller: ['$scope', '$state', 'breadcrumb', 'uac', function($scope, $state, breadcrumb, uac) {
                breadcrumb.data = uac.user.activeDepartmentId === 49 ? [{
                    last: 2
                }, {
                    functions: 'supplyList',
                    args: 'state'
                }, {
                    name: 'supply.breadcrumb.RECEIVED'
                }] : [{
                    name: 'orders.breadcrumb.WAREHOUSE'
                }, {
                    functions: 'orderByAdminList',
                    args: 'state'
                }, {
                    name: 'supply.breadcrumb.RECEIVED'
                }]

                $scope.supplier_id = $state.params.supplier_id
            }]
        })
}])