angular.module('eOpti.app.coupons').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.coupons', {
                    url: '/coupons',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.coupons.table', {
                    url: '/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'expiration_date',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/coupons/table.html',
                    controller: 'coupons.table'
                })
                .state('app.coupon', {
                    url: '/coupon',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.coupon.details', {
                    url: '/{id:[0-9]+}',
                    template: '<opti-coupons-details></opti-coupons-details>',
                    controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                        breadcrumb.data = [{
                            functions: 'couponsList',
                            args: ['state']
                        }, {
                            functions: 'coupon',
                            args: [$state.params.id, null]
                        }]
                    }]
                })
                .state('app.coupon.add', {
                    url: '/add',
                    templateUrl: 'app/views/coupons/add.html',
                    controller: 'coupon.add'
                })
                .state('app.coupon.edit', {
                    url: '/{id:[0-9]+}/edit',
                    templateUrl: 'app/views/coupons/edit.html',
                    controller: 'coupon.edit'
                });
        }
    ]
);
