angular.module('eOpti.app.patients').controller('patientsListPrescriptionsController', ['$scope', 'breadcrumb', function($scope, breadcrumb) {
    breadcrumb.data = [{
        defaults: 'patientsList'
    }, {
        name: 'prescription.breadcrumb.PRESCRIPTIONS'
    }]

    $scope.renderers = {
        id(value, row) {
            return `<a class="link" ui-sref="app.patient.detail({id: ${row.patientId}, '#': 'prescriptions', 
                prescriptionId: ${value}})">${value}</a>`
        },
        check(value, row) {
            let html = ''

            if(value === 1) {
                html = `<a class="link" ui-sref="app.patient.detail({id: ${row.patientId}, '#': 'prescriptions', 
                    prescriptionId: ${row.id}, historySurvey: true})">
                    <opti-enum v-props-enum="'PrescriptionCheckStatus'" v-props-id="${value}"></opti-enum></a>`
            } else {
                html = `<opti-enum v-props-enum="'PrescriptionCheckStatus'" v-props-id="${value}"></opti-enum>`
            }

            return html
        },
        images(value) {
            if(!value) {
                return ''
            }

            let html = ''

            value.split(',').forEach(i => {
                html += `<a href="/upload/prescription/${i}" target="_blank">
                    <img class="img-responsive" width="200px" src="/thumb/small/prescription/${i}"></a>`
            })

            return html
        }
    }
}])