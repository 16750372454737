angular.module('eOpti.app.sales').controller('saleOffstockAddController', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'dialogs', 'error',
    function ($scope, $http, $state, breadcrumb, formValidate, dialogs, errorValidate) {
        breadcrumb.data = [{
            defaults: 'salesOffstockList'
        }, {
            name: 'sale.offstock.breadcrumb.NEWSALEOFFSTOCK'
        }]

        $scope.formValidate = formValidate

        $scope.saleoffstock = {
            price: 0,
            quantity: 1,
            vat: 3,
            percentDiscount: 0,
            discount: 0,
            valueAfterDiscount: 0,
            receipt: {
                cash_register_id: 0,
                receipt: ''
            }
        }

        $scope.patient = {result: {}}
        $scope.discountCard = 0
        $scope.payment = []

        $scope.mode = 1 // 0 - percent, 1 - amount

        $scope.loadingPatient = 'hide'

        $scope.updatePatient = function(patient) {
            $scope.patient.result = patient
        }

        $scope.goPatientNoExists = function () {
            if ($scope.saleoffstock.patientExists == 0) {
                $scope.patient.result = {};
                $scope.discountCard = 0;
            }
        };

        var getDiscountCard = function (typeId) {
            switch (typeId) {
                case 1:
                    return 20;
                    break;
                case 2:
                    return 10;
                    break;
                default:
                    return 0;
            }
        };

        $scope.changeMode = function (value) {
            $scope.mode = value;
        };

        $scope.$watch('patient.result', function(patient) {
            if (Object.keys(patient).length) {
                $scope.discountCard = getDiscountCard(patient.loyality.typeId);

                if($scope.discountCard > 0) {
                    $scope.changeMode(0);
                    $scope.saleoffstock.percentDiscount = $scope.discountCard;
                } else {
                    $scope.changeMode(1);
                    $scope.saleoffstock.discount = 0;
                }
            } else {
                $scope.patient.result = {};
                $scope.discountCard = 0;
            }
        }, true);

        $scope.$watch('saleoffstock', function () {
            switch ($scope.mode) {
                case 0: // percent
                    $scope.saleoffstock.discount =
                        Math.ceil($scope.saleoffstock.price * $scope.saleoffstock.quantity *
                            $scope.saleoffstock.percentDiscount) / 100;

                    break;

                case 1:
                    if($scope.saleoffstock.price * $scope.saleoffstock.quantity != 0) {
                        $scope.saleoffstock.percentDiscount =
                            Math.ceil($scope.saleoffstock.discount /
                                ($scope.saleoffstock.price * $scope.saleoffstock.quantity) * 10000) / 100;
                    }
            }

            $scope.saleoffstock.valueAfterDiscount = $scope.saleoffstock.price * $scope.saleoffstock.quantity
                - $scope.saleoffstock.discount;
        }, true);

        $scope.loading = 'hide';

        var addSaleoffstock = function () {
            $scope.loading = 'loading';

            $scope.saleoffstock.patientId = Object.keys($scope.patient.result).length ? $scope.patient.result.id : null;

            $http.post('api/sale/offstock/add', {
                saleoffstock: $scope.saleoffstock,
                payment: $scope.payment
            }).then(function (res) {
                if (res.data.status === 'OK') {
                    $state.go('app.sales-offstock');

                    $scope.$emit('success', 'sale.offstock.notify.SAVE')
                } else {
                    $scope.$emit('error', 'sale.offstock.notify.error.SAVE');
                }

                $scope.loading = 'hide';
            }, function (error) {
                errorValidate.request(error, 'sale.offstock.notify.error.SAVE');

                $scope.loading = 'hide';
            });
        };

        $scope.checkCardCoupon = function() {
            if(Object.keys($scope.payment[0].type).indexOf('id') > -1 && $scope.payment[0].type.id == 2 &&
                Object.keys($scope.payment[0].ccType).indexOf('id') > -1 && $scope.payment[0].ccType.id == 8) {
                return $scope.payment[0].amount >= $scope.saleoffstock.valueAfterDiscount ? true : false;
            }

            return true;
        };

        $scope.confirmBeforeAdd = function () {
            dialogs.confirm('sale.offstock.add.confirm.MESSAGE' + (Object.keys($scope.patient.result).length ? 'WITHPATIENT' : ''), {
                className: 'ngdialog-theme-plain',
                template: 'app/views/_dialogs/confirm-with-name.html',
                data: {
                    title: 'Sprzedaż spoza magazynu',
                    yes: 'sale.offstock.add.confirm.YES',
                    no: 'sale.offstock.add.confirm.NO',
                    name: Object.keys($scope.patient.result).length ? ($scope.patient.result.firstName + ' ' + $scope.patient.result.lastName + ', ' +
                        $scope.patient.result.zipCode + ' ' + $scope.patient.result.city) : '',
                    confirmPanel: 'panel-purple',
                    confirmIcon: 'fa fa-fw fa-check',
                    confirmIconColor: 'btn-success'
                }
            }).then(function () {
                addSaleoffstock();
            });
        };

        $scope.payment_invalid = true
        $scope.updatePaymentInvalid = invalid => {
            $scope.payment_invalid = invalid
        }

        $scope.receipt_invalid = true
        $scope.updateReceiptInvalid = invalid => {
            $scope.receipt_invalid = invalid
        }
    }
]);
