angular.module('eOpti.app.suppliers').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.suppliers', {
            url: '/suppliers',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.suppliers.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/suppliers/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'suppliers.breadcrumb.SUPPLIERS'
                }]
            }]
        })
        .state('app.suppliers.add', {
            url: '/add',
            template: '<opti-suppliers-add></opti-suppliers-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    defaults: 'suppliersList'
                }, {
                    name: 'suppliers.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.suppliers.edit', {
            url: '/supplier/:id/edit',
            template: '<opti-suppliers-edit></opti-suppliers-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    defaults: 'suppliersList'
                }, {
                    name: 'suppliers.breadcrumb.SUPPLIER',
                    id: $state.params.id
                }, {
                    name: 'suppliers.breadcrumb.EDIT'
                }]
            }]
        })
}])
