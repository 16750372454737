angular.module('eOpti.app.warehouse.shipments').controller('warehouse.shipments.detail-level', [
    '$scope',
    '$attrs',
    '$parse',
    '$http',
    function ($scope, $attrs, $parse, $http) {
        $scope.details  = [];
        $scope.fetching = true;

        if ($attrs.id) {
            var id = $parse($attrs.id);
            $http
                .get('api/warehouse/shipment/' + id($scope) + '/details')
                .then(function (res) {
                    $scope.details  = res.data;
                    $scope.fetching = false;
                })
        }
    }
]);
