import Vue from "vue";
import Component from "vue-class-component";
import OptiSettingsOfferTranslations
    from "@/js/app/vue/components/Settings/Offer/Translations/OptiSettingsOfferTranslations.vue";

angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.settings-offer', {
            abstract: true,
            url: '/settings/offer',
            template: '<ui-view/>'
        })
        .state('app.settings-offer.report-difference', {
            url: '/report-difference',
            template: '<opti-settings-offer-difference></opti-settings-offer-difference>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.SETTINGS'
                }, {
                    name: 'sidebar.settings.OFFERS'
                }, {
                    name: 'sidebar.settings.offers.DIFFERENCE'
                }]
            }]
        })
        .state('app.settings-offer.translations', {
            url: '/translations',
            params: {
                '#': 'gender'
            },
            template: '<opti-settings-offer-translations v-props-init-hash="initHash" ' +
                'v-on-breadcrumb-change-last="breadcrumbChangeLast"></opti-settings-offer-translations>',
            controller: ['$scope', '$location', 'breadcrumb', function($scope, $location, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.SETTINGS'
                }, {
                    name: 'sidebar.settings.OFFERS'
                }, {
                    name: 'sidebar.settings.offers.TRANSLATIONS'
                }, {
                    name: 'settings.offer.translations.card.GENDER'
                }]

                $scope.initHash = $location.hash()

                $scope.breadcrumbChangeLast = (hash, tab_name) => {
                    $location.hash(hash)

                    breadcrumb.changeLast({name: tab_name})
                }
            }]
        })
        .state('app.settings-offer.questions-edit', {
            url: '/questions-edit',
            template: '<opti-settings-offer-questions-edit></opti-settings-offer-questions-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.SETTINGS'
                }, {
                    name: 'sidebar.settings.OFFERS'
                }, {
                    name: 'sidebar.settings.offers.QUESTIONS_EDIT'
                }]
            }]
        })
}])
