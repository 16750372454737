angular.module('eOpti.app.coupons').controller('coupon.edit', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'error', 'coupons.service',
    function ($scope, $http, $state, breadcrumb, formValidate, errorValidate, coupons) {
        breadcrumb.data = [{
            functions: 'couponsList',
            args: ['state']
        }, {
            functions: 'coupon',
            args: [$state.params.id, 'state']
        }, {
            name: 'coupons.action.EDIT'
        }]

        $scope.formValidate = formValidate

        $scope.coupon = {}

        $scope.updateDepartment = department => {
            $scope.coupon.salon_id = department.id
        }

        coupons.resource.get({couponId: $state.params.id}, res => {
            $scope.coupon = res
            $scope.coupon.old_id = $scope.coupon.id

            $scope.loading = $scope.coupon ? 'hide' : 'empty'
        }, () => {
            $scope.loading = 'error'
        })

        $scope.editCoupon = function () {
            if(moment($scope.coupon.expiration_date).startOf('d').isBefore(moment().startOf('d'))) {
                $scope.$emit('error', 'coupons.validate.EXPBEFOREDATE')
            } else {
                $scope.loading = 'loading'

                coupons.resource.update($scope.coupon, () => {
                    $scope.$emit('success', 'coupons.notify.EDIT')

                    $state.go('app.coupons.table')

                    $scope.loading = 'hide'
                }, error => {
                    errorValidate.request(error, 'coupons.notify.error.EDIT')

                    $scope.loading = 'hide'
                })
            }
        }
    }])
