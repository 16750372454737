angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.taskextras', {
            url: '/task-extras',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.taskextras.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/dictionary/taskextra/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    name: 'dictionary.taskextra.breadcrumb.EXTRA'
                }]
            }]
        })

    $stateProvider
        .state('app.dictionary.taskextra', {
            url: '/task-extra',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.taskextra.add', {
            url: '/add',
            template: '<opti-dictionary-task-extra-add></opti-dictionary-task-extra-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'taskExtraList'
                }, {
                    name: 'dictionary.taskextra.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.dictionary.taskextra.edit', {
            url: '/:id/edit',
            template: '<opti-dictionary-task-extra-edit></opti-dictionary-task-extra-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'taskExtraList'
                }, {
                    name: 'dictionary.taskextra.breadcrumb.EDIT'
                }]
            }]
        })
        .state('app.dictionary.taskextra.delete', {
            url: '/:id/delete',
            template: '<opti-dictionary-task-extra-delete></opti-dictionary-task-extra-delete>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'taskExtraList'
                }, {
                    functions: 'taskextraList',
                    args: [$state.params.id]
                }, {
                    name: 'dictionary.taskextra.breadcrumb.DELETE'
                }]
            }]
        })
}])
