angular.module('eOpti.app.alerts').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.alerts', {
            url: '/alerts/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/alerts/table.html',
            controller: 'alertsListController'
        })

        .state('app.alert', {
            url: '/alert',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.alert.redirect', {
            url: '/redirect/:type/:id/:extra_param/:department_id',
            template: '<opti-alert-redirect></opti-alert-redirect>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'alert.breadcrumb.ALERTS'
                }, {
                    name: 'alert.breadcrumb.REDIRECT'
                }]
            }]
        })
}])
