angular.module('eOpti.app.alerts').controller('alertsListController', [
    '$scope',
    '$rootScope',
    '$http',
    '$state',
    '$localStorage',
    'breadcrumb',
    'uac',
    function($scope, $rootScope, $http, $state, $localStorage, breadcrumb, uac) {
        breadcrumb.data = [{
            name: 'alert.breadcrumb.ALERTS'
        }]

        $scope.renderers = {
            description: (value, row) => {
                let type = row.type,
                    id = row.ref_id,
                    extra_param = row.extra_param,
                    department_id = row.salon,
                    add = '',
                    disabled = !uac.permission('settings.sms.rating')

                if(type === 13 /* sms reply */) {
                    if(extra_param) {
                        add = `
                                <opti-patients-schedule-alert
                                v-props-sms-id="${id}"    
                                ></opti-patients-schedule-alert>`
                    } else {
                        add = `
                            <opti-button-toggle 
                            v-props-enum="'SmsRatingFiltered'" 
                            v-props-endpoint="'sms/rating/${id}'" 
                            v-props-model="devNull${id}" 
                            v-props-disabled="${disabled}"
                            style="display: block"></opti-button-toggle><script></script>`
                    }
                }

                return `
                <a class="link" ui-sref="app.alert.redirect({ type: ${type}, id: ${id}, extra_param: '${extra_param}', department_id: ${department_id} })">
                    <span>${value}</span>
                </a>
                ${add}`
            }
        }

        $scope.checkAsRead = () => {
            $http.post('api/alert/check/as/read', {
                alert_id: $localStorage['alert_read_checkbox']
            }).then(res => {
                if(res.data.status === 'OK') {
                    $scope.$emit('success', 'alert.read.notify.SAVE')

                    $localStorage['alert_read_checkbox'] = []

                    $scope.$broadcast('table-refresh')
                } else {
                    $scope.$emit('error', 'alert.read.notify.error.SAVE')
                }
            }, () => {
                $scope.$emit('error', 'alert.read.notify.error.SAVE')
            })
        }

        window.odznacz = () => {
            $http.get('api/alert/check-all').then(() => {
                console.log('przeszło!')

                $scope.$broadcast('table-refresh')
            }, () => {
                console.error('coś poszło nie tak!')
            })

            return 'przetwarza...'
        }
    }
])