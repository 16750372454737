angular.module('eOpti.app.catalog').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.catalog', {
            url: '/catalog',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.catalog.category', {
            url: '/category/{id:[0-9-]+}?page&special&productId&departmentId&isOffer&taskId',
            params: {
                page: '1',
                special: '',
                productId: '',
                departmentId: '',
                isOffer: '',
                taskId: ''
            },
            reloadOnSearch: false,
            template: '<opti-catalog-category v-on-update-breadcrumb="updateBreadcrumb"></opti-catalog-category>',
            controller: ['$scope', '$timeout', 'breadcrumb', function($scope, $timeout, breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-", special: 0})'
                }]

                $scope.updateBreadcrumb = (breads, product_id, department_id, category_name) => {
                    let update = false

                    breadcrumb.data[0].type = category_name === 'Catalog' ? 'state' : null
                    breadcrumb.data[0].state = 'app.catalog.category({id: "-", special: 0, page: 1, departmentId: ' + department_id + '})'

                    $timeout(() => {
                        breadcrumb.data[0].type = category_name === 'Catalog' ? null : 'state'
                    }, 0)

                    breads.forEach((bread, key) => {
                        if(breadcrumb.data[key + 1]) {
                            breadcrumb.data[key + 1].type = null
                            breadcrumb.data[key + 1].state = 'app.catalog.category({id: ' + bread.id + ', special: 0, page: 1' +
                                ', productId: ' + product_id + ', departmentId: ' + department_id + '})'

                            $timeout(() => {
                                breadcrumb.data[key + 1].type = 'state'
                            }, 0)

                            update = true
                        } else {
                            breadcrumb.data.push({
                                name: bread.name,
                                type: 'state',
                                state: 'app.catalog.category({id: ' + bread.id + ', page: 1, special: 0' +
                                    ', productId: ' + bread.product_id + ', departmentId: ' + department_id + '})'
                            })
                        }
                    })

                    if(!update && category_name !== 'Catalog') {
                        breadcrumb.data.push({
                            name: category_name
                        })
                    }
                }
            }]
        })
        .state('app.catalog.hoya', {
            url: '/hoya',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<opti-catalog-hoya v-on-update-breadcrumb="updateBreadcrumb"></opti-catalog-hoya>',
            controller:['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-"})'
                }, {
                    functions: 'hoyaPrices',
                    args: [null]
                }, {
                    name: 'catalog.hoya.heading.REPORT'
                }]

                $scope.updateBreadcrumb = active_tab => {
                    breadcrumb.data[2].name = 'catalog.hoya.heading.' + active_tab.toUpperCase()
                }
            }]
        })
        .state('app.catalog.hoya.daily', {
            url: '/:page/:search',
            parent: 'app.catalog.hoya'
        })
        .state('app.catalog.dailycheck', {
            url: '/dailyReport/check/:id',
            template: `<hoya-daily-report-details></hoya-daily-report-details>`,
            controller:['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-"})'
                }, {
                    functions: 'hoyaPrices',
                    args: ['state']
                }, {
                    name: 'catalog.hoya.heading.REPORT',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.catalog.prices', {
            template: '<opti-catalog-prices-confirm></opti-catalog-prices-confirm>',
            url: '/hoya-prices-excel',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.DASHBOARD'
                }]
            }]
        })
        .state('app.catalog.product', {
            url: '/product/{id:[0-9]+}?departmentId',
            params: {
                departmentId: ''
            },
            reloadOnSearch: false,
            template: '<opti-product-details v-on-update-breadcrumb="updateBreadcrumb" v-on-update-memory="updateMemory"></opti-product-details>',
            controller: ['$scope', '$state', '$timeout', 'breadcrumb', 'memory', function($scope, $state, $timeout, breadcrumb, memory) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-"})'
                }]

                $scope.updateBreadcrumb = (breads, department_id) => {
                    let update = false

                    breadcrumb.data[0].type = null
                    breadcrumb.data[0].state = 'app.catalog.category({id: "-", departmentId: ' + department_id + '})'

                    $timeout(() => {
                        breadcrumb.data[0].type = 'state'
                    }, 0)

                    breads.forEach((bread, key) => {
                        if(breadcrumb.data[key + 1]) {
                            breadcrumb.data[key + 1].type = null
                            breadcrumb.data[key + 1].state = 'app.catalog.category({id: ' + bread.id + ', page: ' + bread.page +
                                ', productId: ' + bread.product_id + ', departmentId: ' + department_id + '})'

                            $timeout(() => {
                                breadcrumb.data[key + 1].type = 'state'
                            }, 0)

                            update = true
                        } else {
                            breadcrumb.data.push({
                                name: bread.name,
                                type: 'state',
                                state: 'app.catalog.category({id: ' + bread.id + ', page: ' + bread.page +
                                    ', productId: ' + bread.product_id + ', departmentId: ' + department_id + '})'
                            })
                        }
                    })

                    if(!update) {
                        breadcrumb.data.push({
                            functions: 'product',
                            args: [$state.params.id]
                        })
                    }
                }

                $scope.updateMemory = obj => {
                    memory.rememberObject('products', obj)
                }
            }]
        })

        .state('app.catalog.product-edit', {
            url: '/product/{id:[0-9]+}/edit',
            template: '<opti-product-edit v-on-update-breadcrumb="updateBreadcrumb"></opti-product-edit>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-", special: 0})'
                }]

                $scope.updateBreadcrumb = breads => {
                    breads.forEach(bread => {
                        breadcrumb.data.push({
                            name: bread.name,
                            type: 'state',
                            state: 'app.catalog.category({id: ' + bread.id + ', page: ' + bread.page +
                                ', productId: ' + bread.product_id + '})'
                        })
                    })

                    breadcrumb.data.push({
                        functions: 'product',
                        args: [$state.params.id, 'state']
                    }, {
                        name: 'catalog.breadcrumb.EDIT'
                    })
                }
            }]
        })

        .state('app.catalog.category-edit', {
            url: '/edit',
            template: '<opti-catalog-category-edit></opti-catalog-category-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-", special: 0})'
                }, {
                    name: 'catalog.breadcrumb.EDIT'
                }]
            }]
        })

        .state('app.catalog.offer', { // nazwa istotna w mutacjach resetState
            url: '/offer/:page',
            params: {
                page: '1'
            },
            template: '<opti-catalog-offer></opti-catalog-offer>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-"})'
                }, {
                    name: 'catalog.offer.breadcrumb.OFFER'
                }]
            }]
        })

        .state('app.catalog.offer-temporary-task', {
            url: '/offer/temporary-task/:id',
            params: {
                '#': 'summary',
                refresh: false
            },
            controller: 'detailTaskController',
            templateUrl: 'app/views/tasks/detail.html',
            resolve: {
                offer: ['$http', '$stateParams', function($http, $stateParams) {
                    return $http.get('api/get-offer-from-temporary-task-id/' + $stateParams.id)
                }],
                task: ['$http', '$stateParams', function($http, $stateParams) {
                    return $http.get('api/task/' + $stateParams.id + '/offer')
                }]
            }
        })

        .state('app.catalog.offer-redirect', {
            url: '/offer/:id/redirect',
            template: '<opti-catalog-offer-redirect></opti-catalog-offer-redirect>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-"})'
                }, {
                    name: 'catalog.offer.breadcrumb.OFFER'
                }, {
                    name: 'patient.offer.breadcrumb.REDIRECT'
                }]
            }]
        })

        //stock
        .state('app.catalog.stock-corrections', {
            url: '/stock-corrections',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.catalog.stock-corrections.table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/catalog/stock/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    functions: 'catalog',
                    args: ['state']
                }, {
                    functions: 'stockCorrectionList',
                    args: [null]
                }]
            }]
        })
        .state('app.catalog.stock-correction', {
            url: '/stock-correction',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.catalog.stock-correction.edit', {
            url: '/:id/edit',
            template: '<opti-product-stock-change-edit></opti-product-stock-change-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    functions: 'catalog',
                    args: ['state']
                }, {
                    functions: 'stockCorrectionList',
                    args: ['state']
                }, {
                    name: 'catalog.stock.breadcrumb.NO',
                    id: $state.params.id
                }]
            }]
        })

        .state('app.catalog.producent', {
            url: '/producent',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.catalog.producent.overwrite-table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/warehouse/product/producent/overwrite/table" ' +
                'ref="app.catalog.producent.overwrite-table" header="product.breadcrumb.PRODUCENT_OVERWRITE"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    functions: 'catalog',
                    args: ['state']
                }, {
                    name: 'product.breadcrumb.PRODUCENT_OVERWRITE'
                }]
            }]
        })

        .state('app.catalog.graph', {
            url: '/graph',
            template: '<opti-catalog-graph></opti-catalog-graph>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'catalog.breadcrumb.CATALOG',
                    type: 'state',
                    state: 'app.catalog.category({id: "-"})'
                }, {
                    name: 'sidebar.catalog.GRAPH'
                }]
            }]
        })
}])
