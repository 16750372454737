angular.module('eOpti.app.layout').controller('layoutSupplyListController', [
    '$scope',
    '$rootScope',
    '$http',
    '$state',
    '$localStorage',
    'breadcrumb',
    function ($scope, $rootScope, $http, $state, $localStorage, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: ['state']
        }, {
            name: 'layout.breadcrumb.SUPPLY'
        }]

        $scope.renderers = {
            details(value, row) {
                if(!value) {
                    return ''
                }

                return `<a ui-sref="app.layout.grid({ open: ${value}, departmentId: ${row.department_id}})" 
                    title="{{::\'layout.supply.action.DETAILS\'|translate}}"><i class="fa fa-info-circle fa-2x fa-fw"></i></a>`
            }
        }

        $scope.edit_mode = false
        $scope.edit_loading = true

        $scope.$on('select_editable_was_loaded', () => {
            $scope.edit_loading = false
        })

        $scope.selectEditModeAll = bool => {
            $scope.edit_mode = bool

            $scope.$broadcast('editable_mode', 'object_name', $scope.edit_mode)
        }

        $scope.addToReturnCart = () => {
            $http.post('api/carts/supply/add', {
                items: $localStorage['select_checkbox']
            }).then(() => {
                $localStorage['select_checkbox'] = []
                $scope.$broadcast('table-refresh')

                //TODO: gdy będzie przepisane w Vue to rozsunąć koszyk i z res.data pobrać dane do koszyka

                $state.reload()

                window.localStorage.setItem('cart-active', 2)
                $rootScope.offsideOpen = true

                $scope.$emit('success', 'carts.notify.SAVEMULTI')
            }, () => {
                $scope.$emit('error', 'sale.items.notify.error.SENDTOORDER')
            })
        }
    }
])
