angular.module('eOpti.app.tasks').config(['$stateProvider', function($stateProvider) {
    let generateTaskStatus = () => {
        let statuses = ['cancelled', 'notaccepted', 'accepted', 'confirmed', 'uncompleted',
            'completed', 'abraded', 'checked', 'sent', 'received', 'done', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.tasks.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: status === 'sent' ? '50' : '10',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    },
                    resetSearch: false
                },
                template: `<base-table end-point="api/tasks/table/${status}" ref="app.tasks.${status}"
                    header="tasks.TASKS" sub-header="tasks.status.${status.toUpperCase()}"
                    additional-row="additionalRow">` +
                    (status === 'sent' ? `<btn default icon="fa fa-phone" text="sms.dialog.confirm" 
                        confirm="sendReceive()">sms.dialog.SEND</btn>` : '') +
                `</base-table>
                <opti-button-prescription class="hide" ng-if="prescription_id" v-props-auto-open="true" 
                    v-props-id="prescription_id"></opti-button-prescription>`,
                controller: status === 'sent' ? 'sentTaskController' : [
                    '$scope', 'breadcrumb', 'taskListIcons', function($scope, breadcrumb, taskListIcons) {
                        breadcrumb.data = [{
                            last: 2
                        }, {
                            name: 'tasks.breadcrumb.TASKS'
                        }, {
                            functions: 'tasks',
                            args: `tasks.status.${status.toUpperCase()}`
                        }]

                        $scope.additionalRow = taskListIcons.getRenderer()

                        $scope.prescription_id = null

                        $scope.$on('preview', (env, value) => {
                            $scope.prescription_id = null

                            setTimeout(() => {
                                $scope.prescription_id = value

                                $scope.$apply()
                            }, 1)
                        })
                    }]
            })
        })
    }

    generateTaskStatus()

    $stateProvider.state('app.tasks', {
        url: '/tasks',
        abstract: true,
        template: '<ui-view/>'
    })

    $stateProvider.state('app.tasks.wz', {
        url: '/wz',
        abstract: true,
        template: '<ui-view/>'
    })

    $stateProvider.state('app.tasks.wz.list', {
        url: '/list/:page/:search',
        params: {
            page: '1',
            pageSize: '10',
            pageSizeWarehouse: '20',
            sortBy: 'id',
            sortOrder: 'desc',
            search: {
                array: true
            },
            columns: {
                array: true
            }
        },
        template: `<base-table end-point="api/tasks/wz/table" header="tasks.wz.breadcrumb.TASKSWZ"
            ref="app.tasks.wz.list"></base-table>`,
        controller: ['breadcrumb', function(breadcrumb) {
            breadcrumb.data = [{
                last: 2
            }, {
                name: 'tasks.wz.breadcrumb.WAREHOUSE'
            }, {
                name: 'tasks.wz.breadcrumb.TASKSWZ'
            }]
        }]
    })

    $stateProvider.state('app.tasks.wz.details', {
        url: '/{id:[0-9]+}',
        template: '<opti-tasks-wz-details></opti-tasks-wz-details>',
        controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
            breadcrumb.data = [{
                last: 2
            }, {
                name: 'tasks.wz.breadcrumb.WAREHOUSE'
            }, {
                functions: 'taskwz',
                args: ['state']
            }, {
                name: 'tasks.wz.breadcrumb.TASKSWZ',
                id: $state.params.id
            }]
        }]
    })

    $stateProvider.state('app.tasks.printouts', {
        url: '/printouts',
        abstract: true,
        template: '<ui-view/>'
    })

    $stateProvider.state('app.tasks.printouts.list', {
        url: '/list/:page/:search',
        params: {
            page: '1',
            pageSize: '10',
            sortBy: 'id',
            sortOrder: 'desc',
            search: {
                array: true
            },
            columns: {
                array: true
            }
        },
        template: `<base-table end-point="api/task/printouts/table" header="tasks.printouts.breadcrumb.PRINTED"
            ref="app.tasks.printouts.list"></base-table>`,
        controller: ['breadcrumb', function(breadcrumb) {
            breadcrumb.data = [{
                last: 2
            }, {
                name: 'tasks.printouts.breadcrumb.WAREHOUSE'
            }, {
                name: 'tasks.printouts.breadcrumb.PRINTOUTS'
            }]
        }]
    })

    $stateProvider.state('app.tasks.printouts.toPrint', {
        url: '/list/toPrint/:page/:search',
        params: {
            page: '1',
            pageSize: '10',
            sortBy: 'id',
            sortOrder: 'desc',
            search: {
                array: true
            },
            columns: {
                array: true
            }
        },
        template: `<base-table style="position:relative" end-point="api/tasks/table/uncompletedWarehouse"
            header="tasks.printouts.breadcrumb.TOPRINT" ref="app.tasks.printouts.toPrint">
            <btn default icon="fa fa-print" ng-click="taskPrintout()">tasks.printouts.table.PRINT</btn>
            <opti-dimmer v-props-active="loading"></opti-dimmer>
        </base-table>`,
        controller: 'toPrintTaskController'
    })

    $stateProvider.state('app.tasks.printouts.details', {
        url: '/{id:[0-9]+}',
        template: '<opti-tasks-printout-details></opti-tasks-printout-details>',
        controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
            breadcrumb.data = [{
                last: 2
            }, {
                name: 'tasks.printouts.breadcrumb.WAREHOUSE'
            }, {
                functions: 'printouts',
                args: ['state']
            }, {
                name: 'tasks.printouts.breadcrumb.PRINTOUT',
                id: $state.params.id
            }]
        }]
    })

    $stateProvider.state('app.tasks.wzToTasks', {
        url: '/wz-to-tasks',
        abstract: true,
        template: '<ui-view/>'
    })

    $stateProvider.state('app.tasks.wzToTasks.list', {
        url: '/list/:page/:search',
        params: {
            page: '1',
            pageSize: '10',
            sortBy: 'id',
            sortOrder: 'desc',
            search: {
                array: true
            },
            columns: {
                array: true
            }
        },
        template: `<base-table style="position:relative" end-point="api/tasks/table/wzToTasks"
            header="tasks.wzToTasks.breadcrumb.WZTOTASKS" ref="app.tasks.wzToTasks.list">
            <btn default icon="fa fa-copy" ng-click="goWZTasks()">tasks.wzToTasks.BTN</btn>
            <opti-dimmer v-props-active="loading"></opti-dimmer>
        </base-table>`,
        controller: 'wzToTasksTaskController'
    })

    // Single task
    $stateProvider
        .state('app.task', {
            url: '/task',
            abstract: true,
            template: '<ui-view/>'
        })

        .state('app.task.optic', {
            url: '/optic',
            templateUrl: 'app/views/tasks/optic.html',
            controller: 'opticTaskController'
        })

        .state('app.task.control', {
            url: '/control',
            templateUrl: 'app/views/tasks/control.html',
            controller: 'controlTaskController'
        })

        .state('app.task.detail', {
            url: '/detail/:id',
            params: {
                '#': 'summary',
                refresh: false
            },
            controller: 'detailTaskController',
            templateUrl: 'app/views/tasks/detail.html',
            resolve: {
                offer: () => null,
                task: ['$http', '$stateParams', function($http, $stateParams) {
                    return $http.get('api/task/' + $stateParams.id + '/task')
                }]
            }
        })

        .state('app.tasks-grindery', {
            url: '/tasks/grindery',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.tasks-grindery.list', {
            url: '/list/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: `<base-table end-point="api/tasks/grindery/table" 
                header="tasks.grindery.breadcrumb.SCORE" sub-header="tasks.grindery.breadcrumb.LIST"
                ref="app.tasks-grindery.list"></base-table>`,
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'tasks.grindery.breadcrumb.SCORE'
                }]
            }]
        })
        .state('app.task-grindery-edit', {
            url: '/grindery/score/edit/:id',
            templateUrl: 'app/views/tasks/grindery/edit.html',
            controller: 'grinderyEditTaskController'
        })

        .state('app.task-compare', {
            url: '/tasks/compare',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.task-compare.list', {
            url: '/list/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/tasks/compare-ilog.html',
            controller: 'compareIlogTaskController'
        })

        .state('app.tasks-raport', {
            url: '/tasks/raport',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.tasks-raport.list', {
            url: '/list/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/tasks/grindery/table-grindery.html',
            controller: 'raportListTaskController'
        })

        .state('app.tasks.temporary', {
            url: '/temporary/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: `<base-table end-point="/api/tasks/table/temporary" header="tasks.table.TEMPORARY" 
                sub-header="" ref="app.tasks.temporary" additional-row="additionalRow">
                <opti-button-report-rate v-props-type="'temporary_tasks'"></opti-button-report-rate>
            </base-table>
            <opti-catalog-offer-preview class="hide" ng-if="offer_id" v-props-auto-open="true" 
                 v-props-id="offer_id" v-on-reload="reload"></opti-catalog-offer-preview>
            <opti-button-prescription class="hide" ng-if="prescription_id" v-props-auto-open="true" 
                v-props-id="prescription_id"></opti-button-prescription>`,
            controller: ['$scope', 'breadcrumb', 'taskListIcons',
                function($scope, breadcrumb, taskListIcons) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'tasks.breadcrumb.TASKS'
                }, {
                    functions: 'tasks',
                    args: 'tasks.status.TEMPORARY'
                }]

                $scope.offer_id = null
                $scope.prescription_id = null

                $scope.$on('preview', (env, value, columnId) => {
                    $scope[columnId] = null

                    setTimeout(() => {
                        $scope[columnId] = value

                        $scope.$apply()
                    }, 1)
                })

                $scope.reload = () => $scope.$broadcast('table-refresh')

                $scope.additionalRow = taskListIcons.getRenderer()
            }]
        })
}])
