angular.module('eOpti.app.tests').controller('testDoQuestionController', [
    '$scope', '$http', '$state', '$filter', 'breadcrumb', 'ENV',
    function ($scope, $http, $state, $filter, breadcrumb, ENV) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            functions: 'test',
            args: ['nostate', $state.params.id]
        }];

        $scope.result = {};
        $scope.countdown = -1;
        $scope.answer = {
            position: 0
        };
        $scope.disabled = false;

        $scope.uploadHost = ENV.uploadHost

        //ten sam warunek jest w TestsDoController:testStatus
        $scope.allow_begin = false

        var loadData = function(data) {
            $scope.loading = 'loading';

            $scope.result = data;

            if($scope.result.answers.length === 1 && $scope.result.test.questions.length === 1) {
                if($scope.result.answers[0].test_question_id !== $scope.result.test.questions[0].id) {
                    $scope.$emit('error', 'test.do.notify.error.ERRORDATA');
                }
            }

            switch($scope.result.status) {
                case 0:
                    breadcrumb.data[2] = {
                        name: 'test.breadcrumb.START'
                    };
                    break;

                case 1:
                    $scope.countdown = $scope.result.test.questions[0].time -
                        ($scope.result.timestamp - $scope.result.answers[0].created_at_ts) + 1;

                    if($scope.countdown < 0) {
                        $scope.finished()
                    }

                    breadcrumb.data[2] = {
                        functions: 'testQuestion',
                        args: [$scope.result.index_question + 1]
                    };
                    break;

                case 2:
                    breadcrumb.data[2] = {
                        name: 'test.breadcrumb.SUMMARY'
                    };

                    $scope.percent = $scope.result.result * 100 / $scope.result.test.max;
            }

            $scope.loading = $scope.result ? 'hide' : 'empty';
        };

        $http.post('api/tests/do', {
            test_id: $state.params.id
        }).then(function (res) {
            if(Object.keys(res.data).length && res.data.error == 12345) {
                $state.go('app.tests');

                $scope.$emit('error', 'test.notify.error.AGAINDOTEST');
            } else {

                let d = new Date()

                $scope.start_from = $scope.countTime(res.data.test.start_from)
                $scope.start_to = $scope.countTime(res.data.test.start_to)

                $scope.check = $scope.countTime(d.getHours()+':'+d.getMinutes())

                $scope.allow_begin = $scope.start_from < $scope.check && $scope.start_to > $scope.check

                loadData(res.data);
            }
        }, function() {
            $scope.loading = 'error';
        });

        $scope.countTime = function(str) {
            const [h, m] = str.split(":")
            console.log('hours: ' + h + '__minutes' + m)
            return ((parseInt(h)*60) + parseInt(m))
        }

        $scope.changeStatus = function(status) {
            $scope.loading = 'loading';

            var params = {
                result_id: $scope.result.id,
                status: status,
                start_from: $scope.start_from,
                start_to: $scope.start_to,
                now: $scope.check
            };

            if(status === 1) {
                params['next'] = 1;
            }

            $http.post('api/tests/do/status', params).then(function(res) {
                loadData(res.data);
            }, function() {
                $scope.$emit('error', 'test.do.notify.error.CHANGESTATUS');
            });
        };

        $scope.goNext = function(next) {
            $scope.loading = 'loading';

            $http.post('api/tests/do/answer', {
                test_id: $scope.result.test.id,
                answer_id: $scope.result.answers[0].id,
                answer: $scope.answer.position,
                next: next
            }).then(function(res) {
                loadData(res.data);

                $scope.answer = {
                    position: 0
                };

                $scope.disabled = false;
            }, function() {
                $scope.$emit('error', 'test.do.notify.error.NEXTQUESTION');
            });
        };

        $scope.finished = function() {
            $scope.answer.position = 0;

            $scope.disabled = true;
        };
    }
]);
