angular.module('eOpti.app.optometrist').controller('previousGlassesOptometristController', [
    '$scope', '$http', '$state', '$timeout', function($scope, $http, $state, $timeout) {
        let calculateCorrection = () => {
            angular.forEach(['dist', 'near'], distance => {
                angular.forEach(['r', 'l'], side => {
                    let sph = $scope.interview[distance + '_c_' + side + '_sphere'],
                        cyl = $scope.interview[distance + '_c_' + side + '_cylinder'],
                        axs = $scope.interview[distance + '_c_' + side + '_axis']

                    if(!cyl || (cyl && axs !== 'brak')) {
                        if(cyl < 0) {
                            $scope.correction['minus'][distance][side] = {
                                sph: sph,
                                cyl: cyl,
                                axs: axs
                            }

                            sph += cyl
                        } else if(cyl > 0) {
                            $scope.correction['plus'][distance][side] = {
                                sph: sph,
                                cyl: cyl,
                                axs: axs
                            }

                            sph += cyl
                        }

                        cyl *= -1

                        if(cyl) {
                            axs = parseInt(axs)

                            if (axs <= 90) {
                                axs += 90
                            } else {
                                axs -= 90
                            }
                        }

                        if(cyl > 0) {
                            $scope.correction['plus'][distance][side] = {
                                sph: sph,
                                cyl: cyl,
                                axs: axs
                            }
                        } else if(cyl < 0) {
                            $scope.correction['minus'][distance][side] = {
                                sph: sph,
                                cyl: cyl,
                                axs: axs
                            }
                        } else {
                            $scope.correction['plus'][distance][side] = {
                                sph: sph,
                                cyl: cyl,
                                axs: axs
                            }

                            $scope.correction['minus'][distance][side] = {
                                sph: sph,
                                cyl: cyl,
                                axs: axs
                            }
                        }
                    } else {
                        $scope.correction['plus'][distance][side] = {
                            sph: 0,
                            cyl: 0,
                            axs: 'brak'
                        }

                        $scope.correction['minus'][distance][side] = {
                            sph: 0,
                            cyl: 0,
                            axs: 'brak'
                        }
                    }

                    $scope.interview[distance + '_c_' + side + '_sphere'] = $scope.correction['minus'][distance][side].sph
                    $scope.interview[distance + '_c_' + side + '_cylinder'] = $scope.correction['minus'][distance][side].cyl
                    $scope.interview[distance + '_c_' + side + '_axis'] = ($scope.correction['minus'][distance][side].axs).toString()
                })
            })
        }, calculateCorrectionContactLens = () => {
            angular.forEach(['r', 'l'], side => {
                let sph = $scope.interview.contactLensCorrection[side + '_sph'],
                    cyl = $scope.interview.contactLensCorrection[side + '_cyl'],
                    axs = $scope.interview.contactLensCorrection[side + '_axs']

                if(!cyl || (cyl && axs !== 'brak')) {
                    if (cyl < 0) {
                        $scope.correction.contactlens.minus[side] = {
                            sph: sph,
                            cyl: cyl,
                            axs: axs
                        }

                        sph += cyl;
                    } else if(cyl > 0) {
                        $scope.correction.contactlens.plus[side] = {
                            sph: sph,
                            cyl: cyl,
                            axs: axs
                        }

                        sph += cyl
                    }

                    cyl *= -1

                    if(cyl) {
                        axs = parseInt(axs)

                        if(axs <= 90) {
                            axs += 90
                        } else {
                            axs -= 90
                        }
                    }

                    if(cyl > 0) {
                        $scope.correction.contactlens.plus[side] = {
                            sph: sph,
                            cyl: cyl,
                            axs: axs
                        }
                    } else if(cyl < 0) {
                        $scope.correction.contactlens.minus[side] = {
                            sph: sph,
                            cyl: cyl,
                            axs: axs
                        }
                    } else {
                        $scope.correction.contactlens.plus[side] = {
                            sph: sph,
                            cyl: cyl,
                            axs: axs
                        }

                        $scope.correction.contactlens.minus[side] = {
                            sph: sph,
                            cyl: cyl,
                            axs: axs
                        }
                    }
                } else {
                    $scope.correction.contactlens.plus[side] = {
                        sph: 0,
                        cyl: 0,
                        axs: 'brak'
                    }

                    $scope.correction.contactlens.minus[side] = {
                        sph: 0,
                        cyl: 0,
                        axs: 'brak'
                    }
                }

                $scope.interview.contactLensCorrection[side + '_sph'] = $scope.correction.contactlens.minus[side].sph
                $scope.interview.contactLensCorrection[side + '_cyl'] = $scope.correction.contactlens.minus[side].cyl
                $scope.interview.contactLensCorrection[side + '_axs'] = $scope.correction.contactlens.minus[side].axs
            })
        }

        let copyCorrection = (previousLens, distance) => {
            angular.forEach(['r', 'l'], side => {
                $scope.interview[distance + '_c_' + side + '_sphere'] = previousLens[side + '_sph']
                $scope.interview[distance + '_c_' + side + '_cylinder'] = previousLens[side + '_cyl']
                $scope.interview[distance + '_c_' + side + '_axis'] = previousLens[side + '_axs']
                $scope.interview[distance + '_c_' + side + '_prism'] = previousLens[side + '_prd']
                $scope.interview[distance + '_c_' + side + '_base'] = previousLens[side + '_base']

                if(distance === 'near') {
                    $scope.interview[distance + '_c_' + side + '_sphere'] =
                        Math.round((parseFloat(previousLens[side + '_sph']) +
                            (previousLens[side + '_add'] == 0.88 || previousLens[side + '_add'] == 0.53 ? 0 :
                                parseFloat(previousLens[side + '_add']))) * 100) / 100
                } else {
                    $scope.interview[distance + '_c_' + side + '_add'] = previousLens[side + '_add']
                }
            })
        }

        let clearCorrection = distance => {
            angular.forEach(['r', 'l'], side => {
                $scope.interview[distance + '_c_' + side + '_sphere'] = 0
                $scope.interview[distance + '_c_' + side + '_cylinder'] = 0
                $scope.interview[distance + '_c_' + side + '_axis'] = 'brak'
                $scope.interview[distance + '_c_' + side + '_prism'] = 0
                $scope.interview[distance + '_c_' + side + '_base'] = 0

                if(distance === 'dist') {
                    $scope.interview[distance + '_c_' + side + '_add'] = 0
                }
            })
        }

        let clearVisus = dist => {
            $scope.interview[dist + '_c_r_visus'] = '-'
            $scope.interview[dist + '_c_r_cz'] = '0'
            $scope.interview[dist + '_c_l_visus'] = '-'
            $scope.interview[dist + '_c_l_cz'] = '0'
            $scope.interview[dist + '_c_binvisus'] = '-'
        }, clearVisusCorrection = () => {
            $scope.interview.contactLensCorrection.r_visus = '-'
            $scope.interview.contactLensCorrection.r_cz = '0'
            $scope.interview.contactLensCorrection.l_visus = '-'
            $scope.interview.contactLensCorrection.l_cz = '0'
            $scope.interview.contactLensCorrection.binvisus = '-'
        }

        $scope.pendingPreviouslenses = false

        let timeout = null,
            previous_lenses_updated_at = $scope.interview.patient.previous_lenses_updated_at

        let savePreviousLenses = () => {
            if(!timeout) {
                $scope.pendingPreviouslenses = true

                timeout = $timeout(() => {
                    $http.put('api/optometrists/previouslenses/edit', {
                        previouslenses: $scope.previouslenses,
                        contactlenses: $scope.contactlenses,
                        previous_lenses_updated_at: previous_lenses_updated_at,
                        interview_id: $scope.interview.id,
                        previous_glasses_checked: $scope.interview.previous_glasses_checked,
                        previous_contacts_checked: $scope.interview.previous_contacts_checked,
                        glasses: $scope.interview.glasses
                    }).then(res => {
                        previous_lenses_updated_at = res.data.previous_lenses_updated_at
                        res.data.to_delete.forEach(task_id => {
                            angular.forEach($scope.previouslenses, (previousLens, key) => {
                                if(previousLens.task_id === task_id) {
                                    $scope.previouslenses.splice(key, 1)
                                    $scope.interview.previous_glasses_checked.splice(key, 1)
                                    $scope.previouslens.checked_task[previousLens.task_id] = false
                                }
                            })
                        })

                        //force reload …
                        if(Object.keys($scope.previouslenses).length) {
                            $scope.previouslenses.push({measurement: {ipad: {}, measurementJawroVisu: {}}})

                            $timeout(() => {
                                $scope.previouslenses.splice(Object.keys($scope.previouslenses).length - 1, 1)
                            }, 100)
                        }

                        angular.forEach($scope.previouslenses, (previouslens, key) => {
                            if(previouslens.task_id) {
                                $scope.previouslens.checked_task[previouslens.task_id] = $scope.interview.previous_glasses_checked[key]
                            }
                        })

                        $scope.pendingPreviouslenses = false

                        $timeout.cancel(timeout)
                        timeout = null
                    }, error => {
                        if(error.status === 460) {
                            $scope.$emit('notify', {
                                status: 'warning',
                                translate: false,
                                message: error.data.message
                            })

                            $state.reload()
                        } else {
                            $scope.$emit('error', 'previouslens.notify.error.SAVE')
                        }

                        $scope.pendingPreviouslenses = false
                    })
                }, 500)
            }
        }

        let watchPreviousLenses = () => {
            let task_ids = []

            $scope.previouslens.dist = false
            $scope.previouslens.near = false
            $scope.previouslens.dist_cant_read_powers = false

            angular.forEach($scope.previouslenses, (previouslens, key) => {
                if(previouslens.task_id) {
                    task_ids.push(previouslens.task_id)
                }

                if($scope.interview.previous_glasses_checked[key]) {
                    if(previouslens.distance_id == 1) {
                        copyCorrection(previouslens, 'dist')
                        if(!$scope.previouslens.near) {
                            copyCorrection(previouslens, 'near')
                        }

                        $scope.previouslens.dist = true

                        if(previouslens.cant_read_powers) {
                            $scope.previouslens.dist_cant_read_powers = true
                        }
                    } else if(previouslens.distance_id == 2) {
                        copyCorrection(previouslens, 'near')

                        $scope.previouslens.near = true
                    }
                }

                if(!$scope.previouslens.near && !$scope.previouslens.dist) {
                    clearCorrection('dist')
                    clearCorrection('near')
                } else if($scope.previouslens.near && !$scope.previouslens.dist) {
                    clearCorrection('dist')
                }
            })

            angular.forEach($scope.previouslens.checked_task, (bool, task_id) => {
                if(bool && task_ids.indexOf(parseInt(task_id)) === -1) {
                    $scope.previouslens.checked_task[task_id] = false
                }
            })

            calculateCorrection()
        }, watchPreviousLensesContact = () => {
            $scope.previouslens.contactlens = false

            angular.forEach($scope.contactlenses, (contactlens, key) => {
                if($scope.interview.previous_contacts_checked[key]) {
                    $scope.previouslens.contactlens = true

                    $scope.interview.contactLensCorrection.r_name = contactlens.r_product_name
                    $scope.interview.contactLensCorrection.l_name = contactlens.l_product_name
                    $scope.interview.contactLensCorrection.r_sph = contactlens.r_sph
                    $scope.interview.contactLensCorrection.l_sph = contactlens.l_sph
                    $scope.interview.contactLensCorrection.r_cyl = contactlens.r_cyl
                    $scope.interview.contactLensCorrection.l_cyl = contactlens.l_cyl
                    $scope.interview.contactLensCorrection.r_axs = contactlens.r_axs
                    $scope.interview.contactLensCorrection.l_axs = contactlens.l_axs
                    $scope.interview.contactLensCorrection.r_add = contactlens.r_add
                    $scope.interview.contactLensCorrection.l_add = contactlens.l_add
                }
            })

            if(!$scope.previouslens.contactlens) {
                $scope.interview.contactLensCorrection.r_name = ''
                $scope.interview.contactLensCorrection.l_name = ''
                $scope.interview.contactLensCorrection.r_sph = 0
                $scope.interview.contactLensCorrection.l_sph = 0
                $scope.interview.contactLensCorrection.r_cyl = 0
                $scope.interview.contactLensCorrection.l_cyl = 0
                $scope.interview.contactLensCorrection.r_axs = 'brak'
                $scope.interview.contactLensCorrection.l_axs = 'brak'
                $scope.interview.contactLensCorrection.r_add = 0
                $scope.interview.contactLensCorrection.l_add = 0
            }

            calculateCorrectionContactLens()
        };

        watchPreviousLenses()
        let firstTime = true
        $scope.updatePreviousLenses = () => {
            if(!firstTime) {
                watchPreviousLenses()
                savePreviousLenses()
            } else {
                firstTime = false
            }
        }

        watchPreviousLensesContact()
        $scope.updatePreviousLensesContact = () => {
            watchPreviousLensesContact()
            savePreviousLenses()
        }

        $scope.updateGlasses = () => {
            savePreviousLenses()
        }

        $scope.deleteVisus = distance => {
            if(distance === 1) {
                clearVisus('dist')
                clearVisus('near')
            } else if(distance === 2) {
                clearVisus('near')
            }
        }

        $scope.deleteVisusCorrection = () => {
            clearVisusCorrection()
        }

        $scope.updateAllowSave = bool => {
            $scope.previouslens.allowSave = bool
        }
    }
])