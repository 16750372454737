angular.module('eOpti.app.optometrist').controller('optometristGeneralController', [
    '$scope', '$filter',
    function ($scope, $filter) {
        $scope.pupil_range = [{
            id: 0,
            name: '-'
        }];

        angular.forEach($filter('range')([3, 9], 0.5), function(i) {
            $scope.pupil_range.push({
                id: i,
                name: i.toString() + ' mm'
            });
        });

        $scope.stereopsja_range = [{id: -1, name: '-'}, {id: 0, name: 'Brak'}, {id: 25, name: '25\'\''}, {id: 40, name: '40\'\''},
            {id: 50, name: '50\'\''}, {id: 60, name: '60\'\''}, {id: 80, name: '80\'\''}, {id: 100, name: '100\'\''}, {id: 140, name: '140\'\''},
            {id: 200, name: '200\'\''}, {id: 400, name: '400\'\''}, {id: 800, name: '800\'\''}]
    }
]);