angular.module('eOpti.validate').directive('noZero', [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attr, ctrl) {
                ctrl.$validators.noZero = function (input) {
                    if (input != 0 || input.length > 1) {
                        return true;
                    }

                    return false;
                }
            }
        }
    }
]);