angular.module('eOpti.app.sales').controller('salesListController', ['$scope', 'breadcrumb', function($scope, breadcrumb) {
    breadcrumb.data = [{
        name: 'sale.breadcrumb.SALE'
    }]

    $scope.renderers = {
        comments(value) {
            return value
        }
    }
}])