angular.module('eOpti.app.register.return').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.register.return', {
            abstract: true,
            url: '/return',
            template: '<ui-view/>'
        })

        .state('app.register.return.sale', {
            abstract: true,
            url: '/sale',
            template: '<ui-view/>'
        })
        .state('app.register.return.sale.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/register/return/sale/table.html',
            controller: 'register.return.sale.table'
        })
        .state('app.register.return.sale.details', {
            url: '/show/details/:id',
            templateUrl: '/app/views/sales/details.html',
            controller: 'register.return.sale.details'
        })
        .state('app.register.return.sale.add', {
            url: '/add',
            templateUrl: '/app/views/register/return/sale/add.html',
            controller: 'register.return.sale.add',
            params: {
                saleId: null
            }
        })

        .state('app.register.return.deposit', {
            abstract: true,
            url: '/deposit',
            template: '<ui-view>'
        })
        .state('app.register.return.deposit.add', {
            url: '/add',
            template: '<opti-register-return-task-account></opti-register-return-task-account>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'register.breadcrumb.REGISTER'
                }, {
                    name: 'register.breadcrumb.RETURN'
                }, {
                    name: 'register.breadcrumb.DEPOSIT'
                }]
            }]
        })
}])