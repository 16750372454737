angular.module('eOpti.services').service('tests.service', ['$resource', function ($resource) {
    this.resource = $resource('api/tests/:testId', null, {
        update: {
            method: 'PUT'
        }
    });

    this.questions = {
        resource: $resource('api/test/questions/:questionId', null, {
            update: {
                method: 'PUT'
            }
        })
    };

    this.responsibilities = {
        resource: $resource('api/test/responsibilities/:responsibilityId', null, {
            update: {
                method: 'PUT'
            }
        })
    };
}]);