angular.module('eOpti.app.download').controller('download.history', [
    '$scope', 'breadcrumb', function ($scope, breadcrumb) {
        breadcrumb.data = [{
            functions: 'downloadList',
            args: ['state']
        }, {
            name: 'download.breadcrumb.HISTORY'
        }];


    }]);