angular.module('eOpti.app.download').controller('download.add', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'ENV', 'download.service',
    function($scope, $http, $state, breadcrumb, formValidate, ENV, download) {
        breadcrumb.data = [{
            functions: 'downloadList',
            args: ['state']
        }, {
            name: 'download.breadcrumb.ADD'
        }]

        $scope.formValidate = formValidate

        $scope.uploadHost = ENV.uploadHost

        $scope.form = {
            name: '',
            link: '',
            upload: ''
        }

        $scope.type = 0

        $scope.$watch('type', (newVal, oldVal) => {
            if(newVal != oldVal) {
                if(newVal) {
                    $scope.form.link = ''
                } else {
                    $scope.form.upload = null
                }
            }
        })

        $scope.addFile = () => {
            download.resource.save($scope.form, () => {
                $scope.$emit('success', 'download.notify.SAVE')

                $state.go('app.downloads.table')
            }, () => {
                $scope.$emit('error', 'download.notify.error.SAVE')
            })
        }
    }])