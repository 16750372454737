angular.module('eOpti.app.departments.offices').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.departments.offices', {
            url: '/offices',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.departments.offices.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/departments/offices/table.html',
            controller: ['$scope', 'uac', 'breadcrumb', function($scope, uac, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsOfficesList',
                    args: [null]
                }]

                $scope.uac = uac
            }]
        })
        .state('app.departments.office', {
            url: '/office',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.departments.office.add', {
            url: '/add',
            template: '<opti-departments-office-add></opti-departments-office-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsOfficesList',
                    args: ['state']
                }, {
                    name: 'departments.office.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.departments.office.details', {
            url: '/:id/details',
            template: '<opti-departments-office-details></opti-departments-office-details>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsOfficesList',
                    args: ['state']
                }, {
                    functions: 'departmentsOfficesDetails',
                    args: [null, $state.params.id]
                }]
            }]
        })
        .state('app.departments.office.edit', {
            url: '/:id/edit',
            template: '<opti-departments-office-edit></opti-departments-office-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsOfficesList',
                    args: ['state']
                }, {
                    functions: 'departmentsOfficesDetails',
                    args: ['state', $state.params.id]
                }, {
                    name: 'departments.office.breadcrumb.EDIT'
                }]
            }]
        })
}])
