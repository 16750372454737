angular.module('eOpti.app.users').controller('usersListController', ['$scope', 'uac', 'breadcrumb', function($scope, uac, breadcrumb) {
    breadcrumb.data = [{
        defaults: 'settingsList'
    }, {
        name: 'user.breadcrumb.USERS'
    }]

    $scope.renderers = {
        edit_user_id(value, row, column) {
            return uac.user.id === value ? '<a type="button" href="#/app/user/' + value + '/edit" ' +
                'title="{{\'user.EDIT\'|translate}}"><span class="fa fa-fw fa-2x fa-edit"</a>' : ''
        },
        ipad_serial_number(value, row, column) {
            return value.toString().split(',').join('<br/>')
        },
        role(value, row, column) {
            return `<a class="link" type"button" href="#/app/download/details/89" target="_blank">${column.options[value]}</a>`
        }
    }
}])
