angular.module('eOpti.app.settings').controller('cashRegisterAddSettingsController', [
    '$scope', '$http', 'formValidate', 'error', function ($scope, $http, formValidate, errorValidate) {
        $scope.formValidate = formValidate;

        $scope.cashRegister = {};

        $scope.cashRegisterSave = function() {
            $scope.resource.save($scope.cashRegister, function (res) {
                if(res.status) {
                    $scope.$emit('success', 'settings.cash.notify.SAVE');

                    $scope.model.load();
                } else {
                    $scope.$emit('error', 'settings.cash.notify.error.SAVE');
                }
            }, function (error) {
                errorValidate.request(error, 'settings.cash.notify.error.SAVE');
            });
        };
    }
]);