angular.module('eOpti.app.bookkeeping').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.bookkeeping', {
            abstract: true,
            url: '/bookkeeping',
            template: '<ui-view/>'
        })
        .state('app.bookkeeping.expenses', {
            url: '/expenses/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/bookkeeping/expenses/table.html',
            controller: 'bookkeepingExpensesTableController'
        })

        .state('app.bookkeeping.expense', {
            abstract: true,
            url: '/expense',
            template: '<ui-view/>'
        })
        .state('app.bookkeeping.expense.add', {
            url: '/add',
            template: '<opti-bookkeeping-expenses-add></opti-bookkeeping-expenses-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'bookkeeping.breadcrumb.REGISTER'
                }, {
                    name: 'bookkeeping.breadcrumb.BOOKKEEPING'
                }, {
                    functions: 'bookkeepingExpensesList',
                    args: ['state']
                }, {
                    name: 'bookkeeping.breadcrumb.EXPENSESADD'
                }]
            }]
        })
        .state('app.bookkeeping.expense.edit', {
            url: '/{id}/edit',
            template: '<opti-bookkeeping-expenses-edit></opti-bookkeeping-expenses-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    name: 'bookkeeping.breadcrumb.REGISTER'
                }, {
                    name: 'bookkeeping.breadcrumb.BOOKKEEPING'
                }, {
                    functions: 'bookkeepingExpensesList',
                    args: ['state']
                }, {
                    name: 'bookkeeping.breadcrumb.EXPENSE',
                    id: $state.params.id
                }, {
                    name: 'bookkeeping.breadcrumb.EXPENSEEDIT'
                }]
            }]
        })

        .state('app.bookkeeping.order-to-lens', {
            abstract: true,
            url: '/order-to-lens',
            template: '<ui-view/>'
        })
        .state('app.bookkeeping.order-to-lens.table', {
            url: '/order-to-lens/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'date',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/bookkeeping/orderToLens.html',
            controller: 'bookkeepingOrderToLensController'
        })
        .state('app.bookkeeping.order-to-lens.download', {
            url: '/order-to-lens/table/:id/download',
            templateUrl: 'app/views/bookkeeping/download.html',
            controller: 'bookkeepingOrderToLensDownloadController'
        })

        .state('app.bookkeeping.terminals', {
            abstract: true,
            url: '/order-to-lens',
            template: '<ui-view/>'
        })
        .state('app.bookkeeping.terminals.table', {
            url: '/terminals/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'date',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/bookkeeping/terminals.html',
            controller: 'bookkeepingTerminalsController'
        })

        .state('app.bookkeeping.archive', {
            abstract: true,
            url: '/archive',
            template: '<ui-view/>'
        })
        .state('app.bookkeeping.archive.archived', {
            url: '/archived/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: `<base-table end-point="api/bookkeeping/archive/table/archived" 
                ref="app.bookkeeping.archive.archived" header="sidebar.register.bookkeeping.archive.ARCHIVED">
                    <btn default icon="fa fa-table" ui-sref="app.bookkeeping.archive.not_archived">{{'sidebar.register.bookkeeping.archive.NOT_ARCHIVED'|translate}}</btn>
                    <btn default icon="fa fa-plus" ui-sref="app.bookkeeping.archive.add"> {{'sidebar.register.bookkeeping.archive.ADD'|translate}}</btn>
                </base-table>`,
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.REGISTER'
                }, {
                    name: 'sidebar.register.BOOKKEEPING'
                }, {
                    name: 'sidebar.register.bookkeeping.ARCHIVE'
                }, {
                    name: 'sidebar.register.bookkeeping.archive.ARCHIVED'
                }]
            }]
        })
        .state('app.bookkeeping.archive.not_archived', {
            url: '/not_archived/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'task_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: `<div class="text-muted">
                    <i class="fa fa-exclamation-circle mr3"></i>
                    <span>{{'bookkeeping.archive.NOT_ARCHIVED_INFO'|translate}}</span>
                </div>
                <base-table end-point="api/bookkeeping/archive/table/not-archived" 
                    ref="app.bookkeeping.archive.not_archived" header="bookkeeping.archive.NOTARCHIVED">
                    <btn default icon="fa fa-table" ui-sref="app.bookkeeping.archive.archived">{{'sidebar.register.bookkeeping.archive.ARCHIVED'|translate}}</btn>
                    <btn default icon="fa fa-plus" ui-sref="app.bookkeeping.archive.add"> {{'sidebar.register.bookkeeping.archive.ADD'|translate}}</btn>
                </base-table>`,
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.REGISTER'
                }, {
                    name: 'sidebar.register.BOOKKEEPING'
                }, {
                    name: 'sidebar.register.bookkeeping.ARCHIVE'
                }, {
                    name: 'sidebar.register.bookkeeping.archive.NOT_ARCHIVED'
                }]
            }]
        })
        .state('app.bookkeeping.archive.add', {
            url: '/add',
            template: '<opti-bookkeeping-archive-add></opti-bookkeeping-archive-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.REGISTER'
                }, {
                    name: 'sidebar.register.BOOKKEEPING'
                }, {
                    name: 'sidebar.register.bookkeeping.ARCHIVE'
                }, {
                    name: 'sidebar.register.bookkeeping.archive.ADD'
                }]
            }]
        })
}])