angular.module('eOpti.app.marketing').controller('marketingAddController', [
    '$scope', '$http', '$state','breadcrumb', 'formValidate', 'marketing.service', 'error',
    function ($scope, $http, $state, breadcrumb, formValidate, marketing, errorValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'marketingList'
        }, {
            name: 'marketing.breadcrumb.ADD'
        }]

        $scope.formValidate = formValidate

        $scope.loadingAttachment = []

        $scope.marketing = {
            type: 0,
            uploads: [],
            department_ids: []
        }

        $scope.addAttachment = function() {
            $scope.marketing.uploads.push({
                upload: ''
            })
        }

        $scope.addAttachment()

        $scope.removeAttachment = function(uploads, index) {
            if(index || (!index && uploads.length > 1)) {
                uploads.splice(index, 1)
            } else {
                uploads[index].upload = ''
            }
        }

        $scope.addMarketing = function () {
            marketing.resource.save($scope.marketing, () => {
                $scope.$emit('success', 'marketing.info.ADDED')

                $state.go('app.marketing.list')
            }, error => {
                errorValidate.request(error, 'marketing.notify.ERRORADDED')
            })
        }

        $scope.$watch('marketing.period', period => {
            if(!period) {
                $scope.marketing.from = null
                $scope.marketing.to = null
            }
        })

        $scope.getDepartmentIds = ids => {
            $scope.marketing.department_ids = ids
        }

    }])