angular.module('eOpti.app.settings.general').controller('scheduleDepartmentSettingsController', [
    '$scope', '$http', function ($scope, $http) {
        $scope.hours = [];
        for(var i = 0; i < 24; ++i) {
            $scope.hours.push(i < 10 ? '0' + i : i.toString());
        }
        $scope.minutes = ['00', '30'];

        $scope.schedule = {
            week: {
                from: {
                    hour: '00',
                    minute: '00'
                },
                to: {
                    hour: '00',
                    minute: '00'
                }
            },
            saturday: {
                from: {
                    hour: '00',
                    minute: '00'
                },
                to: {
                    hour: '00',
                    minute: '00'
                }
            },
            sunday: {
                from: {
                    hour: '00',
                    minute: '00'
                },
                to: {
                    hour: '00',
                    minute: '00'
                }
            }
        };

        $http.get('api/settings/schedule/department').then(function(res) {
            $scope.schedule = res.data;

            $scope.loadingSchedule = 'hide';
        }, function() {
            $scope.loadingSchedule = 'error';
        });

        $scope.scheduleTaskSubmit = function() {
            $http.post('api/settings/schedule/department/save', $scope.schedule).then(function() {
                $scope.loading = 'hide';

                $scope.$emit('success', 'settings.schedule.notify.SAVE');
            }, function() {
                $scope.$emit('error', 'settings.schedule.notify.error.SAVE');
            });
        };
    }
]);