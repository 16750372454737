angular.module('eOpti.app.register.expense').controller('register.expense.bank.add', [
    '$scope',
    '$state',
    '$http',
    'breadcrumb',
    'formValidate',
    'error',
    function ($scope, $state, $http, breadcrumb, formValidate, errorValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'register.breadcrumb.REGISTER'
        }, {
            name: 'register.breadcrumb.EXPENSE'
        }, {
            name: 'register.breadcrumb.PAYMENTTOBANK'
        }, {
            name: 'register.breadcrumb.ADD'
        }];

        $scope.formValidate = formValidate;

        $scope.form = {
            value: 0,
            description: ''
        };

        $scope.loading = 'hide';

        $scope.submit = function() {
            $scope.loading = 'loading';

            $http.post('api/register/expenses/bank/add', $scope.form).then(function (res) {
                if(res.data.status) {
                    $state.go('app.register.current');

                    $scope.$emit('success', 'register.expense.bank.notify.SAVE');
                } else {
                    $scope.$emit('error', 'register.expense.bank.notify.error.SAVE');
                }

                $scope.loading = 'hide';
            }, function (error) {
                errorValidate.request(error, 'register.expense.bank.notify.error.SAVE');

                $scope.loading = 'hide';
            });
        };
    }
]);
