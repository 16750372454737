angular.module('eOpti.app.settings').controller('cashRegisterSettingsController', [
    '$scope', '$http', 'cash-register.service', function ($scope, $http, cashRegister) {
        $scope.resource = cashRegister.resource;

        $scope.mode = 'table';

        $scope.setMode = function(mode) {
            $scope.mode = mode;
        };

        $scope.cashRegisters = [];

        $scope.loadingCashRegister = 'hide';

        $scope.model = {
            load: function() {
                $scope.loadingCashRegister = 'loading';

                $scope.resource.query(function(res) {
                    $scope.setMode('table');
                    $scope.loadingCashRegister = 'hide';

                    $scope.cashRegisters = res;
                }, function(error) {
                    $scope.loadingCashRegister = 'error';
                });
            }
        };

        $scope.model.load();

        $scope.setActiveCashRegister = function(id, active) {
            $http.post('api/register/cash-register/active/save', {
               id: id,
                active: active
            }).then(function(res) {
                $scope.$emit('success', 'settings.cash.notify.ACTIVE');

                $scope.model.load();
            }, function(error) {
                $scope.$emit('error', 'settings.cash.notify.error.ACTIVE');
            });
        };

        $scope.setDefaultCashRegister = function(id) {
            $http.post('api/register/cash-register/default/save', {
                id: id
            }).then(function(res) {
                $scope.$emit('success', 'settings.cash.notify.DEFAULT');

                $scope.model.load();
            }, function(error) {
                $scope.$emit('error', 'settings.cash.notify.error.DEFAULT');
            });
        };
    }
]);