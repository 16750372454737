angular.module('eOpti.app.tests').controller('testAddController', [
    '$scope', '$http', '$state', 'breadcrumb', 'tests.service', 'formValidate', 'error',
    function ($scope, $http, $state, breadcrumb, tests, formValidate, errorValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            name: 'test.breadcrumb.ADD'
        }]

        $scope.formValidate = formValidate

        $scope.form = {
            name: '',
            questions_ids: []
        }

        $scope.responsibilities = {}

        $scope.time = 0

        tests.responsibilities.resource.query(function(res) {
            $scope.responsibilities = res;

            $scope.formTest.$setValidity('questions', false);

            $scope.loading = Object.keys($scope.responsibilities).length ? 'hide' : 'empty';
        }, function(error) {
            $scope.loading = 'error';
        });

        $scope.setQuestion = function() {
            var someChecked = false;

            $scope.form.questions_ids = [];
            $scope.time = 0;

            angular.forEach($scope.responsibilities, function(responsibility) {
                responsibility.quantity = 0;

                angular.forEach(responsibility.questions, function(question) {
                    if(question.checked) {
                        someChecked = true;

                        ++responsibility.quantity;
                        $scope.time += question.time;

                        $scope.form.questions_ids.push(question.id);
                    }
                });
            });

            $scope.formTest.$setValidity('questions', someChecked ? true : false);
        };

        $scope.roles = [];

        $http.get('api/roles').then(function(res) {
            $scope.roles = res.data;
        }, function(error) {
            $scope.$emit('error', 'test.create.notify.error.LOADROLES');
        });

        $scope.addTest = function () {
            tests.resource.save($scope.form, function () {
                $scope.$emit('success', 'test.create.notify.NAMESAVE');

                $state.go('app.tests');
            }, function (error) {
                errorValidate.request(error, 'test.create.notify.error.NAMESAVE');
            });
        };
    }
]);