angular.module('eOpti.app.tasks').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.packs', {
            abstract: true,
            url: '/packs',
            template: '<ui-view/>'
        })
        .state('app.packs.list', {
            templateUrl: 'app/views/packs/packs.html',
            controller: 'packsController',
            url: '/packs/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'active',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
        })

        .state('app.packs.edit', {
            url: '/edit/:id',
            template: '<opti-pack-edit></opti-pack-edit>',
            controller: ['breadcrumb', function(breadcrumb){
                breadcrumb.data = [{
                    functions: 'catalog',
                    args: ['state']
                }, {
                    functions: 'packs',
                    args: ['state']
                }, {
                    name:'pack.breadcrumb.EDIT'
                }]
            }]
        })

        .state('app.packs.add', {
            url: '/add',
            template: '<opti-pack-add></opti-pack-add>',
            controller: ['breadcrumb', function(breadcrumb){
                breadcrumb.data = [{
                    functions: 'catalog',
                    args: ['state']
                }, {
                    functions: 'packs',
                    args: ['state']
                }, {
                    name: 'pack.new.ADD'
                }]
            }]
        })
}])