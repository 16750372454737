angular.module('eOpti.app.tasks').controller('detailTaskController', [
    '$scope',
    '$http',
    '$location',
    'task',
    'offer',
    'breadcrumb',
    'memory',
    function($scope,
              $http,
              $location,
              task,
              offer,
              breadcrumb,
              memory) {

        $scope.task = task.data

        window.task = $scope.task

        $scope.offer = offer ? offer.data : null

        $scope.breadcrumbInit = data => breadcrumb.data = data

        $scope.initHash = $location.hash()

        $scope.breadcrumbChangeTab = (hash, tab_name) => {
            $location.hash(hash)

            breadcrumb.changeLast({name: tab_name})
        }

        if(!$scope.offer) {
            $scope.$watch('task.statusName', (nVal, oVal) => {
                if(nVal !== oVal) {
                    breadcrumb.data[2].args = nVal
                }
            })
        }

        $scope.updateOffer = offer => $scope.offer = offer

        $scope.forceSave = () => {
            $scope.task.fake_data = 'fake_data'
        }

        //TODO: sprawdzić rate_save będą się pokazywać jak przejdzie autosave na vue
        $scope.rateSave = () => {
            $scope.task.rate_save = 'rate_save'
        }

        memory.rememberObject('tasks', {
            id: $scope.task.id,
            name: $scope.task.patientName,
            is_offer: !!$scope.offer
        })

        $scope.changeLogs = {
            info: false
        }

        $scope.registerChange = () => {
            $scope.changeLogs.info = true
        }

        $scope.edit = () => {
            return $http.put('api/task/edit/' + ($scope.offer ? 'offer' : 'task'), $scope.task)
        }

        $scope.pendingTask = false

        $scope.$watch('task._autosave._state', function (nStatus, oStatus) {
            if (nStatus !== 'init' && nStatus !== oStatus) {
                $scope.pendingTask = true
            } else {
                if ($scope.pendingTask) {
                    if(typeof $scope.task.reload != 'undefined' && $scope.task.reload) {
                        $scope.$emit('notify', {
                            message: 'tasks.notify.error.RELOAD',
                            status: 'danger',
                            timeout: 10000
                        })
                    }
                }

                $scope.pendingTask = false
            }
        })

        $scope.updatePending = bool => {
            $scope.pendingTask = bool
        }

        $scope.payment_pending = false
        $scope.updatePaymentPending = p => {
            $scope.payment_pending = p
        }

        $scope.$watch('task.registry.discountType', newValue => {
            if(newValue === 'none') {
                $scope.task.registry.discountValue = 0
            }
        })
    }
])
