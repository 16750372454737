angular.module('eOpti.app.departments').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.departments', {
            url: '/departments',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.departments.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/departments/list/table.html',
            controller: 'departmentsListController'
        })
        .state('app.departments.add', {
            url: '/add',
            template: '<opti-departments-list-add></opti-departments-list-add>',
            controller: ['breadcrumb', function (breadcrumb) {
                    breadcrumb.data = [{
                        last: 3
                    }, {
                        functions: 'departments',
                        args: ['state']
                    }, {
                        name: 'departments.breadcrumb.ADD'
                    }]
                }
            ]
        })
        .state('app.departments.edit', {
            url: '/department/:id/edit',
            template: '<opti-departments-list-edit></opti-departments-list-edit>',
            controller: ['breadcrumb', function (breadcrumb) {
                    breadcrumb.data = [{
                        last: 3
                    }, {
                        functions: 'departments',
                        args: ['state']
                    }, {
                        name: 'departments.breadcrumb.EDIT'
                    }]
                }
            ]
        })
        .state('app.departments.delete', {
            url: '/service/:id/delete',
            templateUrl: 'app/views/departments/list/delete.html',
            controller: 'departmentsDeleteController'
        })
        .state('app.departments.groups', {
            url: '/groups',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.departments.groups.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/departments/groups/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsGroupsList',
                    args: ['notstate']
                }]
            }]
        })
        .state('app.departments.group', {
            url: '/group',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.departments.group.add', {
            url: '/group/add',
            template: '<opti-departments-group-add></opti-departments-group-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 3
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsGroupsList',
                    args: ['state']
                }, {
                    name: 'departments.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.departments.group.edit', {
            url: '/group/:id/edit',
            template: '<opti-departments-group-edit></opti-departments-group-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 3
                }, {
                    functions: 'departments',
                    args: ['state']
                }, {
                    functions: 'departmentsGroupsList',
                    args: ['state']
                }, {
                    name: 'departments.breadcrumb.GROUP',
                    id: $state.params.id
                }, {
                    name: 'departments.breadcrumb.EDIT'
                }]
            }]
        })
}])