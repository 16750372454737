angular.module('eOpti.app.sms.template').controller('smsTemplateDeleteController', [
    '$scope', '$http', '$state', 'breadcrumb', 'sms.template.service', 'dialogs',
    function ($scope, $http, $state, breadcrumb, template, dialogs) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'settingsList'
        }, {
            name: 'sms.template.breadcrumb.SMS'
        }, {
            functions: 'smsTemplateList',
            args: ['state']
        }, {
            name: 'sms.template.breadcrumb.TEMPLATE',
            id: $state.params.id
        }, {
            name: 'sms.template.breadcrumb.DELETE'
        }];

        $scope.loadingSmsTemplate = 'loading';

        dialogs.confirm('sms.template.confirm.delete.MESSAGE', {
            className: 'ngdialog-theme-plain',
            data: {
                confirm: 'sms.template.confirm.delete.YES',
                confirmIcon: 'fa fa-fw fa-check',
                cancel: 'sms.template.confirm.delete.NO'
            }
        }, 'true').then(function() {
            template.resource.remove({templateId: $state.params.id}, function(res) {
                if(res.status === 'deleted') {
                    $scope.$emit('success', 'sms.template.notify.DELETED');

                    $state.previous('app.sms.template.list');
                } else if(res.status === 'system') {
                    $scope.$emit('error', 'sms.template.notify.error.DELETESYSTEM');

                    $state.previous('app.sms.template.list');
                } else {
                    $scope.loadingSmsTemplate = 'error';
                }
            }, function() {
                $scope.loadingSmsTemplate = 'error';
            });
        }, function() {
            $state.previous('app.sms.template.list');
        });

    }]);