angular.module('eOpti.app.register').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.register', {
            abstract: true,
            url: '/register',
            template: '<ui-view/>'
        })
        .state('app.register.current', {
            url: '/current',
            templateUrl: '/app/views/register/history.html',
            controller: 'registerHistoryController'
        })
        .state('app.register.history', {
            url: '/history',
            templateUrl: '/app/views/register/history.html',
            controller: 'registerHistoryController'
        })
        .state('app.register.detail', {
            url: '/detail/{id}',
            templateUrl: '/app/views/register/detail.html',
            controller: 'registerDetailController'
        })
 }])