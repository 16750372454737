angular.module('eOpti.app.sms.template').controller('smsCampaignListController', [
    '$scope', 'breadcrumb',
    function ($scope, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'settingsList'
        }, {
            name: 'sms.template.breadcrumb.SMS'
        }, {
            functions: 'smsCampaignList',
            args: ['nostate']
        }];

        $scope.renderers = {
            filtr: function(value) {
                return value ? JSON.parse(value).join('<br/>') : '';
            }
        };

    }]);