import {EventBus} from "@/js/app/_bridge/EventBus";
import {API} from "@/js/app/vue/api";
import Notify from "@/js/app/vue/helpers/Notify";

angular.module('eOpti.app.patients').config(['$stateProvider', function ($stateProvider) {
	$stateProvider
		.state('app.patient', {
			url: '/patient',
			template: '<ui-view/>',
			abstract: true
		})
		.state('app.patient.detail', {
			url: '/detail/:id',
			params: {
				status: null,
				offerId: null,
				prescriptionId: null,
				historySurvey: false,
				'#': 'detail'
			},
			template: '<opti-patients-details v-props-init-hash="initHash" v-on-update-memory="updateMemory" ' +
				'v-on-breadcrumb-change-last="breadcrumbChangeLast"></opti-patients-details>',
			controller: ['$scope', '$state', '$location', 'breadcrumb', 'memory', function ($scope, $state, $location, breadcrumb, memory) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.id]
				}, {
					name: 'patient.card.DETAIL'
				}]

				$scope.$emit('dialog-close')

				$scope.initHash = $location.hash()

				$scope.updateMemory = patient => {
					memory.rememberObject('patients', patient)
				}

				$scope.breadcrumbChangeLast = (hash, tab_name) => {
					$location.hash(hash)

					breadcrumb.changeLast({name: tab_name})
				}
			}]
		})
		.state('app.patient.prescription-add', {
			url: '/:patientId/prescription/add',
			template: '<opti-patients-details-prescription-add></opti-patients-details-prescription-add>',
			controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.patientId, 'state']
				}, {
					functions: 'patientPrescriptions',
					args: [$state.params.patientId]
				}, {
					name: 'patient.breadcrumb.ADD'
				}]
			}]
		})
		.state('app.patient.prescription-edit', {
			url: '/:patientId/prescription/:prescriptionId/edit',
			template: '<opti-patients-details-prescription-edit></opti-patients-details-prescription-edit>',
			controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.patientId, 'state']
				}, {
					functions: 'patientPrescriptions',
					args: [$state.params.patientId, $state.params.prescriptionId]
				}, {
					name: 'patient.breadcrumb.EDIT'
				}]
			}]
		})
		.state('app.patient.loyality-event-add', {
			url: '/:patientId/loyality-card/add-event/:cardId',
			template: '<opti-patients-details-loyality-cards-event-add></opti-patients-details-loyality-cards-event-add>',
			controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.patientId, 'state']
				}, {
					functions: 'patientLoyality',
					args: [$state.params.patientId, $state.params.cardId]
				}, {
					name: 'patient.breadcrumb.ADDEVENT'
				}]
			}]
		})
		.state('app.patient.loyality-event-edit', {
			url: '/:patientId/loyality-card/edit-event/:eventId',
			template: '<opti-patients-details-loyality-cards-event-edit></opti-patients-details-loyality-cards-event-edit>',
			controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.patientId, 'state']
				}, {
					functions: 'patientLoyality',
					args: [$state.params.patientId, $state.params.patientId]
				}, {
					name: 'patient.breadcrumb.EDITEVENT',
					id: $state.params.eventId
				}]
			}]
		})
		.state('app.patient.loyality-order', {
			url: '/:patientId/loyality-card/order-add/:cardId/:cardType',
			template: '<opti-patients-details-loyality-cards-order></opti-patients-details-loyality-cards-order>',
			controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.patientId, 'state']
				}, {
					functions: 'patientLoyality',
					args: [$state.params.patientId, $state.params.cardId]
				}, {
					name: 'patient.breadcrumb.ADDLOYALITY'
				}]
			}]
		})
		.state('app.patient.find', {
			url: '/find',
			params: {
				visit: null
			},
			template: '<opti-patient-find></opti-patient-find>',
			controller: ['breadcrumb', function (breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					name: 'sidebar.patients.FIND'
				}]
			}]
		})
		.state('app.patient.add', {
			url: '/add',
			params: {
				patient: null,
				visit: null
			},
			template: '<opti-patient-add></opti-patient-add>',
			controller: ['breadcrumb', function (breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					name: 'patient.breadcrumb.ADD'
				}]
			}]
		})
		.state('app.patient.edit', {
			url: '/:id/edit',
			template: '<opti-patient-edit></opti-patient-edit>',
			controller: ['$state', 'breadcrumb', function ($state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'patient',
					args: [$state.params.id, 'state']
				}, {
					name: 'patient.breadcrumb.EDIT'
				}]
			}]
		})
		.state('app.patients', {
			url: '/patients',
			template: '<ui-view/>',
			abstract: true
		})
		.state('app.patients.list', {
			url: '/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			template: `<div class="row">
               <div class="col-xs-12">
                  <base-table end-point="/api/patients/table" header="patient.PATIENTS" sub-header="" ref="app.patients.list"></base-table>
               </div>
            </div>`,
			controller: ['breadcrumb', function(breadcrumb) {
				breadcrumb.data = [{
					name: 'patient.breadcrumb.PATIENTS'
				}]
			}]
		})
		.state('app.patients.cards', {
			url: '/cart',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			template: '<opti-patient-loyality-card></opti-patient-loyality-card>',
			controller: ['breadcrumb', function (breadcrumb) {
				breadcrumb.data = [{
					defaults: 'patientsList'
				}, {
					name: 'cards.breadcrumb.LOYALITYCARDS'
				}]
			}]
		})
		.state('app.patients.cards.list', {
			url: '/:page/:search',
			parent: 'app.patients.cards'
		})
		.state('app.patients.smses', {
			url: '/smses/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			templateUrl: 'app/views/patients/table/smses.html',
			controller: ['breadcrumb', function(breadcrumb) {
				breadcrumb.data = [{
					defaults: 'patientsList'
				}, {
					name: 'sms.breadcrumb.SMSES'
				}]
			}]
		})
		.state('app.patients.contact-plus', {
			url: '/contact-plus/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'created_at',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			templateUrl: 'app/views/patients/contact-plus/table.html',
			controller: ['breadcrumb', function (breadcrumb) {
				breadcrumb.data = [{
					defaults: 'patientsList'
				}, {
					functions: 'contactPlusList',
					args: [null]
				}]
			}]
		})
		.state('app.patients.contact-plus-add', {
			url: '-contact-plus-edit/:id',
			template: '<opti-contact-plus-add></opti-contact-plus-add>',
			controller: ['breadcrumb', function (breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					functions: 'contactPlusList',
					args: ['state']
				}, {
					name: 'patient.breadcrumb.ADD'
				}]
			}]
		})
		.state('app.patients.prescriptions', {
			url: '/prescriptions/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			templateUrl: 'app/views/patients/table/prescriptions.html',
			controller: 'patientsListPrescriptionsController'
		})
		.state('app.patients.interviews', {
			url: '/interviews/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			templateUrl: 'app/views/patients/table/interviews.html',
			controller: ['breadcrumb', function(breadcrumb) {
				breadcrumb.data = [{
					defaults: 'patientsList'
				}, {
					name: 'optometrist.breadcrumb.INTERVIEWS'
				}]
			}]
		})
		.state('app.patients.matching', {
			url: '/matching/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			templateUrl: 'app/views/patients/table/matching.html',
			controller: ['breadcrumb', function(breadcrumb) {
				breadcrumb.data = [{
					defaults: 'patientsList'
				}, {
					name: 'optometrist.breadcrumb.MATCHING'
				}]
			}]
		})
		.state('app.patients.schedule', {
			url: '/schedule/:department_id/:view/:date',
			params: {
				department_id: '0',
				view: 'day',
				date: moment().format('YYYY-MM-DD'),
				patient: null,
				contact_lens: null,
				doctor: null,
				visit_id: null
			},
			template: '<opti-patients-schedule></opti-patients-schedule>',
			controller: ['breadcrumb', function (breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList'
				}, {
					name: 'patient.schedule.breadcrumb.SCHEDULE'
				}]
			}]
		})
		.state('app.patients.offers', {
			url: '/offers/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			template: `<base-table end-point="/api/patients/table/offer" header="patient.offer.table.HEADER" 
				ref="app.patients.offers">
				<opti-button-report-rate v-props-type="'offers'"></opti-button-report-rate>
				<btn default icon="fa fa-plus" ui-sref="app.catalog.offer" 
					ng-click="createNewOffer()"> {{'catalog.offer.ADD'|translate}}</btn>
		    </base-table>
		    <opti-catalog-offer-preview class="hide" ng-if="offer_id" v-props-auto-open="true" v-props-id="offer_id" v-on-reload="reload"></opti-catalog-offer-preview>`,
			controller: ['$scope', '$state', 'breadcrumb', 'VuexStore',
				function($scope, $state, breadcrumb, VuexStore) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList',
					args: [$state.params.id, 'state']
				}, {
					name: 'patient.offer.breadcrumb.OFFERS'
				}]

				$scope.createNewOffer = () => {
					VuexStore.dispatch('offer/createNewOffer', {})
				}

				$scope.reload = () => $scope.$broadcast('table-refresh')

				$scope.offer_id = null

				$scope.$on('preview', (env, value) => {
					$scope.offer_id = null

					setTimeout(() => {
						$scope.offer_id = value

						$scope.$apply()
					}, 1)
				})
			}]
		})
		.state('app.patients.emails', {
			url: '/emails/:page/:search',
			params: {
				page: '1',
				pageSize: '10',
				sortBy: 'id',
				sortOrder: 'desc',
				search: {
					array: true
				},
				columns: {
					array: true
				}
			},
			template: `<base-table end-point="/api/patients/table/emails" 
				header="patient.emails.breadcrumb.EMAILS" ref="app.patients.emails">
			</base-table>
			<opti-patient-email-preview class="hide" ng-if="mail_id" 
				v-props-auto-open="true" v-props-type="'id'" v-props-id="mail_id"
				v-on-changed="reload"></opti-patient-email-preview>`,
			controller: ['$scope', '$state', 'breadcrumb', function ($scope, $state, breadcrumb) {
				breadcrumb.data = [{
					last: 2
				}, {
					defaults: 'patientsList',
					args: [$state.params.id, 'state']
				}, {
					name: 'patient.emails.breadcrumb.EMAILS'
				}]

				$scope.reload = () => $scope.$broadcast('table-refresh')

				$scope.mail_id = null

				$scope.$on('preview', (env, value) => {
					$scope.mail_id = null

					setTimeout(() => {
						$scope.mail_id = value

						$scope.$apply()
					}, 1)
				})
			}]
		})
}])