angular.module('eOpti.app.sales').config(['$stateProvider', function ($stateProvider) {
    $stateProvider
        .state('app.sales', {
            url: '/sales/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            controller: 'salesListController',
            templateUrl: 'app/views/sales/table.html'
        })
        .state('app.sales-products', {
            url: '/sales-products/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'sale_id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            controller: 'salesProductsListController',
            templateUrl: 'app/views/sales/products/table.html'
        })
        .state('app.sales-offstock', {
            url: '/sales/offstock/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            controller: 'salesOffstockListController',
            templateUrl: 'app/views/sales/offstock/table.html'
        })
        .state('app.sale-offstock', {
            url: '/sale/offstock',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.sale-offstock.details', {
            url: '/details/:id',
            templateUrl: 'app/views/sales/offstock/details.html',
            controller: 'saleOffstockDetailsController'
        })
        .state('app.sale-offstock.add', {
            url: '/add',
            templateUrl: 'app/views/sales/offstock/add.html',
            controller: 'saleOffstockAddController'
        })
        .state('app.sale', {
            url: '/sale',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.sale.add', {
            url: '/add',
            template: '<opti-cart-sale-add></opti-cart-sale-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'salesList'
                }, {
                    name: 'sale.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.sale.details', {
            url: '/details/:id',
            templateUrl: 'app/views/sales/details.html',
            controller: 'saleDetailsController'
        })
}])
