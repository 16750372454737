angular.module('eOpti.app.optometrist').controller('previousGlassesTaskOptometristController', [
    '$scope', '$http', 'dialogs', function($scope, $http, dialogs) {
        angular.forEach($scope.interview.patient.tasksDone, task => {
            $scope.previouslens.checked_task[task.id] = false
        })

        angular.forEach($scope.previouslenses, (previouslens, key) => {
            if(previouslens.task_id && $scope.interview.previous_glasses_checked[key]) {
                $scope.previouslens.checked_task[previouslens.task_id] = true
            }
        })

        let _delete = (key, task_id) => {
            $scope.interview.previous_glasses_checked[key] = false
            $scope.previouslens.checked_task[task_id] = false

            $scope.updatePreviousLenses()
        }

        let clearVisus = dist => {
            $scope.interview[dist + '_c_r_visus'] = '-'
            $scope.interview[dist + '_c_r_cz'] = '0'
            $scope.interview[dist + '_c_l_visus'] = '-'
            $scope.interview[dist + '_c_l_cz'] = '0'
            $scope.interview[dist + '_c_binvisus'] = '-'
        }

        let uncheckFromConfirm = (key, task_id) => {
            dialogs.confirm('previouslens.confirm.uncheck.MESSAGE', {
                className: 'ngdialog-theme-plain',
                data: {
                    translate: true,
                    confirm: 'previouslens.confirm.uncheck.YES',
                    confirmIcon: 'fa fa-fw fa-check',
                    cancel: 'previouslens.confirm.uncheck.NO'
                }
            }).then(() => {
                angular.forEach($scope.previouslenses, (previouslens, previouslensKey) => {
                    if(key == previouslensKey) {
                        if(previouslens.distance_id == 1) {
                            clearVisus('dist')
                            clearVisus('near')
                        } else if(previouslens.distance_id == 2) {
                            clearVisus('near')
                        }
                    }
                })

                _delete(key, task_id)
            })
        }

        let addPreviousLens = (task, to_uncheck, ipad, ipad_id, measurement_jawro_visu, measurement_jawro_visu_id,
                               distance_id, type_id) => {
            $scope.previouslenses.unshift({
                patient_id: $scope.interview.patient.id,
                distance_id: distance_id,
                type_id: type_id,
                target_id: 0,
                r_sph: task.rightEye.sphere,
                r_cyl: task.rightEye.cylinder,
                r_axs: task.rightEye.axis,
                r_add: task.rightEye.support ? task.rightEye.support : task.rightEye.addition,
                r_prd: task.rightEye.prism,
                r_base: task.rightEye.prismAngle,
                l_sph: task.leftEye.sphere,
                l_cyl: task.leftEye.cylinder,
                l_axs: task.leftEye.axis,
                l_add: task.leftEye.support ? task.leftEye.support : task.leftEye.addition,
                l_prd: task.leftEye.prism,
                l_base: task.leftEye.prismAngle,
                task_id: task.id,
                cant_read_powers: false,
                comments: '',
                measurement: {
                    ipad_measurements_previous_lens_id: ipad_id,
                    measurement_jawro_visu_id: measurement_jawro_visu_id,
                    pdR: 0,
                    pdL: 0,
                    btR: 0,
                    btL: 0,
                    ipad: ipad,
                    measurementJawroVisu: measurement_jawro_visu
                }
            })

            $scope.interview.previous_glasses_checked.unshift(true)

            $scope.previouslens.checked_task[task.id] = true

            if(typeof to_uncheck !== 'undefined') {
                $scope.interview.previous_glasses_checked[to_uncheck + 1] = false
            }
        }

        let taskIsChecked = task_id => {
            let _return = -1

            angular.forEach($scope.previouslenses, (previousLens, key) => {
                if(previousLens.task_id === task_id) {
                    _return = key
                }
            })

            return _return
        }

        let check = (task, to_uncheck) => {
            let distance_id = 0,
                type_id = 0

            switch(task.type) {
                case 1:
                    distance_id = 2
                    type_id = 1
                    break

                case 2:
                    distance_id = 1
                    type_id = 1
                    break

                case 3:
                    distance_id = 1
                    type_id = 3
                    break

                case 4:
                    distance_id = 1
                    type_id = 4
            }

            if(Object.keys(task.pack).length && task.pack.pack_name1[0] === 'B') {
                type_id = 2
            }

            let task_was_checked = taskIsChecked(task.id)
            if(task_was_checked !== -1) {
                $scope.interview.previous_glasses_checked[task_was_checked] = true

                $scope.previouslens.checked_task[task.id] = true

                if(typeof to_uncheck !== 'undefined') {
                    $scope.interview.previous_glasses_checked[to_uncheck] = false
                }

                $scope.updatePreviousLenses()
            } else {
                if(task.measurementId) {
                    $http.post('api/optometrist/previouslenses/measurement/visu-real', {
                        patient_id: $scope.interview.patient.id,
                        task_measurement_id: task.measurementId,
                        distance_id: distance_id
                    }).then(res => {
                        addPreviousLens(task, to_uncheck, res.data, res.data.id, [], 0, distance_id, type_id)
                    }, () => {
                        $scope.$emit('error', 'optometrist.notify.error.MEASUREMENT')
                    })
                } else if(task.measurement_jawro_visu_id) {
                    $http.post('api/optometrist/previouslenses/measurement/jawro-visu', {
                        patient_id: $scope.interview.patient.id,
                        task_measurement_id: task.measurement_jawro_visu_id,
                        distance_id: distance_id
                    }).then(res => {
                        addPreviousLens(task, to_uncheck, [], 0, res.data, res.data.id, distance_id, type_id)
                    }, () => {
                        $scope.$emit('error', 'optometrist.notify.error.MEASUREMENT')
                    })
                } else {
                    addPreviousLens(task, to_uncheck, [], 0, [], 0, distance_id, type_id)
                }
            }
        }

        let checkFromConfirm = (task, to_uncheck) => {
            dialogs.confirm('previouslens.confirm.uncheck.MESSAGE', {
                className: 'ngdialog-theme-plain',
                data: {
                    translate: true,
                    confirm: 'previouslens.confirm.uncheck.YES',
                    confirmIcon: 'fa fa-fw fa-check',
                    cancel: 'previouslens.confirm.uncheck.NO'
                }
            }).then(() => {
                let is_deleted = false

                angular.forEach($scope.previouslens.checked_task, (checked_task, checked_task_id) => {
                    if(checked_task && task.id != checked_task_id) {
                        angular.forEach($scope.interview.patient.tasksDone, patient_task => {
                            if(checked_task_id == patient_task.id && task.type == patient_task.type) {
                                angular.forEach($scope.previouslenses, (previouslens, key) => {
                                    if(previouslens.task_id == checked_task_id) {
                                        _delete(key, checked_task_id)

                                        is_deleted = true
                                    }
                                })
                            }
                        })
                    }
                })

                if(!is_deleted) {
                    check(task, to_uncheck)
                } else {
                    check(task)
                }
            })
        }

        $scope.checkPreviousLensTask = task => {
            if(!$scope.block2()) {
                if($scope.previouslens.checked_task[task.id]) {
                    angular.forEach($scope.previouslenses, (previouslens, key) => {
                        if(previouslens.task_id == task.id) {
                            uncheckFromConfirm(key, task.id)
                        }
                    })
                } else {
                    let dist = -1,
                        near = -1

                    angular.forEach($scope.previouslenses, (previouslens, key) => {
                        if($scope.interview.previous_glasses_checked[key]) {
                            if(previouslens.distance_id === 1) {
                                dist = key
                            } else if(previouslens.distance_id === 2) {
                                near = key
                            }
                        }
                    })

                    if(dist > -1 && (task.type === 2 || task.type === 3 || task.type === 4)) {
                        checkFromConfirm(task, dist)
                    } else if(near > -1 && task.type === 1) {
                        checkFromConfirm(task, near)
                    } else {
                        check(task)
                    }
                }
            }
        }
    }
])