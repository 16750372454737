angular.module('eOpti.app.optometrist').controller('optometristExtraDetailsController', [
    '$scope',
    '$rootScope',
    '$http',
    '$state',
    '$location',
    '$enum',
    'breadcrumb',
    'formValidate',
    function ($scope, $rootScope, $http, $state, $location, $enum, breadcrumb, formValidate) {
        breadcrumb.data = [{
            functions: 'patientName',
            args: []
        }, {
            functions: 'interview',
            args: []
        }, {
            name: 'optometrist.extra.breadcrumb.OPTOMETRIST'
        }, {
            name: 'optometrist.breadcrumb.section.SUMMARY'
        }];

        $scope.formValidate = formValidate;

        var prefixTabLang = 'optometrist.breadcrumb.section.',
            prefixTabExtraLang = 'optometrist.extra.breadcrumb.';

        $scope.tab = {
            summary: prefixTabLang + 'SUMMARY',
            previousglasses: prefixTabLang + 'PREVIOUSGLASSES',
            general: prefixTabExtraLang + 'GENERAL',
            eyeMovements: prefixTabExtraLang + 'EYEMOVEMENTS',
            accommodation: prefixTabExtraLang + 'ACCOMMODATION',
            squint: prefixTabExtraLang + 'SQUINT',
            fusion: prefixTabExtraLang + 'FUSION',
            fixation: prefixTabExtraLang + 'FIXATION',
            retinal: prefixTabExtraLang + 'RETINAL',
            paralytic: prefixTabExtraLang + 'PARALYTIC',
            damping: prefixTabExtraLang + 'DAMPING',
            prism: prefixTabExtraLang + 'PRISM'
        };

        $scope.cardActive = [];

        $scope.setActive = function (hash) {
            $scope.activeTab = $scope.tab[hash];

            angular.forEach($scope.tab, function(value, key) {
                $scope.cardActive[key] = false;
            });

            $scope.cardActive[hash] = true;

            breadcrumb.changeLast({name: $scope.activeTab});

            $location.hash(hash);
        };

        if($location.hash()) {
            $scope.setActive($location.hash());
        } else {
            $scope.setActive('summary');
        }

        $rootScope.$emit('dialog-close');

        $scope.points = {
            covd: 0
        };

        $scope.active_cylinder = 'minus';

        $scope.changeActiveCylinder = function(sign) {
            $scope.active_cylinder = sign;
        };

        $scope.active_cylinder_contactlens = 'minus';

        $scope.changeActiveCylinderContactLens = function(sign) {
            $scope.active_cylinder_contactlens = sign;
        };

        $scope.correction = {
            plus: {
                dist: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                near: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            },
            minus: {
                dist: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                near: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            },
            matches: {
                dist: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                near: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            },
            contactlens: {
                plus: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                minus: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            }
        };

        $scope.visus_values = ['-', '0,01', '0,1', '0,2', '0,3', '0,4', '0,5', '0,6', '0,7', '0,8', '0,9', '1,0', '1,2', '1,5', '2,0'];

        $http.get('api/optometrist/interview/extra/' + $state.params.id).then(function(res) {
            $scope.interview = res.data;
            window.interview = $scope.interview;

            $http.get('api/patient/previouslenses/all/fromPatientId/' + $scope.interview.patient.id).then(function(res) {
                $scope.previouslenses = res.data.previouslenses;
                $scope.contactlenses = res.data.contactlenses;

                $scope.loading = 'hide';

                init();
            }, function(error) {
                $scope.loading = 'error';
            });
        }, function(error) {
            $scope.loading = 'error';
        });

        $scope.task_checked = {};

        var init = function() {
            window.interview = $scope.interview;

            breadcrumb.data[0].args = [
                'state',
                $scope.interview.patient.firstName + ' ' + $scope.interview.patient.lastName,
                $scope.interview.patient.id
            ];

            breadcrumb.data[1].args = [
                'state',
                $scope.interview.id,
                $scope.interview.previous_interview_id
            ];

            let points = 0,
                checked = true

            $scope.interview.questionnaire.forEach(questionnaire => {
                if(questionnaire.pivot.option !== -1) {
                    points += questionnaire.pivot.option
                } else {
                    checked = false
                }
            })

            $scope.points.covd = checked ? points : -1

            angular.forEach($scope.interview.patient.tasksDone, function(task) {
                $scope.task_checked[task.id] = false;
            });

            angular.forEach($scope.previouslenses, function(previousLens) {
                if(previousLens.task_id > 0) {
                    $scope.task_checked[previousLens.task_id] = true;
                }
            });

            $scope.previous = {};

            if($scope.interview.previous_interview_id) {
                angular.forEach($scope.interview.previous, function(previous) {
                    if($scope.interview.previous_interview_id === previous.id) {
                        $scope.previous = previous;
                    }
                });
            }
        };

        $scope.mainSavePending = false;

        $scope.$watch('interview._autosave._state', function (state) {
            if(state === 'error') {
                $scope.$emit('error', 'optometrist.notify.error.SAVE');
                $state.reload();
            }

            $scope.mainSavePending = state !== 'init'
        });

        $scope.edit = function () {
            return $http.put('api/optometrists/extra/edit', $scope.interview);
        };

        $scope.results = {
            maddox: {
                r: {
                    prism: 0,
                    angle: 0
                },
                l: {
                    prism: 0,
                    angle: 0
                },
                selected: []
            }
        };

        $scope.previouslens = {
            checked_task: {},
            dist: false,
            near: false,
            dist_cant_read_powers: false,
            allowSave: true
        };

        $scope.accommodation_point_range = [{id: 0, name: '-'}, {id: 4, name: '4 cm'}, {id: 5, name: '5 cm'}, {id: 6, name: '6 cm'},
            {id: 7, name: '7 cm'}, {id: 8, name: '8 cm'}, {id: 9, name: '9 cm'}, {id: 10, name: '10 cm'}, {id: 11, name: '11 cm'},
            {id: 12, name: '12 cm'}, {id: 13, name: '13 cm'}, {id: 14, name: '14 cm'}, {id: 15, name: '15 cm'}, {id: 16, name: '16 cm'},
            {id: 17, name: '17 cm'}, {id: 18, name: '18 cm'}, {id: 19, name: '19 cm'}, {id: 20, name: '20 cm'}, {id: 22, name: '22 cm'},
            {id: 24, name: '24 cm'}, {id: 26, name: '26 cm'}, {id: 28, name: '28 cm'}, {id: 30, name: '30 cm'}, {id: 35, name: '35 cm'},
            {id: 40, name: '40 cm'}, {id: 45, name: '45 cm'}, {id: 50, name: '50 cm'}, {id: 55, name: '> 55'}];

        $scope.$watch('interview.optometrist.accommodation_point_r', function(nVal, oVal) {
            if(nVal !== oVal) {
                if(nVal) {
                    $scope.interview.optometrist.accommodation_point_r_aa = 100 / nVal;
                } else {
                    $scope.interview.optometrist.accommodation_point_r_aa = -999;
                }
            }
        });

        $scope.$watch('interview.optometrist.accommodation_point_l', function(nVal, oVal) {
            if(nVal !== oVal) {
                if(nVal) {
                    $scope.interview.optometrist.accommodation_point_l_aa = 100 / nVal;
                } else {
                    $scope.interview.optometrist.accommodation_point_l_aa = -999;
                }
            }
        });

        $scope.obliteration_range = ['-', 'x', '1', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20'];

        $scope.breaking_range = [{id: 0, name: '-'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 4, name: '4'}, {id: 6, name: '6'},
            {id: 8, name: '8'}, {id: 10, name: '10'}, {id: 12, name: '12'}, {id: 14, name: '14'}, {id: 16, name: '16'}, {id: 18, name: '18'},
            {id: 20, name: '20'}, {id: 22, name: '22'}, {id: 24, name: '24'}, {id: 26, name: '26'}, {id: 28, name: '28'}, {id: 30, name: '30'}];

        $scope.reversal_range = ['-', '-4', '-2', '-1', '0', '1', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30'];

        $scope.updateInterview = interview => {
            $scope.interview = interview
        }
    }
]);
