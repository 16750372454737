angular.module('eOpti.app.layout').controller('objectConfigController', [
    '$scope',
    '$http',
    '$state',
    'model',
    function ($scope, $http, $state, model) {
        $scope.model = model.data

        $scope.isCustom = model => {
            switch (model.config.type) {
                case 'custom':
                case 'glasscase':
                case 'advertisement':
                    return true
                default:
                    return false
            }
        }

        if ($scope.isCustom($scope.model)) {
            $scope.model.config.width  = $scope.model.config.width || 90
            $scope.model.config.height = $scope.model.config.height || 30
            $scope.model.config.min    = $scope.model.config.min || 1
            $scope.model.config.max    = $scope.model.config.max || 999
        }

        $scope.objects = []

        $http
            .get('api/layout/grid/' + $scope.model.gridId + '/objects/summary')
            .then(response => {
                $scope.objects = response.data.filter(obj => {
                    if (obj.id !== $scope.model.id) {
                        if (($scope.model.config.type === 'glasscase' || $scope.model.config.type === 'advertisement') &&
                            (obj.type === 'glasscase' || obj.type === 'advertisement')) {
                            return true
                        } else if (($scope.model.config.type !== 'glasscase' && $scope.model.config.type !== 'advertisement') &&
                            (obj.type !== 'glasscase' && obj.type !== 'advertisement')) {
                            return true
                        }
                    }

                    return false
                })

                $scope.objects = $scope.objects.map(item => {
                    let brands  = item.brands || []
                    let reduced = brands.reduce((prev, brand) => {
                        return (prev ? prev + ', ' : '') + brand.name + (brand.type === 'sun' ? '☼' : '')
                    }, false)
                    reduced     = !reduced ? '' : ' (' + reduced + ')'
                    item.name   = item.name + reduced
                    return item
                })
            })

        $scope.getObjects = except => {
            if (!$scope.model[except]) return $scope.objects

            return $scope.objects.filter(obj => {
                return obj.id !== $scope.model[except]
            })
        }

        $scope.goBack = () => {
            $state.go('app.layout.grid.unit', {id: $scope.model.id}, {location: false})
        }

        $scope.save = () => {
            let data = {
                name: $scope.model.name,
                previous: $scope.model.previous,
                next: $scope.model.next
            }

            if ($scope.isCustom($scope.model)) {
                data.config = {
                    width: parseInt($scope.model.config.width),
                    height: parseInt($scope.model.config.height),
                    min: parseInt($scope.model.config.min),
                    max: parseInt($scope.model.config.max)
                }
            }

            $http
                .patch('api/layout/object/' + $scope.model.id, data)
                .then(response => {
                    $scope.model = response.data
                    $scope.$emit('update-layout-selection', $scope.model)
                    $scope.goBack()
                })
        }

        $scope.cancelEdit = () => {
            $state.go('app.layout.grid.unit', {id: $scope.model.id}, {location: false})
        }

        window.ob = $scope
    }
])
