angular.module('eOpti.app.tasks').controller('raportListTaskController', [
    '$scope',
    '$http',
    'breadcrumb',
    function ($scope, $http, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'sidebar.settings.REPORTS'
        }, {
            name: 'tasks.grindery.breadcrumb.SCORES'
        }];

        $scope.goGenerate = function(type) {
            $http.get('api/tasks/grindery/report/' + type).then(function (res) {
                window.open('upload/grindery/' + res.data + '.xls');
            }, function () {
                $scope.$emit('error', 'tasks.grindery.notify.error.XLS');
            });
        };
    }
]);
