angular.module('eOpti.app.optometrist').controller('interviewOptometristController', [
    '$scope', '$http', '$state', 'ENV',
    function ($scope, $http, $state, ENV) {
        $scope.uploadHost = ENV.uploadHost

        $scope.loadingDisease = []

        $scope.addDiseasePhoto = images => {
            images.push({image: ''})
        }

        $scope.removeDiseasePhoto = (images, index) => {
            if(index || (!index && images.length > 1)) {
                images.splice(index, 1)
            } else {
                images[index].image = ''
            }
        }

        $http.get('api/optometrist/doctors').then(res => {
            $scope.doctors = res.data
        }, () => {
            $scope.$emit('error', 'dictionary.doctor.notify.error.EDIT')
        })

        $scope.goDoctorVisit = () => {
            $state.go('app.patients.schedule', {
                doctor: {
                    patient: $scope.interview.patient,
                    interview_id: $scope.interview.id,
                    comments: $scope.interview.diseases_comments,
                    reason: $scope.interview.recommended_visit_reason
                }
            })
        }

        $scope.goScheduleDoctor = () => {
            $state.go('app.patients.schedule', {
                department_id: $scope.interview.visit.department_id,
                visit_id: $scope.interview.visit.id,
                date: $scope.interview.visit.date_visit,
                view: 'day'
            })
        }

        $scope.$watch('interview.recommended_visit', (newVal, oldVal) => {
            if(newVal !== oldVal && !newVal) {
                $scope.interview.doctor = {}
                $scope.interview.recommended_visit_reason = ''
            }
        })

        $scope.block_eye = disease_id => {
            return [4].includes(disease_id)
        }
    }
])