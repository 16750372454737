angular.module('eOpti.validate').directive('dbMatch', ['$http', '$q', '$timeout',
    function ($http, $q, $timeout) {
        return {
            restrict: 'A',
            require: ['ngModel',  '^pending'],
            link: function (scope, elem, attrs, ctrl) {

                var timeout = false;

                ctrl[0].$asyncValidators.dbmatch = function (input) {
                    var defer = $q.defer();

                    if(input) {
                        if (timeout) {
                            $timeout.cancel(timeout);
                        }

                        timeout = $timeout(function () {
                            $http.post('api/validate/dbmatch', {
                                data: attrs.dbMatch,
                                compare: input
                            }).then(function (res) {
                                if (res.data.info === 'fail') {
                                    defer.reject();
                                } else if (res.data.info === 'ok') {
                                    defer.resolve();
                                }
                            }, function (error) {
                                scope.$emit('error', 'default.notify.ERRORDBMATCH');
                                defer.reject();
                            });
                        }, 500);
                    } else {
                        defer.resolve();
                    }

                    return defer.promise;
                };

                scope.$watch(function() {
                    return ctrl[0].$pending;
                }, function(nVal) {
                   if(nVal) {
                       ctrl[1].show();
                   } else {
                       ctrl[1].hide();
                   }
                }, true);
            }
        }
    }
]);