angular.module('eOpti.app.tests').controller('questionAddController', [
    '$scope', '$http', '$timeout', '$state', 'breadcrumb', 'tests.service', 'formValidate', 'error',
    function ($scope, $http, $timeout, $state, breadcrumb, tests, formValidate, errorValidate) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            defaults: 'testsResponsibilitiesList'
        }, {
            functions: 'testResponsibility',
            args: ['state', $state.params.id]
        }, {
            name: 'test.breadcrumb.QUESTION'
        }, {
            name: 'test.breadcrumb.ADD'
        }];

        $scope.formValidate = formValidate;

        $scope.responsibility = {};

        tests.responsibilities.resource.get({responsibilityId: $state.params.id}, function(res) {
            $scope.responsibility = res;

            $scope.loading = Object.keys($scope.responsibility).length ? 'hide' : 'empty';
        }, function(error) {
            $scope.loading = 'error';
        });

        $scope.responsibilities = [];

        tests.responsibilities.resource.query(function(res) {
            $scope.responsibilities = res;
        });

        $scope.form = {
            tests_responsibility_id: $state.params.id,
            time: 0,
            answer: 1,
            pointsA: 0,
            pointsB: 0,
            pointsC: 0,
            pointsD: 0,
            picture: ''
        };

        $scope.max = 0;
        $scope.time = {
            minute: 0,
            second: 0
        };

        $scope.$watch('form', function(form) {
            $scope.max = Math.max(parseInt(form.pointsA), parseInt(form.pointsB),
                parseInt(form.pointsC), parseInt(form.pointsD));
        }, true);

        $scope.$watch('time', function(time) {
            $scope.form.time = time.minute * 60 + time.second;
        }, true);

        $scope.addQuestionTest = function () {
            tests.questions.resource.save($scope.form, function () {
                $scope.$emit('success', 'test.question.notify.SAVE');

                $state.go('app.test.responsibility.details', {
                    id: $state.params.id
                });
            }, function (error) {
                errorValidate.request(error, 'test.question.notify.error.SAVE');
            });
        };

        $scope.existsQuestion = false;

        var timeout = false;

        $scope.$watch('form.question', function(question) {
            if(question) {
                if(timeout) {
                    $timeout.cancel(timeout);
                }

                timeout = $timeout(function() {
                    $http.post('api/validate/dbmatch', {
                        data: 'test_questions,question',
                        compare: question
                    }).then(function (res) {
                        $scope.existsQuestion = res.data.info == 'ok' ? false : true;
                    }, function (error) {
                        scope.$emit('error', 'default.notify.ERRORDBMATCH');
                    });
                }, 500);
            } else {
                $scope.existsQuestion = false;
            }
        });
    }
]);