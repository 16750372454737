angular.module('eOpti.app.tasks.reclamations').config(['$stateProvider', function($stateProvider) {
    let generateReclamationStatus = () => {
        let statuses = ['cancelled', 'accepted', 'acceptedsent', 'waitingforparts',
            'beingrepaired', 'readytobesent', 'sent', 'received', 'done', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.tasks.reclamations.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: '10',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/tasks/reclamations/table/' + status + '.html',
                controller: ['$scope', 'breadcrumb', 'reclamationTableRenderers', function($scope, breadcrumb, reclamationTableRenderers) {
                    breadcrumb.data = [{
                        last: 2
                    }, {
                        name: 'reclamations.breadcrumb.TASKS'
                    }, {
                        name: 'reclamations.breadcrumb.RECLAMATIONS'
                    }, {
                        functions: 'reclamations',
                        args: 'reclamations.status.' + status.toUpperCase()
                    }]

                    $scope.renderers = reclamationTableRenderers.getRenderers('tasks')
                }]
            })
        })
    }

    $stateProvider
        .state('app.tasks.reclamations', {
            url: '/reclamations',
            abstract: true,
            template: '<ui-view/>'
        })

    generateReclamationStatus()

    $stateProvider
        .state('app.tasks.reclamation', {
            url: '/reclamation',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.tasks.reclamation.add', {
            url: '/add',
            params: {
                task: {}
            },
            template: '<opti-reclamation-task-add></opti-reclamation-task-add>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'reclamations.breadcrumb.TASKS'
                }, {
                    name: 'reclamations.breadcrumb.RECLAMATIONS'
                }, {
                    name: 'reclamations.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.tasks.reclamation.edit', {
            url: '/{id:[0-9]+}/edit',
            template: '<opti-reclamation-task-edit v-on-update-breadcrumb="updateBreadcrumb"></opti-reclamation-task-edit>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'reclamations.breadcrumb.TASKS'
                }, {
                    name: 'reclamations.breadcrumb.RECLAMATIONS'
                }, {
                    functions: 'reclamations',
                    args: null
                }, {
                    name: 'reclamations.breadcrumb.RECLAMATION',
                    id: $state.params.id,
                    type: 'state',
                    state: 'app.tasks.reclamation.details({id:' + $state.params.id + '})'
                }, {
                    name: 'reclamations.breadcrumb.EDIT'
                }]

                $scope.updateBreadcrumb = status_name => {
                    breadcrumb.data[3].args = status_name
                }
            }]
        })
        .state('app.tasks.reclamation.details', {
            url: '/{id:[0-9]+}',
            template: '<opti-reclamation-task-details v-on-update-breadcrumb="updateBreadcrumb"></opti-reclamation-task-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'reclamations.breadcrumb.TASKS'
                }, {
                    name: 'reclamations.breadcrumb.RECLAMATIONS'
                }, {
                    functions: 'reclamations',
                    args: null
                }, {
                    functions: 'reclamation',
                    args: [$state.params.id]
                }]

                $scope.updateBreadcrumb = status_name => {
                    breadcrumb.data[3].args = status_name
                }
            }]
        })
}])
