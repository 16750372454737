angular.module('eOpti.app.sms.template').controller('smsCampaignEditController', [
    '$scope', '$http', '$state', '$localStorage', '$filter', 'breadcrumb', 'formValidate',
    function ($scope, $http, $state, $localStorage, $filter, breadcrumb, formValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'settingsList'
        }, {
            name: 'sms.template.breadcrumb.SMS'
        }, {
            functions: 'smsCampaignList',
            args: ['state']
        }, {
            name: 'sms.campaign.breadcrumb.CAMPAIGN',
            id: $state.params.id
        }, {
            name: 'sms.campaign.breadcrumb.EDIT'
        }]

        $scope.formValidate = formValidate

        $scope.campaign = {}
        let campaign_before = $localStorage['sms-campaign-edit']

        $http.get('api/sms/campaign/' + $state.params.id).then(res => {
            $scope.campaign = res.data

            if(typeof campaign_before !== 'undefined' && campaign_before.id === $scope.campaign.id && campaign_before.updated_at === $scope.campaign.updated_at) {
                $scope.campaign = $localStorage['sms-campaign-edit']
                $scope.campaign.active = res.data.active
            } else {
                $localStorage['sms-campaign-edit'] = $scope.campaign
                $localStorage['app.sms.campaign.edit-search'] = $scope.campaign.filtrs
            }

            $scope.$watch('campaign', (nVal, oVal) => {
                if(!angular.equals(nVal, oVal)) {
                    $localStorage['sms-campaign-edit'] = $scope.campaign
                }
            }, true)

            $scope.$watch('campaign.active', (active, old) => {
                if(active !== old && active) {
                    $scope.campaign.started_at = null
                }
            })

            $scope.loading = 'hide'
        }, () => {
            $scope.loading = 'error'

            $scope.$emit('error', 'sms.campaign.notify.error.LOAD')
        })

        $scope.editCampaign = () => {
            $scope.loading = 'loading'

            $scope.campaign.filtrs = $localStorage['app.sms.campaign.edit-search']

            $http.post('api/sms/campaign/edit', $scope.campaign).then(() => {
                $state.go('app.sms.campaign.list')

                $scope.$emit('success', 'sms.campaign.notify.EDIT')
            }, () => {
                $scope.loading = 'error'

                $scope.$emit('error', 'sms.campaign.notify.error.EDIT')
            })
        }

        $scope.removeCampaign = () => {
            $http.post('api/sms/campaign/remove', {
                id: $state.params.id
            }).then(() => {
                $state.go('app.sms.campaign.list')
            }, error => {
                $scope.$emit('error', error.data.message)
            })
        }
    }])
