angular.module('eOpti.app.tasks').controller('wzToTasksTaskController', [
    '$scope', '$http', '$state', 'breadcrumb', '$localStorage',
    function($scope, $http, $state, breadcrumb, $localStorage) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'tasks.printouts.breadcrumb.WAREHOUSE'
        }, {
            name: 'tasks.wzToTasks.breadcrumb.WZTOTASKS'
        }]

        $scope.loading = false

        if(!window.localStorage.getItem('ngStorage-create_task_wz_checkbox')) {
            //dlatego, że zdefiniowane jest to OptiOrdersDetails.vue
            $localStorage['create_task_wz_checkbox'] = []
        }

        let _finally = () => {
            $localStorage['create_task_wz_checkbox'] = []
            $scope.$broadcast('table-refresh')
        }

        $scope.goWZTasks = () => {
            $scope.loading = true
            $scope.task_ids = window.localStorage.getItem('ngStorage-create_task_wz_checkbox')

            $http.post('api/tasks/create-group-wz', {
                ids: $scope.task_ids
            }).then(res => {
                _finally()
                $state.go('app.tasks.wz.details', {id: res.data})

                $scope.$emit('success', 'tasks.wzToTasks.notify.GENERATED')
            }, () => {
                $scope.loading = false

                $scope.$emit('error', 'tasks.wzToTasks.notify.error.GENERATED')
            })
        }
}])