angular.module('eOpti.app.tasks').controller('toPrintTaskController', [
    '$scope', 'breadcrumb', '$http', '$state', 'uac',
    function($scope, breadcrumb, $http, $state, uac) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'tasks.breadcrumb.TASKS'
        }, {
            name: 'tasks.printouts.breadcrumb.TOPRINT'
        }]

        $scope.loading = false

        $scope.taskPrintout = () => {
            $scope.loading = true

            $http.get('api/task/print/control-group/' + uac.user.id).then(res => {
                window.open(res.data, '_blank')
                $state.reload()
                $scope.loading = false
                $scope.$emit('success','tasks.printouts.notify.SUCCESS')
            })
        }
}])