angular.module('eOpti.app').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app', {
            url: '/app',
            abstract: true,
            templateUrl: 'app/views/app.html',
            controller: 'AppController',
            resolve: {
                authorize: [function() {
                    return true
                }]
            },
            data: {
                roles: [
                    'user2'
                ]
            }
        })

        .state('app.logout', {
            url: '/logout',
            template: '<div></div>',
            controller: ['uac', function(uac) {
                uac.logout()
            }]
        })
}])