angular.module('eOpti').config([
    '$provide',
    function ($provide) {
        $provide.decorator('$breadcrumb', ['$delegate', function ($delegate) {
            var properJson = function (text) {
                try {
                    JSON.parse(text);
                } catch (e) {
                    return false;
                }
                return true;
            };

            var linkFrom = function (state, arr, val) {
                var oldUrl = state.ncyBreadcrumbLink;

                var search = encodeURI(arr.join('/'));

                return oldUrl.replace(search, '');
            };

            var oldChain = $delegate.getStatesChain;
            $delegate.getStatesChain = function (exitOnFirst) {
                var chain = oldChain(exitOnFirst);
                var newChain = [];

                angular.forEach(chain, function (state) {
                    var label = state.ncyBreadcrumbLabel;

                    if (properJson(label)) {
                        var data = JSON.parse(label);
                        var arr = data;

                        angular.forEach(arr, function (link, val) {
                            var newState = angular.copy(state);
                            newState.ncyBreadcrumb.label = val;
                            newState.ncyBreadcrumbLabel = val;

                            if(link) {
                                newState.ncyBreadcrumbLink = link;
                            }

                            newChain.push(newState);
                        });

                    } else {
                        newChain.push(state);
                    }
                });

                return newChain;
            };

            return $delegate;
        }])
    }
]).config(['$breadcrumbProvider', function($breadcrumbProvider) {
    $breadcrumbProvider.setOptions({
        template: '<ol class="breadcrumb">' +
            '<li ng-repeat="step in steps" ng-class="{active: $last}" ng-switch="$last || !!step.abstract">' +
                '<a ng-switch-when="false" ui-sref="{{step.ncyBreadcrumbLink}}">{{step.ncyBreadcrumbLabel}}</a> ' +
                '<span ng-switch-when="true">{{step.ncyBreadcrumbLabel}}</span>' +
            '</li>' +
        '</ol>'
    });
}]);
