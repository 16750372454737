angular.module('eOpti.pages').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('page', {
                    url: '/page',
                    templateUrl: 'app/pages/page.html',
                    controller: ['$rootScope', function ($rootScope) {
                        $rootScope.app.layout.isBoxed = true;
                    }]
                })
                .state('page.login', {
                    url: '/login',
                    title: 'Login',
                    templateUrl: 'app/pages/login.html'
                })
                .state('page.lock', {
                    url: '/lock',
                    title: 'Lock',
                    templateUrl: 'app/pages/lock.html'
                })
                .state('page.404', {
                    url: '/404',
                    title: 'Not Found',
                    templateUrl: 'app/pages/404.html'
                })
                .state('page.403', {
                    url: '/403',
                    title: 'Access denied',
                    templateUrl: 'app/pages/403.html'
                })
                .state('page.block', {
                    url: '/block',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: [
                            'type:=:insufficient_stock'
                        ],
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/layout/alerts-table.html',
                    controller: 'layoutAlertsController'
                })
            ;
        }
    ]
);
