angular.module('eOpti.app.assemblies').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.assemblies', {
            url: '/dictionary/assemblies',
            template: '<ui-view/>',
            abstract: true
        })

        .state('app.assemblies.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/dictionary/assembliesTable.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'services.breadcrumb.DICTIONARY'
                }, {
                    name: 'services.breadcrumb.SERVICES'
                }]
            }]
        })
        .state('app.assemblies.edit', {
            url: '/assembly/:id/edit',
            template: '<opti-assemblies-edit></opti-assemblies-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    name: 'services.breadcrumb.DICTIONARY'
                },
                    {
                    defaults: 'servicesList'
                }, {
                    name: 'services.breadcrumb.EDIT'
                }]
            }]
        })
}])