angular.module('eOpti.app.sms.template').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.sms', {
                    url: '/sms',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.sms.template', {
                    url: '/templates',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.sms.template.list', {
                    url: '/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/sms/template/table.html',
                    controller: 'smsTemplateListController'
                })
                .state('app.sms.template.add', {
                    url: '/add',
                    templateUrl: 'app/views/sms/template/add.html',
                    controller: 'smsTemplateAddController'
                })
                .state('app.sms.template.edit', {
                    url: '/template/:id/edit',
                    templateUrl: 'app/views/sms/template/edit.html',
                    controller: 'smsTemplateEditController'
                })
                .state('app.sms.template.delete', {
                    url: '/template/:id/delete',
                    templateUrl: 'app/views/sms/template/delete.html',
                    controller: 'smsTemplateDeleteController'
                })

                .state('app.sms.campaign', {
                    url: '/campaigns',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.sms.campaign.list', {
                    url: '/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/sms/campaign/table.html',
                    controller: 'smsCampaignListController'
                })
                .state('app.sms.campaign.add', {
                    url: '/add/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/sms/campaign/add.html',
                    controller: 'smsCampaignAddController'
                })
                .state('app.sms.campaign.edit', {
                    url: '/edit/:id/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/sms/campaign/edit.html',
                    controller: 'smsCampaignEditController'
                })
        }
    ]
);
