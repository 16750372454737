angular.module('eOpti.app.download').controller('download.delete', [
    '$scope', '$http', '$state', 'breadcrumb', 'download.service', 'dialogs', function ($scope, $http, $state, breadcrumb, download, dialogs) {
        breadcrumb.data = [{
            functions: 'downloadList',
            args: ['state']
        }, {
            name: 'download.breadcrumb.FILE',
            id: $state.params.id
        }, {
            name: 'download.breadcrumb.DELETING'
        }];

        dialogs.confirm('download.confirm.delete.MESSAGE', {
            className: 'ngdialog-theme-plain',
            data: {
                translate: true,
                confirm: 'download.confirm.delete.YES',
                confirmIcon: 'fa fa-fw fa-trash',
                cancel: 'download.confirm.delete.NO'
            }
        }).then(function () {
            download.resource.remove({downloadId: $state.params.id}, function() {
                $scope.$emit('success', 'download.notify.DELETE');

                $state.previous('app.downloads.table');
            }, function() {
                $scope.loading = 'error';
            });
        }, function() {
            $state.previous('app.downloads.table');
        });

    }]);