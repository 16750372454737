angular.module('eOpti.app.messages').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.messages', {
                    url: '/messages',
                    abstract: true,
                    template: '<ui-view/>'
                })
                .state('app.messages.view', {
                    url: '/box',
                    templateUrl: 'app/views/messages/view.html',
                    controller: 'messages.view',
                })
                .state('app.messages.view.inbox', {
                    url: '/inbox/:page/:search',
                    templateUrl: 'app/views/messages/table/inbox.html',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'date_of_last_message',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    }
                })
                .state('app.messages.view.unread', {
                    url: '/unread/:page/:search',
                    templateUrl: 'app/views/messages/table/unread.html',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'date_of_last_message',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    }
                })
                .state('app.messages.view.starred', {
                    url: '/starred/:page/:search',
                    templateUrl: 'app/views/messages/table/starred.html',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'date_of_last_message',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    }
                })
                .state('app.messages.view.sent', {
                    url: '/sent/:page/:search',
                    templateUrl: 'app/views/messages/table/sent.html',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'date_of_last_message',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    }
                })
                .state('app.messages.view.create', {
                    url: '/create',
                    templateUrl: 'app/views/messages/create.html',
                    controller: 'messages.view.create'
                })
                .state('app.messages.view.details', {
                    url: '/details/:id',
                    templateUrl: 'app/views/messages/details.html',
                    controller: 'messages.view.details'
                })
        }
    ]
);
