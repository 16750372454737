angular.module('eOpti.app.settings.general').controller('cacheSettingsController', [
    '$scope', '$http', 'uac', function ($scope, $http, uac) {
        $scope.date = '';
        $scope.loading = false;

        var getDateCache = function() {
            $scope.loading = true;

            $http.get('api/settings/localstorage/truncate').then(function(res) {
                $scope.date = res.data.date;

                $scope.loading = false;
            }, function() {
                $scope.$emit('error', 'settings.general.cache.notify.error.NOSETCACHE');

                $scope.loading = false;
            });
        };

        if(uac.permission('settings.general')) {
            getDateCache();
        }

        $scope.setToCache = function() {
            $scope.loading = true;

            $http.get('api/settings/localstorage/truncate/set').then(function(res) {
                $scope.date = res.data.date;

                $scope.loading = false;
            }, function() {
                $scope.$emit('error', 'settings.general.cache.notify.error.NOSETCACHE');

                $scope.loading = false;
            });
        };

        $scope.btn_task_shape_disabled = false;

        $scope.goUploadShapes = function() {
            $scope.btn_task_shape_disabled = true;

            $http.get('api/settings/upload').then(function(res) {
                $scope.$emit('notify', {
                    status: 'success',
                    timeout: 10000,
                    translate: false,
                    message: res.data
                });

                $scope.btn_task_shape_disabled = false;
            }, function() {
                $scope.$emit('error', 'settings.general.task.notify.error.SHAPE');

                $scope.btn_task_shape_disabled = false;
            });
        };
    }
]);