angular.module('eOpti.app.optometrist').controller('optometristDampingController', [
    '$scope',
    function ($scope) {
        $scope.damping_distance = [{id: 0, name: '-'}, {id: 0.15, name: '0,15'}, {id: 0.2, name: '0,20'}, {id: 0.3, name: '0,30'},
            {id: 0.4, name: '0,40'}, {id: 0.5, name: '0,50'}, {id: 0.7, name: '0,70'}, {id: 1, name: '1,00'}, {id: 1.5, name: '1,50'},
            {id: 2, name: '2,00'}, {id: 2.5, name: '2,50'}, {id: 2.5, name: '2,50'}, {id: 3, name: '3,00'}, {id: 4, name: '4,00'}];

        $scope.damping_time = [{id: -1, name: '-'}, {id: 0, name: '0%'}, {id: 10, name: '10%'}, {id: 25, name: '25%'},
            {id: 50, name: '50%'}, {id: 75, name: '75%'}, {id: 100, name: '100%'}];
    }
]);