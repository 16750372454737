angular.module('eOpti.app.warehouse.products').config(
    [
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider
                .state('app.prices', {
                    abstract: true,
                    url: '/prices',
                    template: '<ui-view/>'
                })

                .state('app.prices.list', {
                    templateUrl: 'app/views/warehouse/pricesTable.html',
                    controller: ['breadcrumb', function(breadcrumb) {
                        breadcrumb.data = [{
                            name: 'prices.breadcrumb.WAREHOUSE'
                        }, {
                            name: 'product.breadcrumb.PRODUCTS'
                        }, {
                            name: 'prices.breadcrumb.PRICESTOCHANGE'
                        }]
                    }],
                    url: '/list/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    }
                })


                .state('app.prices.choose', {
                    url: '/choose',
                    template: '<opti-product-price-change-add></opti-product-price-change-add>',
                    controller: ['breadcrumb', function(breadcrumb) {
                        breadcrumb.data = [{
                            name: 'prices.breadcrumb.WAREHOUSE'
                        }, {
                            name: 'product.breadcrumb.PRODUCTS'
                        }, {
                            functions: 'pricesList',
                            args: ['state']
                        }, {
                            name: 'prices.breadcrumb.ADD'
                        }]
                    }]
                })


                .state('app.prices.edit', {
                    url: '/edit/:id',
                    template: '<opti-product-price-change-edit></opti-product-price-change-edit>',
                    controller: [
                        'breadcrumb', function (breadcrumb) {
                            breadcrumb.data = [{
                                name: 'prices.breadcrumb.WAREHOUSE'
                            }, {
                                name: 'product.breadcrumb.PRODUCTS'
                            }, {
                                functions: 'pricesList',
                                args: ['state']
                            }, {
                                name: 'prices.breadcrumb.DETAILS'
                            }]
                        }
                    ],
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    }
                })

                .state('app.prices.edit.table', {
                    url: '/:page/:search',
                    parent: 'app.prices.edit',
                    // params: {
                    //     page: '1',
                    //     pageSize: '10',
                    //     sortBy: 'id',
                    //     sortOrder: 'desc',
                    //     search: {
                    //         array: true
                    //     },
                    //     columns: {
                    //         array: true
                    //     }
                    // }
                })

                .state('app.prices.import', {
                    url: '/edit/pricesImport/:id',
                    template: '<opti-product-price-change-import></opti-product-price-change-import>',
                    controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                        breadcrumb.data = [{
                            name: 'prices.breadcrumb.WAREHOUSE'
                        }, {
                            name: 'product.breadcrumb.PRODUCTS'
                        }, {
                            functions: 'pricesList',
                            args: ['state']
                        }, {
                            functions: 'pricesDetails',
                            args: [$state.params.id, 'state']
                        }, {
                            name: 'prices.breadcrumb.IMPORT'
                        }]
                    }]
                })


                .state('app.prices.change', {
                    url: '/edit/changesList/:id',
                    template: '<opti-product-price-change-product v-on-update-breadcrumb="updateBreadcrumb"></opti-product-price-change-product>',
                    controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                        breadcrumb.data = [{
                            name: 'prices.breadcrumb.WAREHOUSE'
                        }, {
                            name: 'product.breadcrumb.PRODUCTS'
                        }, {
                            functions: 'pricesList',
                            args: ['state']
                        }]

                        $scope.updateBreadcrumb = (promotion_id, type) => {
                            breadcrumb.data.push({
                                functions: 'pricesDetails',
                                args: [promotion_id, 'state']
                            }, {
                                name: 'prices.breadcrumb.product.' + type
                            })
                        }
                    }]
                })
        }
    ]
);
