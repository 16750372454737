angular.module('eOpti.app.events').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.events', {
            abstract: true,
            url: '/events',
            template: '<ui-view/>'
        })

        .state('app.events.upload', {
            url: '/upload',
            template: '<opti-events-upload></opti-events-upload>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'events.breadcrumb.POSTERS'
                }]
            }]
        })
}])