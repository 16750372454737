angular.module('eOpti.i18n').factory('missingTranslationHandler', [
    '$rootScope',
    '$log',
    '$http',
    function ($rootScope, $log, $http) {
        var missing = [];

        return function (translationId) {

            if (!missing[translationId]) {
                missing[translationId] = true;
                $http.post('/app/i18n/missing', { key: translationId, locale: $rootScope.language.locale }).then(function (response) {
                    if(response) {
                        console.log(response);
                    }
                });
            }

            $log.warn(translationId);
        }
    }
]);
