angular.module('eOpti.app.optometrist').controller('optometristDeleteController', [
    '$scope', '$state', '$http', 'breadcrumb', 'dialogs',
    function ($scope, $state, $http, breadcrumb, dialogs) {
        breadcrumb.data = [{
            functions: 'patientVisit',
            args: ['state']
        }, {
            name: 'optometrist.breadcrumb.DELETINGVISIT'
        }];

        dialogs.confirm('optometrist.action.remove.MESSAGE', {
            className: 'ngdialog-theme-plain',
            data: {
                confirm: 'optometrist.action.remove.YES',
                confirmIcon: 'fa fa-fw fa-check',
                cancel: 'optometrist.action.remove.NO'
            }
        }, 'true').then(function () {
            $http.get('api/optometrist/interview/delete/' + $state.params.id).then(function(res) {
                if(res.data.status === 'OK') {
                    $scope.$emit('success', 'optometrist.notify.REMOVE');
                } else {
                    $scope.$emit('error', 'optometrist.notify.error.REMOVE');
                }

                $state.go('app.optometrists.list');
            }, function(error) {
                $scope.$emit('error', 'optometrist.notify.error.REMOVE');

                $state.go('app.optometrists.list');
            });
        }, function() {
            $state.go('app.optometrists.list');
        });
    }
]);