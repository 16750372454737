angular.module('eOpti.app.layout').controller('viewController', [
    '$scope',
    '$http',
    '$state',
    '$timeout',
    'breadcrumb',
    'layoutClasses',
    'svgExport',
    'uac',
    'ENV',
    function ($scope, $http, $state, $timeout, breadcrumb, layoutClasses, svgExport, uac, ENV) {
        $scope.uac = uac

        $scope.showUnits = uac.permission('reports.snapshots')

        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: ['state']
        }, {
            functions: 'layoutDetails',
            args: [$state.params.departmentId, null, null]
        }, {
            functions: 'layoutList',
            args: ['all']
        }]

        $scope.department = null
        $http.get('api/layout/get-department-by-id/' + $state.params.departmentId).then(res => {
            $scope.department          = res.data
            breadcrumb.data[2].args[1] = res.data
        }, () => {
            $scope.$emit('error', 'default.notify.error.DEFAULT')
        })

        $scope.navi = {
            previous: 'brak',
            next: 'brak',
            loading: false
        }

        $scope.updateNaviLoading = bool => {
            $scope.navi.loading = bool
        }

        $scope.displayObject = false

        $scope.showObjectContent = (panel, select) => {
            $scope.navi.previous = 'brak'
            $scope.navi.next = 'brak'

            $scope.$evalAsync(() => {
                $scope.displayObject = $scope.selection !== panel
                if(!panel) $scope.displayObject = false
                if($scope.displayObject) {
                    setActive(panel)

                    $state.go('app.layout.grid.unit', {
                        id: panel.id,
                        select: select
                    }, {
                        location: false
                    })
                } else {
                    setActive(false)
                }
            })
        }

        $scope.editObject = () => {
            if (!$scope.selection || !($scope.selection instanceof layoutClasses.definitions.LayoutObjectAbstract)) return

            if ($state.is('app.layout.grid.unit') || $state.is('app.layout.grid.advertisement')) {
                $state.go('app.layout.grid.object', {id: $scope.selection.id}, {location: false})
            } else {
                $state.go('app.layout.grid.unit', {id: $scope.selection.id}, {location: false})
            }
        }

        $scope.editAdvertisement = () => {
            if(!$scope.selection || !($scope.selection instanceof layoutClasses.definitions.LayoutObjectAbstract)) return

            if($state.is('app.layout.grid.unit') || $state.is('app.layout.grid.object')) {
                $state.go('app.layout.grid.advertisement', {id: $scope.selection.id}, {location: false})
            } else {
                $state.go('app.layout.grid.unit', {id: $scope.selection.id}, {location: false})
            }
        }

        $scope.previousObject = select => {
            $scope.selectObject($scope.selection.previous, select)
        }

        $scope.nextObject = select => {
            $scope.selectObject($scope.selection.next, select)
        }

        $scope.selectObject = (id, select) => {
            $scope.data.objects.forEach(obj => {
                if(obj.id === id) {
                    $scope.showObjectContent(obj, select)
                }
            })
        }

        $scope.$on('update-layout-selection', () => {
            $scope.fetchData()
        })

        $scope.current = $scope

        $scope.snapToGrid = true
        $scope.toggleSnap = () => {
            $scope.snapToGrid = !$scope.snapToGrid
        }

        $scope.grid = {
            width: 8,
            height: 6,
            getWidth: function () {
                return this.width * this.small.width * this.big.countX + 1
            },
            getHeight: function () {
                return this.height * this.small.height * this.big.countY + 1
            },
            viewBox: function () {
                return '0 0 ' + this.getWidth() + ' ' + this.getHeight()

                // size of one big square
                let ax = this.small.width * this.big.countX,
                    ay = this.small.height * this.big.countY

                let box = [
                    -1 * ax * 2,
                    -1 * ay * 2,
                    this.getWidth() + ax * 4,
                    this.getHeight() + ay * 4
                ]

                return box.join(' ')
            },
            small: {
                width: 10,
                height: 10
            },
            big: {
                countX: 10,
                countY: 10
            },
            smallPath: function () {
                return 'M ' + $scope.grid.small.width + ' 0 L 0 0 0 ' + $scope.grid.small.height
            },
            bigPath: function () {
                return 'M ' + ($scope.grid.small.width * $scope.grid.big.countX) + ' 0 L 0 0 0 ' + ($scope.grid.small.height * $scope.grid.big.countY)
            }
        }

        let roundPos = (val, dim, size) => {
            if(!$scope.snapToGrid) {
                val = Math.round(val)
            } else {
                val = Math.round(val / $scope.grid.small[dim]) * $scope.grid.small[dim]
            }

            if(!size) {
                size = 0
            }

            val = Math.max(0, val)
            if(dim === 'height') {
                val = Math.min($scope.grid.getHeight() - size, val)
            } else if(dim === 'width') {
                val = Math.min($scope.grid.getWidth() - size, val)
            }

            return val
        }

        let roundAngle = angle => {
            if (!$scope.snapToGrid) {
                return Math.round(angle)
            } else {
                return Math.round(angle / 5) * 5
            }
        }

        let setActive = element => {
            if($scope.selection instanceof layoutClasses.definitions.FocusableObject) {
                $scope.selection.setFocus(false)
            }

            $scope.selection = element
            if(element) {
                $scope.selection.setFocus(true)
            }
        }

        let functions = {
            room: {
                applyFn: function (fn) {
                    $scope.$apply(fn)
                },
                roundingFn: roundPos,
                activateFn: setActive
            },
            object: {
                applyFn: function (fn) {
                    $scope.$apply(fn)
                },
                roundingFn: roundPos,
                roundingAngleFn: roundAngle,
                activateFn: setActive,
                toggle: function (panel) {
                    $timeout(function () {
                        $scope.showObjectContent(panel)
                    })
                }
            }
        }
        let assignConfFn = (conf, type) => {
            return Object.assign({}, functions[type], conf)
        }

        $scope.removeRoom = room => {
            $scope.data.rooms = $scope.data.rooms.filter(obj => {
                return room !== obj
            })
        }

        $scope.removeObject = object => {
            $scope.data.objects = $scope.data.objects.filter(obj => {
                return object !== obj
            })
        }

        let fillData = (data, grid) => {
            grid.width  = data.grid.width
            grid.height = data.grid.height

            for(let i = 0; i < data.objects.length; i++) {
                let conf        = assignConfFn(data.objects[i], 'object')
                data.objects[i] = new layoutClasses.objects[conf.type](conf)
            }

            for(let i = 0; i < data.rooms.length; i++) {
                let conf = assignConfFn(data.rooms[i], 'room')
                data.rooms[i] = new layoutClasses.rooms[conf.type](conf)
            }

            return data
        }

        $scope.saveData = () => {
            let data  = Object.assign({}, $scope.data)
            data.grid = {
                width: $scope.grid.width,
                height: $scope.grid.height
            }
            data.svg  = svgExport.getData($('#floor')[0])
            $http.post('api/layout/room/' + $state.params.departmentId, data).then(result => {
                $scope.data = fillData(result.data, $scope.grid)
            })
        }

        $scope.wasOpened = false
        $scope.snapshotWeeks = 4
        $scope.snapTotalTurnover = 0
        $scope.snapTotalSoldQuantity = 0
        $scope.fetchSnapshotsData = () => {
            $scope.snapshotPending = true
            angular.forEach($scope.data.objects, obj => {
                if(obj.snapshot) {
                    obj.setSnapshot(null)
                }
            })

            $scope.snapTotalTurnover = 0
            $scope.snapTotalSoldQuantity = 0

            if(uac.user.activeRole.admin || uac.permission('reports.snapshots')) {
                $http.get('api/layout/units/' + $state.params.departmentId + '/' + $scope.snapshotWeeks).then(result => {
                    $scope.snapshots = result.data.units
                    $scope.snapshotsBrands = result.data.brands
                    $scope.snapshotsInfo = result.data.info

                    angular.forEach($scope.snapshots, (item, objectId) => {
                        $scope.snapTotalTurnover     += item.turnover
                        $scope.snapTotalSoldQuantity += item.soldQuantity

                        let object = null
                        angular.forEach($scope.data.objects, obj => {
                            if (parseInt(obj.id) === parseInt(objectId)) {
                                object = obj
                            }
                        })

                        if(object != null) {
                            try {
                                object.setSnapshot(item)
                            } catch(e) {
                                console.log(e, object)
                            }
                        }
                    })

                    $scope.snapTotalTurnover += $scope.snapshotsInfo.foreignFrames.turnover
                    $scope.snapTotalSoldQuantity += $scope.snapshotsInfo.foreignFrames.soldQuantity

                    angular.forEach(['other', 'accessories', 'lenses'], key => {
                        $scope.snapTotalTurnover += $scope.snapshotsInfo.others[key].turnover;
                        $scope.snapTotalSoldQuantity += $scope.snapshotsInfo.others[key].soldQuantity;
                    })

                    $scope.snapshotPending = false
                }, () => {
                    $scope.snapshotPending = false
                })
            }
        }

        $scope.snapOrder = '-snapshot.turnover'
        $scope.snapColumn = 'snapshot.turnover'
        $scope.snapDirection = false

        $scope.snapBrandOrder = '-turnover'
        $scope.snapBrandColumn = 'turnover'
        $scope.snapBrandDirection = false

        $scope.snapType = 'brand'
        $scope.getSnapOrder = type => {
            let direction = type === 'brand' ? 'snapBrandDirection' : 'snapDirection',
                column    = type === 'brand' ? 'snapBrandColumn' : 'snapColumn'

            return ($scope[direction] ? '+' : '-') + $scope[column]
        }

        $scope.setSnapOrder = (order, type) => {
            let direction = type === 'brand' ? 'snapBrandDirection' : 'snapDirection',
                column = type === 'brand' ? 'snapBrandColumn' : 'snapColumn',
                orderField = type === 'brand' ? 'snapBrandOrder' : 'snapOrder'

            if($scope[column] === order) {
                $scope[direction] = !$scope[direction]
            } else {
                $scope[column] = order
            }

            $scope[orderField] = $scope.getSnapOrder(type)
        }
        $scope.getSnapOrdering = (order, type) => {
            let direction = type === 'brand' ? 'snapBrandDirection' : 'snapDirection',
                column = type === 'brand' ? 'snapBrandColumn' : 'snapColumn'

            if(order !== $scope[column]) return ''

            if($scope[direction]) {
                return '▲'
            } else {
                return '▼'
            }
        }

        $scope.setSnapshotWeeks = weeks => {
            $scope.snapshotWeeks = weeks
            $scope.fetchSnapshotsData()
        }

        $scope.getIntOrFloat = number => {
            if(number % 1 === 0) {
                return number
            } else {
                return number.toFixed(1).replace('.', ',')
            }
        }

        $scope.setSnapshotType = function (type) {
            $scope.snapType = type
        }

        $scope.getSnapshotBrandUnits = brand => {
            if(!brand.unitNames) {
                let units = [],
                    str = ' '

                angular.forEach($scope.data.objects, obj => {
                    if(brand.units.indexOf(obj.id) > -1) {
                        units.push(obj)
                        str += obj.name + ' '
                    }
                })

                brand.objects = units
                brand.unitNames = str
            }

            return brand.unitNames
        }

        $scope.setSnapshotBrandHover = (brand, hover) => {
            if (!brand.unitNames) {
                // upewniamy sie, ze mamy znalezione unity
                $scope.getSnapshotBrandUnits(brand)
            }

            angular.forEach(brand.objects, (obj) => {
                obj.setHover(hover)
            })
        }

        $scope.setSnapshotBrandHighlight = brand => {
            if(!brand.unitNames) {
                // upewniamy sie, ze mamy znalezione unity
                $scope.getSnapshotBrandUnits(brand)
            }

            let highlight = undefined
            angular.forEach(brand.objects, (obj) => {
                highlight = obj.setHighlight(highlight)
            })
        }

        $scope.isSnapshotBrandHighlighted = brand => {
            let highlighted = false

            angular.forEach(brand.objects, obj => {
                highlighted = highlighted || obj.isHighlighted()
            })

            return highlighted
        }

        $scope.fetchData = () => {
            $http.get('api/layout/room/' + $state.params.departmentId).then(result => {
                $scope.data = fillData(result.data, $scope.grid)
                $scope.detailsId = result.data.detailsId

                if($state.params.open && !$scope.wasOpened) {
                    let open = $state.params.open
                    $scope.wasOpened = true

                    $timeout(() => {
                        $scope.data.objects.forEach(obj => {
                            if(obj.id === open) {
                                $scope.showObjectContent(obj)
                            }
                        })
                    })
                }

                $scope.fetchSnapshotsData()
            }, error => {
                if(error.status === 403) {
                    $scope.$emit('error', error.data.message)
                }
            })
        }

        $scope.fetchData()

        $scope.saveSVG = () => {
            let svgEl = $('#floor')[0]

            svgExport.save(svgEl)
        }

        $scope.addRoom = type => {
            let conf = assignConfFn({
                type: 'room-' + type,
                name: 'Nowy',
                points: [
                    {x: 10, y: 10},
                    {x: 100, y: 10},
                    {x: 100, y: 100},
                    {x: 10, y: 100}
                ]
            }, 'room')
            let room = new layoutClasses.rooms[conf.type](conf)
            room.setEditable(true)
            $scope.data.rooms.push(room)
        }

        $scope.addObject = type => {
            let name = type.toUpperCase()
            switch (type) {
                case 'mn':
                    type = 'panel'
                    break

                case 'msn':
                    type = 'shelve'
                    break

                case 'mxn':
                    type = 'custom'
                    break

                case 'gn':
                    type = 'glasscase'
                    break

                case 'rn':
                    type = 'advertisement'
                    break
            }

            let conf = assignConfFn({
                type: type,
                x: 10,
                y: 10,
                rotation: 0,
                name: name
            }, 'object')

            let object = new layoutClasses.objects[conf.type](conf)
            object.setEditable(true)
            $scope.data.objects.push(object)
        }

        $scope.selection = null
        $scope.selectionType = className => {
            if(!$scope.selection) return false
            if(!layoutClasses.definitions[className]) return false

            return $scope.selection instanceof layoutClasses.definitions[className]
        }

        $scope.mode = 'view'
        $scope.setMode = mode => {
            $scope.mode = mode
            if ($scope.selection instanceof layoutClasses.definitions.FocusableObject) {
                $scope.selection.setFocus(false)
                $scope.selection = null
            }

            let editable = mode === 'edit'

            for(let i = 0; i < $scope.data.rooms.length; i++)
                $scope.data.rooms[i].setEditable(editable)

            for(let i = 0; i < $scope.data.objects.length; i++)
                $scope.data.objects[i].setEditable(editable)
        }

        $scope.saveChanges = () => {
            $scope.setMode('view')

            $timeout(() => {
                $scope.saveData()
            })
        }

        $scope.discardChanges = () => {
            $scope.fetchData()
            $scope.setMode('view')
        }

        $scope.loadingAdvertisementComplete = 'hide'

        $scope.uploadHost = ENV.uploadHost

        let loadingAdvertisement = status => {
            $timeout(() => {
                $scope.loadingAdvertisementComplete = status
            }, 2000)
        }

        $scope.uploadAdvertisementComplete = () => {
            $timeout(() => {
                $scope.loadingAdvertisementComplete = 'loading'

                $http.post('api/layout/object/upload', {
                    object_id: $scope.selection.id,
                    upload: $scope.upload
                }).then(res => {
                    if(parseInt(res.data) > 0) {
                        loadingAdvertisement('hide')

                        $scope.selection.upload = $scope.upload
                    } else {
                        loadingAdvertisement('error')
                    }
                }, () => {
                    loadingAdvertisement('error')
                })
            }, 0)
        }
    }
])
