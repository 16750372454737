angular.module('eOpti.app.sms.template').service('sms.template.service', ['$resource', '$http', '$q', function ($resource, $http, $q) {
    this.resource = $resource('/api/sms/templates/:templateId', null, {
        update: {
            method: 'PUT'
        }
    });

    this.max = function(body) {
        var allow = 160;

        if(typeof body === 'undefined') {
            return allow;
        }

        if(body.indexOf('{IMIE PACJENTA}') !== -1) {
            allow -= 15;
        }

        return allow;
    };

    this.tagi = function() {
        var defer = $q.defer();

        $http.get('api/sms/template/tagi').then(function(res) {
            defer.resolve(res.data);
        }, function() {
            defer.reject();
        });

        return defer.promise;
    };

    String.prototype.replaceAll = function(search, replacement) {
        var target = this;

        return target.replace(new RegExp(search, 'g'), replacement);
    };

    this.preview = function(body, tagi) {
        if(typeof body === 'undefined' || body === null) {
            body = '';
        }

        if(!Object.keys(tagi).length) {
            return '<b class="text-danger">Trwa ładowanie tagów ...</b>';
        }

        angular.forEach(tagi, function(tag, key) {
            if(body.indexOf(key) !== -1) {
                body = body.replaceAll(key, ('<b>' + tag + '</b>'));
            }
        });

        return body;
    };

    __self = this;
}]);