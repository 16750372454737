angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.training-types', {
            url: '/training-types',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.training-types.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/dictionary/trainingTypesTable.html',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'trainingTypesList',
                    args: [null]
                }]

                $scope.renderers = {
                    comments(value) {
                        return value
                    }
                }
            }]
        })

    $stateProvider
        .state('app.dictionary.training-type', {
            url: '/training-type',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.training-type.add', {
            url: '/add',
            template: '<opti-dictionary-training-type-add></opti-dictionary-training-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'trainingTypesList',
                    args: ['state']
                }, {
                    name: 'dictionary.trainingtype.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.dictionary.training-type.edit', {
            url: '/:id/edit',
            template: '<opti-dictionary-training-type-edit></opti-dictionary-training-type-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'trainingTypesList',
                    args: ['state']
                }, {
                    name: 'dictionary.trainingtype.breadcrumb.TYPE',
                    id: $state.params.id
                }, {
                    name: 'dictionary.trainingtype.breadcrumb.EDIT'
                }]
            }]
        })
}])
