angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.hoya', {
            url: '/hoya',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.hoya.edit', {
            url: '/edit',
            template: '<opti-hoya-options-edit></opti-hoya-options-edit>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    name: 'sidebar.settings.HOYA'
                }]
            }]
        })

}])