angular.module('eOpti.app.register').controller('registerDetailController', [
    '$scope', '$rootScope', '$http', '$state', 'formValidate', 'breadcrumb',
    function ($scope, $rootScope, $http, $state, formValidate, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'register.breadcrumb.REGISTER'
        }, {
            functions: 'register',
            args: ['state', 'register.breadcrumb.CURRENT']
        }, {
            name: 'register.breadcrumb.OPERATION',
            id: $state.params.id
        }]

        let setMode = () => {
            $scope.previous = 'app.register.current'

            if ($rootScope.previousState.name === 'app.register.history') {
                $scope.previous = $rootScope.previousState.name

                breadcrumb.data[2].args[1] = 'register.breadcrumb.HISTORY'
            }
        }

        setMode()

        $scope.goBack = () => {
            $state.go($scope.previous)
        }

        $scope.formValidate = formValidate

        $scope.change = {
            receiptId: 0,
            saldo: 0,
            description: ''
        }

        $scope.loadingReceiptDetail = 'loading'

        $scope.receipt = {
            changes: []
        }

        $http.get('api/register/detail/' + $state.params.id).then(res => {
            $scope.receipt = res.data

            $scope.change.receiptId = $scope.receipt.id

            $scope.loadingReceiptDetail = Object.keys($scope.receipt).length ? 'hide' : 'empty'
        }, () => {
            $scope.loadingReceiptDetail = 'error'
        })

        $scope.addChangeReceipt = () => {
            if($scope.receipt.changes[0].saldo * parseFloat($scope.change.saldo) < 0) {
                $scope.$emit('error', 'register.change.notify.error.SIGN')

                return false
            }

            $scope.loadingReceiptDetail = 'loading'

            $http.post('api/register/change', $scope.change).then(res => {
                if (res.data.status === 'OK') {
                    $scope.$emit('success', 'register.change.notify.SAVE')

                    $scope.goBack()
                } else {
                    $scope.$emit('error', 'register.change.notify.error.SAVE')

                    $scope.loadingReceiptDetail = 'hide'
                }
            }, () => {
                $scope.$emit('error', 'register.change.notify.error.SAVE')

                $scope.loadingReceiptDetail = 'hide'
            })
        }

        $scope.printPayment = id => {
            window.open('api/register/expenses/bank/print/' + id)
        }
    }
])