angular.module('eOpti.app.settings').controller('optometristSettingsController', ['$scope', '$http', 'pathService', function($scope, $http, pathService) {
        $scope.pathSaveDisabled = true

        $scope.availablePaths = [
            { name: 'Visus1', bin: false, multi: false },
            { name: 'CZ1|EE1', bin: false, multi: true },
            { name: 'Clock|Salt0', bin: false, multi: true },
            { name: 'CZ2|EE2', bin: false, multi: true },
            { name: 'OptVisus', bin: false, multi: false },
            { name: 'Salt|SaltVisus', bin: false, multi: true },
            { name: 'CZ3|EE3', bin: false, multi: true },
            { name: 'Visus3', bin: false, multi: false },
            { name: 'binVisus1', bin: true, multi: false },
            { name: 'balance3|balance1|balance2', bin: true, multi: true },
            { name: 'binVisus2', bin: true, multi: false },
            { name: 'binVisus3', bin: true, multi: false }
        ]

        $scope.path = []

        pathService.load('Correction').then(res => {
            pathService.setupFor(res, 'right')

            $scope.availablePaths.forEach(p => {
                let name = p.name

                if(p.multi) {
                    let names = name.split('|')

                    p.dots = []

                    for(let i = 0; i < names.length; i++) {
                        let n = p.bin ? names[i] : 'r' + names[i]

                        p.dots.push(pathService.findPoint(res, n))
                    }

                    p.dot = p.dots[0]
                } else {
                    if(!p.bin) {
                        name = 'r' + name
                    }

                    p.dot = pathService.findPoint(res, name)
                }
            })
        })

        $scope.loadedPath = ''
        $scope.activePath = ''

        $scope.isActive = dot_id => {
            return $scope.activePath.split(',').includes(dot_id)
        }

        $scope.updateActive = (path, dotId) => {
            if($scope.isActive(dotId)) {
                return
            }

            let activeDot = false

            path.dots.forEach(d => {
                if($scope.isActive(d.id)) {
                    activeDot = d
                }
            })

            if(activeDot) {
                let bin = (activeDot.id.charAt(0) !== 'r' && activeDot.id.charAt(0) !== 'l'),
                    from = bin ? activeDot.id : activeDot.id.substr(1),
                    to = bin ? dotId : dotId.substr(1)

                $scope.activePath = $scope.activePath.replace(new RegExp(from, 'g'), to)
            }
        }

        $scope.contactLens = {
            contact_lens_time: false,
            contact_lens_test_schirmer: false,
            contact_lens_surface_on_the_eye: false
        }

        $http.get('api/settings').then(function(res) {
            $scope.loadedPath = res.data.interview_path;
            $scope.activePath = res.data.interview_path;
            $scope.pathSaveDisabled = false;

            $scope.loadedEfron = angular.copy(res.data.efron);
            $scope.efron = res.data.efron;
            $scope.efron_change = false;

            $scope.contactLens.contact_lens_time = res.data.contact_lens_time;
            $scope.contactLens.contact_lens_test_schirmer = res.data.contact_lens_test_schirmer;
            $scope.contactLens.contact_lens_surface_on_the_eye = res.data.contact_lens_surface_on_the_eye;
        }, function() {
            $scope.$emit('error', 'settings.optometrist.notify.error.LOAD');
        })

        $scope.pathSave = function() {
            $http.post('api/settings/path', {
                interview_path: $scope.activePath
            }).then(function(res) {
                $scope.$emit('success', 'settings.optometrist.path.notify.SAVE');
                $scope.loadedPath = res.data.interview_path
            }, function(error) {
                $scope.$emit('error', 'settings.optometrist.path.notify.error.SAVE');
            });
        };

        $scope.efronSave = function() {
            var _efron = [];

            angular.forEach($scope.efron, function(efron) {
                if(efron.checked) {
                    _efron.push(efron.id);
                }
            });

            $http.post('api/settings/efron', {
                efron: _efron.join(',')
            }).then(function(res) {
                $scope.$emit('success', 'settings.optometrist.efron.notify.SAVE');

                $scope.loadedEfron = angular.copy(res.data.efron);
                $scope.efron = res.data.efron;
                $scope.efron_change = false;
            }, function(error) {
                $scope.$emit('error', 'settings.optometrist.efron.notify.error.SAVE');
            });
        };

        $scope.pathReset = function () {
            $scope.activePath = $scope.loadedPath;
        };

        $scope.efronReset = function () {
            $scope.efron = angular.copy($scope.loadedEfron);
        };

        $scope.checkedEfron = function() {
            $scope.efron_change = true;
        };

        $scope.contactLensSave = function() {
            $http.post('api/settings/save/contactLens', $scope.contactLens).then(function(res) {
                $scope.contactLens.contact_lens_time = res.data.contact_lens_time;
                $scope.contactLens.contact_lens_test_schirmer = res.data.contact_lens_test_schirmer;
                $scope.contactLens.contact_lens_surface_on_the_eye = res.data.contact_lens_surface_on_the_eye;

                $scope.$emit('success', 'settings.optometrist.path.notify.SAVECONTACTLENS');
            }, function() {
                $scope.$emit('error', 'settings.optometrist.path.notify.error.SAVECONTACTLENS');
            })
        }
    }
])
