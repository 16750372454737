angular.module('eOpti.app.optometrist').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.optometrists', {
            url: '/optometrists',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.optometrists.list', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'arrangement',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/optometrist/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    functions: 'patientVisit',
                    args: []
                }]
            }]
        })
        .state('app.optometrist', {
            url: '/optometrist',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.optometrist.details', {
            url: '/details/:id',
            templateUrl: 'app/views/optometrist/details.html',
            controller: 'optometristDetailsController',
            params: {
                '#': 'summary'
            }
        })
        .state('app.optometrist.delete', {
            url: '/:id/delete',
            templateUrl: 'app/views/optometrist/delete.html',
            controller: 'optometristDeleteController'
        })
        .state('app.optometrist.create', {
            url: '/:id/create',
            templateUrl: 'app/views/optometrist/create.html',
            controller: 'optometristCreateController'
        })

        .state('app.optometrist.extra', {
            url: '/extra',
            template: '<ui-view/>',
            abstract: true
        })

        .state('app.optometrist.extra.details', {
            url: '/details/:id',
            templateUrl: 'app/views/optometrist/extra.html',
            controller: 'optometristExtraDetailsController',
            params: {
                '#': 'summary'
            }
        })
}])
