angular.module('eOpti.app.register.return.sale').controller('register.return.sale.add', [
    '$scope',
    '$state',
    '$http',
    'formValidate',
    'breadcrumb',
    function ($scope, $state, $http, formValidate, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'register.breadcrumb.REGISTER'
        }, {
            name: 'register.breadcrumb.RETURN'
        }, {
            functions: 'registerReturnSaleList',
            args: ['state']
        }, {
            name: 'register.breadcrumb.ADD'
        }]

        $scope.loading = 'hide'
        $scope.toggled = false

        $scope.sale = {
            items: {},
            search_patient: {}
        }

        $scope.formValidate = formValidate

        let sumReturn = () => {
            let sumValue = 0,
                returned = 0,
                back = 0

            angular.forEach($scope.sale.items, item => {
                sumValue += item.quantity > 0 ? item.valueAfterDiscount : 0
                if(item.return > 0) {
                    returned += item.valueAfterDiscount * (item.return / item.quantity)
                    back += item.return
                }
            })

            $scope.sumValue = sumValue
            $scope.returned = returned
            $scope.back = back
        }

        $scope.$watch('sale.items', () => {
            $scope.toggled = true

            angular.forEach($scope.sale.items, item => {
                if ($scope.allow(item.reclamations) && !item.checked && item.quantity) {
                    $scope.toggled = false
                }

                if(item.checked && !item.return) {
                    item.return = item.quantity
                } else if(!item.checked) {
                    item.return = 0
                }
            })

            sumReturn()
        }, true)

        let isAllChecked = () => {
            let result = true

            if ($scope.sale && $scope.sale.items) {
                angular.forEach($scope.sale.items, function (item) {
                    if($scope.allow(item.reclamations) && !item.checked) {
                        result = false
                    }
                })
            }

            return result
        }

        $scope.$watch('toggled', nVal => {
            if($scope.sale && $scope.sale.items && (nVal || (!nVal && isAllChecked()))) {
                angular.forEach($scope.sale.items, item => {
                    if($scope.allow(item.reclamations)) {
                        item.checked = nVal
                    }
                })
            }
        })

        $scope.getOptions = item => {
            if(!item.options) {
                item.options = []
                for(let i = 0; i <= item.quantity; i++) {
                    item.options.push(i)
                }
            }

            return item.options
        };

        $scope.checkItem = item => {
            item.checked = item.return > 0
        }

        $scope.goReturnSale = () => {
            $scope.loading = 'loading'

            $scope.sale.loyalityId = Object.keys($scope.sale.search_patient).length ? $scope.sale.search_patient.id :
                (Object.keys($scope.sale.patient).length ? $scope.sale.patient.id : 0)

            $http.post('api/register/return/sale/add', $scope.sale).then(() => {
                $state.go('app.register.return.sale.table')

                $scope.$emit('success', 'register.return.sale.notify.SAVESALE')
            }, () => {
                $scope.$emit('success', 'register.return.sale.notify.error.SAVESALE')

                $scope.loading = 'hide'
            })
        }

        $scope.allow = reclamations => {
            let _return = true

            angular.forEach(reclamations, reclamation => {
                if(reclamation.status !== 0 && reclamation.status !== 8) {
                    _return = false

                    return false
                }
            })

            return _return
        }

        $scope.updateSale = sale => {
            $scope.sale = sale
        }
    }
])
