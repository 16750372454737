angular.module('eOpti.app.layout').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.contactLenses', {
                    abstract: true,
                    url: '/contactLenses',
                    template: '<ui-view/>'
                })

                .state('app.contactLenses.list', {
                    templateUrl: 'app/views/layout/contactLenses/list.html',
                    controller: 'contactLensesController',
                    url: '/contactLenses/facing/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                })

                .state('app.contactLenses.edit', {
                    url: '/edit/:id',
                    template: '<opti-layout-contact-lenses-edit></opti-layout-contact-lenses-edit>',
                    controller: [
                        'breadcrumb',
                        function (breadcrumb) {
                            breadcrumb.data = [{
                                name: 'contactLenses.breadcrumb.FACING'
                            }]
                        }
                    ]
                })
        }

    ]
);