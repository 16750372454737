// To run this code, edit file 
// index.html or index.jade and change
// html data-ng-app attribute from
// angle to myAppName
// -----------------------------------

import eOpti from '../eopti.init'

eOpti.constant("DEFAULT-EXPIRES", {
    sek: 1,
    hour: 60 * 60,
    day: 60 * 60 * 24,
    week: 60 * 60 * 24 * 7
})

eOpti.config(['breadcrumbProvider', '$enumProvider', '$storageProvider', 'DEFAULT-EXPIRES',
    function(breadcrumbProvider, $enumProvider, $storageProvider, expire) {

        breadcrumbProvider.setTitle(false)

        $enumProvider.setExpire(expire.day)

        $storageProvider.setLinks({
            countryCode: {
                link: 'api/countries/country-code',
                expire: expire.week
            },
            department: {
                link: 'api/department',
                expire: expire.week
            },
            packIcon: {
                link: 'api/task/pack/group',
                expire: expire.week
            },
            package: {
                link: 'api/task/pack',
                expire: expire.week
            },
            ccTypes: {
                link: 'api/payments',
                expire: expire.week
            },
            interviewPathCorrection: {
                link: 'api/optometrist/interview/path/correction',
                expire: expire.week
            },
            interviewPathAcuityNoCorrection: {
                link: 'api/optometrist/interview/path/acuity-no-correction',
                expire: expire.week
            },
            interviewPathAcuityWithCorrection: {
                link: 'api/optometrist/interview/path/acuity-with-correction',
                expire: expire.week
            },
            interviewPathAcuityContactLens: {
                link: 'api/optometrist/interview/path/acuity-contact-lens',
                expire: expire.week
            },
            interviewPathPrescriptionCheckFar: {
                link: 'api/optometrist/interview/path/prescription-check-far',
                expire: expire.week
            }
        })

        $storageProvider.setExpire(expire.day)

}]).config([
    '$stateProvider',
    '$breadcrumbProvider',
    '$authProvider',
    '$httpProvider',
    '$provide',
    function($stateProvider, $breadcrumbProvider, $authProvider, $httpProvider, $provide) {
        $breadcrumbProvider.setOptions({
            includeAbstract: true
        })

        $authProvider.loginUrl = '/api/user/login'
        $authProvider.logoutRedirect = '/page/login'

        $provide.factory('redirectWhenTokenIsInvalid', [
            '$q',
            '$injector',
            function ($q, $injector) {
                return {
                    responseError: rejection => {
                        let $state = $injector.get('$state'),
                            rejectionReasons = [
                            'token_not_provided',
                            'token_expired',
                            'token_absent',
                            'token_invalid'
                        ]

                        angular.forEach(rejectionReasons, (value, key) => {
                            if(rejection.data && rejection.data.error === value) {
                                $state.go('page.login')
                            }
                        })

                        return $q.reject(rejection)
                    }
                }
            }
        ])

        $httpProvider.interceptors.push('redirectWhenTokenIsInvalid')
    }]
)
