angular.module('eOpti.app.layout').controller('layoutRangesController', [
    '$scope',
    '$http',
    '$localStorage',
    'breadcrumb',
    function ($scope, $http, $localStorage, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: ['state']
        }, {
            functions: 'layoutRanges',
            args: [null]
        }];

        $scope.renderers = {
            grid: (value, row, column) => {
                if (!value) return ''

                let links = []
                value.split(',').forEach((object) => {
                    let link  = object.split('=')
                    let names = link[1].split(':')
                    links.push({
                        id: link[0],
                        name: names[0],
                        column: parseInt(names[1]) + 1,
                        size: link[2],
                        department: link[3]
                    })
                })

                let total = 0
                let html  = ''
                links.forEach(link => {
                    html += `<a class="link" ui-sref="app.layout.grid({ open: ${link.id}, departmentId: ${link.department} })">
                                <div>
                                    <span>${link.name} </span>
                                    <span class="text-sm text-muted" style="font-weight: normal">kolumna ${link.column}</span>
                                    <span class="pull-right" style="width: 2em; text-align: right;">${link.size}</span>
                                    <div class="clearfix"></div>
                                </div>
                             </a>`
                    total += parseInt(link.size)
                })

                if (html && total && links.length > 1) {
                    html += `<div style="border-top: 1px solid black">
                                <span>Razem</span>
                                <span class="pull-right" style="width: 2em; text-align: right;">${total}</span>
                                <div class="clearfix"></div>
                            </div>`
                }

                return html
            },
            details: function (value, row,) {
                if (!value) {
                    return ''
                }

                return '<a ui-sref="app.layout.grid({ open: ' + value + ', departmentId: ' + row.department_id + '})" ' +
                    'title="{{::\'layout.supply.action.DETAILS\'|translate}}"><i class="fa fa-info-circle fa-2x fa-fw"></i></a>';
            }
        }
    }
])
