angular.module('eOpti.app.optometrist').controller('optometristCreateController', [
    '$scope',
    '$state',
    '$http',
    'breadcrumb',
    'dialogs',
    'activity',
    function ($scope,
              $state,
              $http,
              breadcrumb,
              dialogs,
              activity
    ) {
        breadcrumb.data = [{
            functions: 'patientVisit',
            args: ['state']
        }, {
            name: 'optometrist.breadcrumb.CREATEVISIT'
        }]

        let error = function() {
            $scope.$emit('error', 'optometrist.notify.error.CREATE')

            $state.go('app.optometrists.list')
        }

        $http.get('api/optometrist/interview/open-check/' + $state.params.id).then(function(res) {
            if(Object.keys(res.data).indexOf('id') !== -1) {
                if(parseInt(res.data.id) > 0) {
                    $state.go('app.optometrist.details', {id: res.data.id})
                } else {
                    error()
                }
            } else {
                dialogs.confirm('optometrist.action.create.MESSAGE', {
                    className: 'ngdialog-theme-plain',
                    data: {
                        confirm: 'optometrist.action.create.YES',
                        confirmIcon: 'fa fa-fw fa-check',
                        cancel: 'optometrist.action.create.NO'
                    }
                }, 'true').then(function () {
                    $http.get('api/optometrist/interview/generate/' + res.data.patient_id + '/' + $state.params.id).then(function(res2) {
                        $state.go('app.optometrist.details', {id: res2.data.id})

                        activity.increaseForPatient(res.data.patient_id)

                        $scope.$emit('success', 'optometrist.notify.CREATE')
                    }, function() {
                        error()
                    })
                }, function() {
                    $state.go('app.optometrists.list');
                })
            }
        }, function() {
            error()
        })
    }
])