angular.module('eOpti.app.layout').controller('layoutAlertsController', [
    '$scope',
    '$http',
    '$localStorage',
    'breadcrumb',
    function ($scope, $http, $localStorage, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: ['state']
        }, {
            name: 'layout.breadcrumb.ALERTS'
        }]

        $scope.renderers = {
            grid: (value, row, column) => {
                if (!value) return ''

                let links = []
                value.split(',').forEach((object) => {
                    let link  = object.split('=')
                    let names = link[1].split(':')
                    links.push({
                        id: link[0],
                        name: names[0],
                        column: parseInt(names[1]) + 1,
                        size: link[2],
                        department: link[3]
                    })
                })

                let total = 0
                let html  = ''
                links.forEach(link => {
                    html += `<a class="link" ui-sref="app.layout.grid({ open: ${link.id}, departmentId: ${link.department} })">
                                <div>
                                    <span>${link.name} </span>
                                    <span class="text-sm text-muted" style="font-weight: normal">kolumna ${link.column}</span>
                                    <span class="pull-right" style="width: 2em; text-align: right;">${link.size}</span>
                                    <div class="clearfix"></div>
                                </div>
                             </a>`
                    total += parseInt(link.size)
                })

                if (html && total && links.length > 1) {
                    html += `<div style="border-top: 1px solid black">
                                <span>Razem</span>
                                <span class="pull-right" style="width: 2em; text-align: right;">${total}</span>
                                <div class="clearfix"></div>
                            </div>`
                }

                return html
            },
            objectId: (value, row, column) => {
                let departmentId = row.departmentId

                let html = `<div class="btn-group" dropdown="dropdown">`;
                    html += `<button type="button" class="btn btn-primary dropdown-toggle" dropdown-toggle aria-haspopup="true" aria-expanded="false">`;
                        html += `<span class="fa fa-gear"></span>`;
                        html += `<span>&nbsp;</span>`;
                        html += `<span class="caret"></span>`;
                    html += `</button>`;

                    html += `<ul class="dropdown-menu">`;
                        html += `<li>`;
                            html += `<a ui-sref="app.layout.grid({ open: ${value}, departmentId: ${departmentId} })">`;
                                html += `<span class="fa fa-paper-plane-o fa-2x fa-fw"></span>`;
                                html += `<span class="fa-2x">Przejdź</span>`;
                            html += `</a>`;
                        html += `</li>`;

                        html += `<li>`;
                            html += `<a ui-sref="app.layout.range.add({ error_id: ${row.id} })">`;
                                html += `<span class="fa fa-edit fa-2x fa-fw"></span>`;
                                html += `<span class="fa-2x">Skoryguj</span>`;
                            html += `</a>`;
                        html += `</li>`;
                    html += `</ul>`;
                html += `</div>`;

                return html;
            },
            data: function (value, row, column) {
                let val = JSON.parse(value)

                switch (row.type) {

                    case 1:
                        return `<table>
                                    <tr>
                                        <td>Miejsc</td>
                                        <td style="width:30px;text-align: right;font-weight:bold">${val.size}</td>
                                    </tr>
                                    <tr>
                                        <td>Produktów</td>
                                        <td style="width:30px;text-align: right;font-weight:bold">${val.products}</td>
                                    </tr>
                                </table>`

                    case 2:
                        let i        = 0
                        let products = val.categories.map(item => {
                            i++
                            return `<tr>
                                        <td class="text-muted text-right">${i}</td>
                                        <td><a class="link" product="${item.product_id}" product-department-id="${row.departmentId}">${item.product_id}</a></td>
                                        <td class="text-right">${item.position / 10}</td>
                                        <td>${item.name}</td>
                                    </tr>`
                        }).join('')

                        return `<div>
                                    <div>Brak zamówienia, miejsca zajęte innym brandem</div>
                                    <div><b>Facing</b>: ${val.facing}, <opti-depository-info v-props-product="{depository: {stock: ${val.stock}, ordered: ${val.ordered}, expected: ${val.expected}}, quantity: ${val.quantity}}"></opti-depository-info></div>
                                    <table class="table table-striped table-condensed">
                                        <thead>
                                            <tr>
                                                <th class="text-muted text-right">#</th>
                                                <th>Produkt</th>
                                                <th class="text-right">Poz.</th>
                                                <th>Brand</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            ${products}
                                        </tbody>
                                    </table>
                                </div>`

                    case 3:
                        return `<div>
                                    <div><b>Magazyn + Zamówione + Oczekiwane</b> mniejsze niż <b>Facing + dopuszczalny zapas minimalny</b></div>
                                    <div><b>Facing</b>: ${val.facing}, zapas min.: ${val.range.min}, <opti-depository-info v-props-product="{depository: {stock: ${val.stock}, ordered: ${val.ordered}, expected: ${val.expected}}, quantity: ${val.quantity}}"></opti-depository-info></div>
                                </div>`

                    case 4:
                        return `<div>
                                    <div>Za duży magazyn (większy niż facing+max zapas)</div>
                                    <div><b>Facing</b>: ${val.facing}, zapas max.: ${val.range.max}, <opti-depository-info v-props-product="{depository: {stock: ${val.stock}, ordered: ${val.ordered}, expected: ${val.expected}}, quantity: ${val.quantity}}"></opti-depository-info></div>
                                </div>`

                    case 5:
                        return `<div>
                                    <div><b>Kolumna</b>: ${val.column + 1}</div>
                                    <div><b>Ilość</b>: ${val.size}</div>
                                </div>`

                    default:
                        return value

                }
            }
        }
    }
])
