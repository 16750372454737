angular.module('eOpti.app.warehouse.shipments').config(['$stateProvider', function($stateProvider) {
    let generateShipmentStatus = direction => {
        let statuses = ['ready', 'sent', 'received', 'cancelled', 'all']

        statuses.forEach(status => {
            $stateProvider.state('app.warehouse.shipments.' + direction + '.' + status, {
                url: '/' + status + '/:page/:search',
                params: {
                    page: '1',
                    pageSize: '10',
                    pageSizeWarehouse: '20',
                    sortBy: 'id',
                    sortOrder: 'desc',
                    search: {
                        array: true
                    },
                    columns: {
                        array: true
                    }
                },
                templateUrl: 'app/views/warehouse/shipments/table/' + direction + '/' + status + '.html',
                controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                    breadcrumb.data = [{
                        last: 2
                    }, {
                        name: 'shipments.breadcrumb.WAREHOUSE'
                    }, {
                        name: 'shipments.breadcrumb.SHIPMENTS'
                    }, {
                        name: 'shipments.breadcrumb.' + direction.toUpperCase()
                    }, {
                        functions: direction + 'shipments',
                        args: 'shipments.status.' + status.toUpperCase()
                    }]
                }]
            })
        })
    }

    $stateProvider
        .state('app.warehouse.shipments', {
            url: '/shipments',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.shipments.outcoming', {
            url: '/outcoming',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.shipments.incoming', {
            url: '/incoming',
            abstract: true,
            template: '<ui-view/>'
        })

    generateShipmentStatus('outcoming')
    generateShipmentStatus('incoming')

    $stateProvider
        .state('app.warehouse.shipment', {
            url: '/shipment',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.shipment.detail', {
            url: '/{id:[0-9]+}',
            template: '<opti-shipment-details v-on-update-breadcrumb="updateBreadcrumb"></opti-shipment-details>',
            controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'shipments.breadcrumb.WAREHOUSE'
                }, {
                    name: 'shipments.breadcrumb.SHIPMENTS'
                }, {
                    name: ''
                }, {
                    functions: null,
                    args: null
                }, {
                    functions: 'shipment',
                    args: [$state.params.id]
                }]

                $scope.updateBreadcrumb = (name, function_name, status_name) => {
                    breadcrumb.data[3].name = name

                    breadcrumb.data[4].functions = function_name
                    breadcrumb.data[4].args = status_name
                }
            }]
        })
        .state('app.warehouse.shipment.create', {
            'url': '/create',
            template: '<opti-shipment-create></opti-shipment-create>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'shipments.breadcrumb.WAREHOUSE'
                }, {
                    name: 'shipments.breadcrumb.SHIPMENTS'
                }, {
                    name: 'shipments.breadcrumb.OUTCOMING'
                }, {
                    name: 'shipments.breadcrumb.NEW'
                }]
            }]
        })
}])
