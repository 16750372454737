angular.module('eOpti.app.sales').controller('salesListMultilevelController', [
    '$scope',
    '$attrs',
    '$parse',
    '$http',
    function ($scope, $attrs, $parse, $http) {
        $scope.details = [];
        $scope.fetching = true;

        if ($attrs.id) {
            var id = $parse($attrs.id);
            $http
                .get('api/sale/' + id($scope) + '/items')
                .then(function (res) {
                    $scope.details = res.data;
                    $scope.fetching = false;
                })
        }
    }
]);
