angular.module('eOpti.app.settings.general').controller('generalSettingsController', [
    '$scope', '$http', 'dialogs', 'uac', function ($scope, $http, dialogs, uac) {
        $scope.general = {
            departmentId: uac.user.activeDepartmentId,
            lang: uac.user.lang,
            color: uac.user.color,
            dark: !!uac.user.dark
        };

        $scope.setColor = function (color) {
            $scope.general.color = color;
        };

        $scope.langs = [{
            id: 'pl',
            name: 'default.language.list.POLISH'
        }, {
            id: 'en',
            name: 'default.language.list.ENGLISH'
        }];

        $scope.updateDepartment = function(department) {
            $scope.general.departmentId = department.id
        }

        $scope.submit = function() {
            $scope.$emit('pending-settings', true);

            $http.post('api/settings/save', $scope.general).then(function(res) {
                if(res.data.user_id) {
                    $scope.$emit('success', 'settings.general.notify.SAVE');

                    uac.relogin(res.data.user_id);
                } else {
                    $scope.$emit('pending-settings', false);

                    $scope.$emit('error', 'settings.general.notify.error.SAVE');
                }
            }, function() {
                $scope.$emit('pending-settings', false);

                $scope.$emit('error', 'settings.general.notify.error.SAVE');
            });
        };

        $scope.inpostSubmit = function () {
            $scope.$emit('pending-settings', true);

            $http.post('api/settings/saveInPost', { dropOff: $scope.settings.department.inpost_dropoff }).then(function(res) {
                if(res.data.drop_off) {
                    $scope.$emit('success', 'settings.general.notify.SAVE');

                    $scope.settings.department.inpost_dropoff = res.data.drop_off
                } else {
                    $scope.$emit('error', 'settings.general.notify.error.SAVE');
                }
                $scope.$emit('pending-settings', false);
            }, function() {
                $scope.$emit('pending-settings', false);

                $scope.$emit('error', 'settings.general.notify.error.SAVE');
            });
        }

        $scope.openEditAdditionalData = function() {
            dialogs.confirm(null, {
                className: 'ngdialog-theme-plain',
                template: 'app/views/_dialogs/settings/edit-additional-data.html',
                data: {
                    bgz: $scope.settings.department.bgz,
                    payments: $scope.settings.department.payments
                },
                controller: ['$scope', 'formValidate', function(d_scope, formValidate) {
                    d_scope.formValidate = formValidate

                    d_scope.saveAdditionalData = function() {
                        $http.post('api/settings/additional-data/save', {
                            bgz: d_scope.ngDialogData.bgz,
                            payments: d_scope.ngDialogData.payments
                        }).then(function(res) {
                            $scope.settings.department.bgz = res.data.bgz
                            $scope.settings.department.payments = res.data.payments

                            d_scope.$emit('success', 'settings.general.info.notify.SAVE')
                            d_scope.closeThisDialog()
                        }, function() {
                            d_scope.$emit('error', 'settings.general.info.notify.error.SAVE')
                        });
                    }
                }]
            })
        };
    }
]);
