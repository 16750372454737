angular.module('eOpti.app.messages').controller('messages.view', [
    '$scope',
    '$http',
    '$state',
    'breadcrumb',
    function ($scope, $http, $state, breadcrumb) {
        breadcrumb.data = [{
            functions: 'messages',
            args: []
        }, {
            name: 'messages.INBOX'
        }]

        $scope.$watch(() => {
            return $state.current.name
        }, name => {
            let prefix = name.substr(name.lastIndexOf('.') + 1).toUpperCase()

            breadcrumb.data[1].name = 'messages.' + prefix

            if(prefix === 'DETAILS') {
                breadcrumb.data[1].id = $state.params.id
            } else {
                delete breadcrumb.data[1].id
            }
        })

        $scope.info = {
            sth_no_read: false,
            owner: false,
            important: false
        }

        $scope.setActive = name => {
            let getNameStatePrefix = $state.current.name.substr($state.current.name.lastIndexOf('.') + 1)

            if(name === getNameStatePrefix) {
                return true
            } else if(getNameStatePrefix === 'details') {
                if(name === 'unread' && $scope.info.sth_no_read) {
                    return true
                } else if(name === 'starred' && $scope.info.important && !$scope.info.sth_no_read) {
                    return true
                } else if(name === 'sent' && $scope.info.owner && !$scope.info.sth_no_read && !$scope.info.important) {
                    return true
                } else if(name === 'inbox' && !($scope.info.sth_no_read || $scope.info.owner || $scope.info.important)) {
                    return true
                }
            }

            return false
        }

        $scope.badges = null

        $scope.getBadges = () => {
            $http.get('api/room/badges').then(res => {
                $scope.badges = res.data
            }, () => {
                $scope.$emit('error', 'messages.notify.error.BADGES')
            })
        }

        $scope.getBadges()

        let getRenderer = () => {
            return {
                icons: (value, row) => {
                    let _return = ''

                    if(!row.read) {
                        _return += '<span class="fa fa-lg fa-exclamation-circle warn"></span>'
                    }

                    if(row.important) {
                        _return += '<span class="fa fa-lg fa-star text-warning"></span>'
                    }

                    return _return
                },
                message: (value, row) => {
                    let _return = '',
                        object_members = []

                    object_members.push('{department: {name: \'' + row.created_by_name + '\'}}')

                    angular.forEach(row.members.split(', '), name => {
                        object_members.push('{department: {name: \'' + name + '\'}}')
                    })

                    _return += '<a class="hover" ui-sref="app.messages.view.details({id: ' + row.room_id + '})">'
                    _return += '<h4>' + row.topic + '</h4>'
                    _return += '<div class="date text-muted">' + row.date_of_last_message + '</div>'
                    _return += '<div><opti-message-member v-props-members="[' + object_members.join(',') + ']" ' +
                        'v-props-appear="2"></opti-message-member></div>'
                    _return += '<div class="text-message text-muted" ng-bind-html="row.last_content"></div>'
                    _return += '</a>'

                    return _return
                }
            }
        }

        $scope.renderer = getRenderer()
    }
])