angular.module('eOpti.app.tasks').controller('compareIlogTaskController', [
    '$scope', 'breadcrumb', function($scope, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'tasks.breadcrumb.TASKS'
        }, {
            name: 'tasks.admin.compareilog.table.HEADING'
        }]

        $scope.renderers = {
            html_table(value) {
                return `<opti-task-compare-preview v-props-html-table="'{{value}}'"></opti-task-compare-preview>`
            }
        }
    }])
