angular.module('eOpti').config([
    '$provide',
    function ($provide) {
        $provide.decorator('$state', ['$delegate', '$rootScope', function ($delegate, $rootScope) {
            $delegate.previous = function (ifFailGoState, ifFailGoParams, reload) {
                console.log($rootScope.previousState);
                console.log($rootScope.previousParams);

                if($rootScope.previousState) {
                    if(reload) { console.log('its here!');
                        $delegate.go($rootScope.previousState, $rootScope.previousParams, {
                            reload: true
                        });
                    } else {
                        $delegate.go($rootScope.previousState, $rootScope.previousParams);
                    }
                } else if(typeof ifFailGoState != 'undefined') {
                    if(reload) {
                        $delegate.go(ifFailGoState, ifFailGoParams, {
                            reload: true
                        });
                    } else {
                        $delegate.go(ifFailGoState, ifFailGoParams);
                    }
                } else {
                    return false;
                }
            };

            return $delegate;
        }]);
    }
]);
