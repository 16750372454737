angular.module('eOpti.app.warehouse.inventories').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.warehouse.inventories', {
            url: '/inventories',
            abstract: true,
            template: '<ui-view/>'
        })

    $stateProvider
        .state('app.warehouse.inventories.quick', {
            url: '/quick',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.inventories.quick.table', {
            url: '/quick/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl:  'app/views/warehouse/inventories/table-quick.html',
            controller: ['$scope', 'breadcrumb', 'uac', function($scope, breadcrumb, uac) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'inventoryList'
                }, {
                    name: 'inventory.breadcrumb.QUICKS'
                }]

                $scope.uac = uac
            }]
        })
        .state('app.warehouse.inventories.table', {
            url: '/table/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl:  'app/views/warehouse/inventories/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'inventory.breadcrumb.INVENTURIES'
                }]
            }]
        })

    $stateProvider
        .state('app.warehouse.inventory', {
            url: '/inventory',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.inventory.add', {
            url: '/add',
            template: '<opti-inventory-add></opti-inventory-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'inventoryList'
                }, {
                    name: 'inventory.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.warehouse.inventory.details', {
            url: '/details/{id:[0-9]+}',
            template: '<opti-inventory-details></opti-inventory-details>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'inventoryList'
                }, {
                    name: 'inventory.breadcrumb.INVENTORY',
                    id: $state.params.id
                }]
            }]
        })
        .state('app.warehouse.inventory.tasks-in-progress', {
            url: '/tasks-in-progress',
            template: '<opti-inventory-tasks-in-progress></opti-inventory-tasks-in-progress>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'inventoryList'
                }, {
                    name: 'inventory.breadcrumb.TASKSINPROGRESS'
                }]
            }]
        })

        .state('app.warehouse.inventory.quick', {
            url: '/quick',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.warehouse.inventory.quick.add', {
            url: '/add',
            template: '<opti-inventory-quick-add></opti-inventory-quick-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'inventoryList'
                }, {
                    defaults: 'inventoryQuickList'
                }, {
                    name: 'inventory.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.warehouse.inventory.quick.details', {
            url: '/details/{id:[0-9]+}',
            template: '<opti-inventory-quick-details></opti-inventory-quick-details>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'inventoryList'
                }, {
                    defaults: 'inventoryQuickList'
                }, {
                    functions: 'inventoryQuick',
                    args: [$state.params.id]
                }]
            }]
        })
}])
