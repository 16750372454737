angular.module('eOpti.app.optometrist').controller('optometristPrismController', [
    '$scope',
    function ($scope) {
        $scope.egzoforia = [{id: 0, name: '-'}];

        for(var i = 2; i <= 30; i+=2) {
            $scope.egzoforia.push({
                id: i,
                name: i
            });
        }

        $scope.angle_adaptation = [{id: 0, name: '-'}];

        for(var i = 2; i <= 20; i+=2) {
            $scope.angle_adaptation.push({
                id: i,
                name: i
            });
        }

        for(var i = 25; i <= 40; i+=5) {
            $scope.angle_adaptation.push({
                id: i,
                name: i
            });
        }

        $scope.time_adaptation = [{id: 0, name: '-'}, {id: 5, name: 5}, {id: 10, name: 10}, {id: 15, name: 15},
            {id: 20, name: 20}, {id: 30, name: 30}];
    }
]);