angular.module('eOpti.app.sms.template').controller('smsCampaignAddController', [
    '$scope', '$http', '$state', '$localStorage', '$filter', 'breadcrumb', 'formValidate',
    function ($scope, $http, $state, $localStorage, $filter, breadcrumb, formValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            defaults: 'settingsList'
        }, {
            name: 'sms.template.breadcrumb.SMS'
        }, {
            functions: 'smsCampaignList',
            args: ['state']
        }, {
            name: 'sms.campaign.breadcrumb.ADD'
        }];

        $scope.formValidate = formValidate;

        $scope.campaign = {
            description: '',
            body: '',
            filtrs: []
        };

        if(typeof $localStorage['sms-campaign'] !== 'undefined') {
            $scope.campaign = $localStorage['sms-campaign'];
        }

        $scope.$watch('campaign', function(nVal, oVal) {
            if(!angular.equals(nVal, oVal)) {
                $localStorage['sms-campaign'] = $scope.campaign;
            }
        }, true);

        $scope.loading = 'hide';

        $scope.addCampaign = function () {
            $scope.loading = 'loading';

            $scope.campaign.filtrs = $localStorage['app.sms.campaign.add-search'];

            $http.post('api/sms/campaign', $scope.campaign).then(function() {
                $localStorage['sms-campaign'] = {
                    description: '',
                    body: '',
                    filtrs: []
                };

                $state.go('app.sms.campaign.list');

                $scope.$emit('success', 'sms.campaign.notify.SAVE');
            }, function() {
                $scope.loading = 'error';

                $scope.$emit('error', 'sms.campaign.notify.error.SAVE');
            });
        };

    }]);
