angular.module('eOpti.app.settings').config(
    [
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('app.dictionary.regenerationtypes', {
                    url: '/regeneration-types',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.dictionary.regenerationtypes.table', {
                    url: '/:page/:search',
                    params: {
                        page: '1',
                        pageSize: '10',
                        sortBy: 'id',
                        sortOrder: 'desc',
                        search: {
                            array: true
                        },
                        columns: {
                            array: true
                        }
                    },
                    templateUrl: 'app/views/dictionary/regenerationtype/table.html',
                    controller: [
                        'breadcrumb',
                        function (breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                name: 'settings.breadcrumb.DICTIONARY'
                            },
                                {
                                name: 'dictionary.regenerationtype.breadcrumb.TYPE'
                            }];
                        }
                    ]
                });

            $stateProvider
                .state('app.dictionary.regenerationtype', {
                    url: '/regeneration-type',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('app.dictionary.regenerationtype.add', {
                    url: '/add',
                    template: '<opti-dictionary-regeneration-type-add></opti-dictionary-regeneration-type-add>',
                    controller: [
                        'breadcrumb',
                        function (breadcrumb) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                name: 'settings.breadcrumb.DICTIONARY'
                            },
                                {
                                defaults: 'regenerationTypeList'
                            }, {
                                name: 'dictionary.regenerationtype.breadcrumb.ADD'
                            }];
                        }
                    ]
                })
                .state('app.dictionary.regenerationtype.edit', {
                    url: '/:id/edit',
                    template: '<opti-dictionary-regeneration-type-edit></opti-dictionary-regeneration-type-edit>',
                    controller: [
                        'breadcrumb', '$state',
                        function (breadcrumb, $state) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                name: 'settings.breadcrumb.DICTIONARY'
                            },
                                {
                                defaults: 'regenerationTypeList'
                            }, {
                                functions: 'regenerationTypeList',
                                args: [$state.params.id]
                            }];
                        }
                    ]
                })
                .state('app.dictionary.regenerationtype.delete', {
                    url: '/:id/delete',
                    template: '<opti-dictionary-regeneration-type-delete></opti-dictionary-regeneration-type-delete>',
                    controller: [
                        'breadcrumb', '$state',
                        function (breadcrumb, $state, ) {
                            breadcrumb.data = [{
                                defaults: 'settingsList'
                            }, {
                                name: 'settings.breadcrumb.DICTIONARY'
                            },
                                {
                                defaults: 'regenerationTypeList'
                            }, {
                                functions: 'regenerationTypeList',
                                args: [$state.params.id]
                            }, {
                                name: 'dictionary.regenerationtype.breadcrumb.DELETE'
                            }];
                        }
                    ]
                })
        }
    ]
);
