angular.module('eOpti.app.download').controller('download.edit', [
    '$scope', '$http', '$state', 'breadcrumb', 'formValidate', 'ENV', 'download.service',
    function($scope, $http, $state, breadcrumb, formValidate, ENV, download) {
        breadcrumb.data = [{
            functions: 'downloadList',
            args: ['state']
        }, {
            name: 'download.breadcrumb.FILE',
            id: $state.params.id
        }, {
            name: 'download.breadcrumb.EDIT'
        }]

        $scope.formValidate = formValidate

        $scope.uploadHost = ENV.uploadHost

        $scope.form = {
            id: 0,
            name: '',
            link: '',
            upload: ''
        }

        $scope.type = 0

        download.resource.get({downloadId: $state.params.id}, res => {
            $scope.form.id = res.id
            $scope.form.name = res.name

            if(res.uri.includes('/')) {
                $scope.form.link = res.uri
                $scope.type = 0
            } else {
                $scope.form.upload = 'download/' + res.uri
                $scope.type = 1
            }

            $scope.loading = 'hide'
        }, () => {
            $scope.loading = 'error'
        })

        $scope.$watch('type', (newVal, oldVal) => {
            if(newVal != oldVal) {
                if(newVal) {
                    $scope.form.link = ''
                } else {
                    $scope.form.upload = null
                }
            }
        })

        $scope.editFile = () => {
            download.resource.update($scope.form, () => {
                $scope.$emit('success', 'download.notify.EDIT')

                $state.go('app.downloads.table')
            }, () => {
                $scope.$emit('error', 'download.notify.error.EDIT')
            })
        }
    }])