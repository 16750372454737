angular.module('eOpti.app.users.trainings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.users.trainings', {
            url: '/trainings',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.users.trainings.list', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/users/trainings/table.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'usersTrainingsSchedule',
                    args: ['state']
                }, {
                    name: 'user.training.breadcrumb.LIST'
                }]
            }]
        })
        .state('app.users.trainings.schedule', {
            url: '/schedule/:department_id/:view/:date',
            params: {
                department_id: '0',
                view: 'month',
                date: moment().format('YYYY-MM-DD')
            },
            template: '<opti-users-training-schedule></opti-users-training-schedule>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'usersTrainingsSchedule',
                    args: [null]
                }]
            }]
        })

        .state('app.user.training', {
            url: '/training',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.user.training.add', {
            url: '/add',
            template: '<opti-users-training-add></opti-users-training-add>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'usersTrainingsSchedule',
                    args: ['state']
                }, {
                    name: 'user.training.breadcrumb.ADD'
                }]
            }]
        })
        .state('app.user.training.details', {
            url: '/:id',
            template: '<opti-users-training-details></opti-users-training-details>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'usersTrainingsSchedule',
                    args: ['state']
                }, {
                    functions: 'usersTrainingsDetails',
                    args: [null, $state.params.id]
                }]
            }]
        })
        .state('app.user.training.edit', {
            url: '/:id/edit',
            template: '<opti-users-training-edit></opti-users-training-edit>',
            controller: ['breadcrumb', '$state', function(breadcrumb, $state) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    defaults: 'settingsList'
                }, {
                    defaults: 'usersList'
                }, {
                    functions: 'usersTrainingsSchedule',
                    args: ['state']
                }, {
                    functions: 'usersTrainingsDetails',
                    args: ['state', $state.params.id]
                }, {
                    name: 'user.training.breadcrumb.EDIT'
                }]
            }]
        })
}])
