angular.module('eOpti.app.settings').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.dictionary.seasons', {
            url: '/seasons',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.seasons.table', {
            url: '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            templateUrl: 'app/views/dictionary/seasonsTable.html',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'seasonList',
                    args: [null]
                }]
            }]
        })

    $stateProvider
        .state('app.dictionary.season', {
            url: '/season',
            template: '<ui-view/>',
            abstract: true
        })
        .state('app.dictionary.season.edit', {
            url: '/:id/edit',
            template: '<opti-dictionary-seasons-edit></opti-dictionary-seasons-edit>',
            controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
                breadcrumb.data = [{
                    defaults: 'settingsList'
                }, {
                    name: 'settings.breadcrumb.DICTIONARY'
                }, {
                    functions: 'seasonList',
                    args: ['state']
                }, {
                    name: 'dictionary.seasons.breadcrumb.SEASON',
                    id: $state.params.id
                }, {
                    name: 'dictionary.seasons.breadcrumb.EDIT'
                }]
            }]
        })
}])
