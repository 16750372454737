angular.module('eOpti.app.register.return.sale').controller('register.return.sale.table', [
    '$scope',
    'breadcrumb',
    function ($scope, breadcrumb) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'register.breadcrumb.REGISTER'
        }, {
            name: 'register.breadcrumb.RETURN'
        }, {
            name: 'register.breadcrumb.SALE'
        }];

    }
]);
