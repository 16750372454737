angular.module('eOpti.app.sales').controller('saleOffstockDetailsController', [
    '$scope', '$http', '$state', 'breadcrumb', 'receiptNoticeService',
    function ($scope, $http, $state, breadcrumb, receiptNotice) {
        breadcrumb.data = [{
            defaults: 'salesOffstockList'
        }, {
            name: 'sale.offstock.breadcrumb.SALE',
            id: $state.params.id
        }];

        $http.get('api/sale/offstock/' + $state.params.id).then(function(res) {
            $scope.saleoffstock = res.data;

            receiptNotice.get('saleoffstock', $state.params.id).then(function(res) {
                $scope.notice = res;
            });

            if($scope.saleoffstock.price * $scope.saleoffstock.quantity != 0) {
                $scope.saleoffstock.percentDiscount =
                    Math.ceil(($scope.saleoffstock.quantity * $scope.saleoffstock.price - $scope.saleoffstock.valueAfterDiscount) /
                        ($scope.saleoffstock.price * $scope.saleoffstock.quantity) * 10000) / 100;
            } else {
                $scope.saleoffstock.percentDiscount = 0;
            }

            $scope.loadingSaleoffstock = Object.keys($scope.saleoffstock).length ? 'hide' : 'empty';
        }, function(error) {
            $scope.loadingSaleoffstock = 'error';
        });
    }
]);