angular.module('eOpti.app.tests').controller('testsResultDetailsController', [
    '$scope', '$http', '$state', 'breadcrumb',
    function ($scope, $http, $state, breadcrumb) {
        breadcrumb.data = [{
            name: 'default.breadcrumb.OTHERS'
        }, {
            defaults: 'testsList'
        }, {
            defaults: 'testResultsList'
        }, {
            name: 'test.breadcrumb.RESULT',
            id: $state.params.test_result_id
        }];

        $scope.max = 0;
        $scope.result = {};
        $scope.test = {};

        $http.get('api/tests/answers/' + $state.params.test_result_id).then(function(res) {
            $scope.result = res.data;
            $scope.test = $scope.result.test;

            $scope.loading = Object.keys($scope.result).length ? 'hide' : 'empty';
        }, function(error) {
            $scope.loading = 'error';
        });

        $scope.getClass = function(q, reply_number) {
            var result = false;

            angular.forEach($scope.result.answers, function (answer) {
                if (answer.test_question_copy_id == q.id && answer.answer == reply_number) {
                    result = q.answer == answer.answer ? 'bg-correct' : 'bg-incorrect';
                }
            });

            return result;
        };
    }
]);