angular.module('eOpti.app.settings').controller('patientsSettingsController', [
    '$scope', function ($scope) {
        $scope.periods = ['1', '2', '3'];

        $scope.translatePeriod = function(time, period) {
            switch(parseInt(period)) {
                case 1:
                    return time == 1 ? 'default.day.ONE' : 'default.day.MORE';
                    break;
                case 2:
                    if(time == 1) {
                        return 'default.week.ONE';
                    } else if(2 <= time && time < 5) {
                        return 'default.week.MIDDLE';
                    } else {
                        return 'default.week.MORE';
                    }
                    break;
                case 3:
                    if(time == 1) {
                        return 'default.month.ONE';
                    } else if(2 <= time && time < 5) {
                        return 'default.month.MIDDLE';
                    } else {
                        return 'default.month.MORE';
                    }
            }
        };

        $scope.removePeriod = function(key) {
            $scope.settings.lastActivity.splice(key, 1);
        };

        $scope.addPeriod = function() {
            $scope.settings.lastActivity.push({time: '', period: ''});
        };
    }
]);