angular.module('eOpti.validate').directive('pending', [function () {
    return {
        restrict: 'A',
        scope: true,
        compile: function (elem) {
            var append = '<div class="line-scale-party pending" ng-show="pending">',
                line = '<div></div>';

            for(var i = 0; i< 5; ++i) {
                append += line;
            }

            append += '<span>{{"default.PENDING"|translate}}</span>';

            append += '</div>';

            elem.append(append);
        },
        controller: ['$scope', function ($scope) {
            $scope.pending = false;

            this.show = function () {
                $scope.pending = true;
            };

            this.hide = function() {
                $scope.pending = false;
            };
        }]
    }
}]);