angular.module('eOpti.app.bookkeeping').controller('bookkeepingTerminalsController', [
    '$scope', 'breadcrumb',
    function ($scope, breadcrumb) {
        breadcrumb.data = [{
            name: 'bookkeeping.breadcrumb.REGISTER'
        }, {
            name: 'bookkeeping.breadcrumb.BOOKKEEPING'
        }, {
            name: 'bookkeeping.breadcrumb.TERMINALS'
        }];

    }]);
