angular.module('eOpti.app.shipments').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.shipment', {
            url: '/shipment',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('app.shipment.create', {
            'url': '/create',
            template: '<opti-shipping-create v-on-update-breadcrumb="updateBreadcrumb"></opti-shipping-create>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'shipments.breadcrumb.WAREHOUSE'
                }, {
                    name: 'shipments.breadcrumb.SHIPMENTS'
                }]

                $scope.updateBreadcrumb = b => {
                    b.forEach(obj => {
                        breadcrumb.data.push(obj)
                    })
                }
            }]
        })
        .state('app.shipment.edit', {
            'url': '/{shipment_id}/edit',
            template: '<opti-shipping-create v-on-update-breadcrumb="updateBreadcrumb"></opti-shipping-create>',
            controller: ['$scope', 'breadcrumb', function($scope, breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'shipments.breadcrumb.WAREHOUSE'
                }, {
                    name: 'shipments.breadcrumb.SHIPMENTS'
                }]

                $scope.updateBreadcrumb = b => {
                    b.forEach(obj => {
                        breadcrumb.data.push(obj)
                    })
                }
            }]
        })
        .state('app.shipment.link', {
            url: '/waybill/:tour_id',
            controller: ['$state', function($state) {
                if ($state.params.tour_id.indexOf('Z') !== -1) {
                    window.open('https://www.ups.com/track?loc=pl_PL&tracknum=' + $state.params.tour_id, '_blank')
                } else {
                    window.open(`https://inpost.pl/sledzenie-przesylek?number=${$state.params.tour_id}`, '_blank')
                }

                $state.previous()
            }]
        })
}])
