angular.module('eOpti.app.register.return.sale').controller('register.return.sale.details', [
    '$scope',
    '$state',
    '$http',
    'breadcrumb',
    'receiptNoticeService',
    function ($scope, $state, $http, breadcrumb, receiptNotice) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'register.breadcrumb.REGISTER'
        }, {
            name: 'register.breadcrumb.RETURN'
        }, {
            functions: 'registerReturnSaleList',
            args: ['state']
        }, {
            name: 'sale.breadcrumb.SALE',
            id: $state.params.id
        }]

        $scope.sum = items => {
            let price = quantity = value = discount = discountPercent = valueAfterDiscount = 0

            angular.forEach(items, item => {
                price += parseFloat(item.price)
                quantity += parseInt(item.quantity)
                value += parseFloat(item.value)
                discount += parseFloat(item.discount)
                discountPercent += parseFloat(item.discountPercent)
                valueAfterDiscount += parseFloat(item.valueAfterDiscount)
            })

            return {
                price: Math.round(price * 100) / 100,
                quantity: quantity,
                value: Math.round(value * 100) / 100,
                discount: Math.round(discount * 100) / 100,
                discountPercent: Math.round((!value ? 0 : discount * 100 / value) * 100) / 100,
                valueAfterDiscount: Math.round(valueAfterDiscount * 100) / 100
            }
        }

        $http.get('api/register/return/sale/' + $state.params.id + '/minus').then(res => {
            $scope.sale = res.data

            angular.forEach($scope.sale.items, item => {
                item.quantity = item.quantity * -1
                item.value = item.value * -1
                item.valueAfterDiscount = item.valueAfterDiscount * -1
            })

            receiptNotice.get('salereturn', $state.params.id).then(res => {
                $scope.noticeSalereturn = res
            })

            $scope.loadingSale = Object.keys($scope.sale).length ? 'hide' : 'empty'
        }, () => {
            $scope.loadingSale = 'error'
        })

        $scope.goToPrint = () => {
            window.open('api/register/return/sale/print/' + $scope.sale.id)
        }

        $scope.updateNotes = notes => {
            $scope.sale.notes = notes
        }
    }
])