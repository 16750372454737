angular.module('eOpti.app.settings').controller('detailSettingsController', [
    '$scope', '$http', '$location', 'breadcrumb', 'settings', 'uac', function ($scope, $http, $location, breadcrumb, settings, uac) {
        breadcrumb.data = [{
            last: 2
        }, {
            name: 'settings.breadcrumb.SETTINGS'
        }, {
            name: 'settings.card.GENERAL'
        }];

        $scope.tab = {
            general: 'settings.card.GENERAL',
            cashRegister: 'settings.card.CASHREGISTER',
            patients: 'settings.card.PATIENTS',
            optometrist: 'settings.card.OPTOMETRIST',
            social: 'settings.card.SOCIAL',
            scheduleTask: 'settings.card.SCHEDULEDEPARTMENT'
        };

        $scope.uac = uac;

        $scope.cardActive = [];

        $scope.pendingSettings = false;

        $scope.$on('pending-settings', function(evt, data) {
            $scope.pendingSettings = data;
        });

        $scope.setActive = function (hash) {
            $scope.activeTab = $scope.tab[hash];

            angular.forEach($scope.tab, function (value, key) {
                $scope.cardActive[key] = false;
            });

            $scope.cardActive[hash] = true;

            breadcrumb.changeLast({name: $scope.activeTab});

            $location.hash(hash);
        };

        if($location.hash()) {
            $scope.setActive($location.hash());
        } else {
            $scope.setActive('general');
        }

        $scope.settings = settings.data;

        $scope.save = function () {
            return $http.post('api/settings/patients/period/save', $scope.settings);
        };
    }
]);
