angular.module('eOpti.app.optometrist').controller('optometristDetailsController', [
    '$scope',
    '$rootScope',
    '$http',
    '$state',
    '$location',
    '$enum',
    'breadcrumb',
    'formValidate',
    'dialogs',
    'ageGroup',
    'uac',
    function ($scope, $rootScope, $http, $state, $location, $enum, breadcrumb, formValidate, dialogs, ageGroup, uac) {
        breadcrumb.data = [{
            functions: 'patientName',
            args: []
        }, {
            functions: 'interview',
            args: []
        }, {
            name: 'optometrist.breadcrumb.section.SUMMARY'
        }]

        $scope.formValidate = formValidate

        let prefixTabLang = 'optometrist.breadcrumb.section.'

        $scope.tab = {
            summary: prefixTabLang + 'SUMMARY',
            previousglasses: prefixTabLang + 'PREVIOUSGLASSES',
            interview: prefixTabLang + 'INTERVIEW',
            questionnaire: prefixTabLang + 'QUESTIONNAIRE',
            refraction: prefixTabLang + 'REFRACTION',
            optometrist: prefixTabLang + 'OPTOMETRIST',
            lens: prefixTabLang + 'LENS',
            history: prefixTabLang + 'HISTORY',
            notes: prefixTabLang + 'NOTES'
        }

        $scope.cardActive = []

        $scope.setActive = hash => {
            $scope.activeTab = $scope.tab[hash]

            angular.forEach($scope.tab, (value, key) => {
                $scope.cardActive[key] = false
            })

            $scope.cardActive[hash] = true

            breadcrumb.changeLast({name: $scope.activeTab})

            $location.hash(hash)
        }

        if($location.hash()) {
            $scope.setActive($location.hash())
        } else {
            $scope.setActive('summary')
        }

        $rootScope.$emit('dialog-close')

        $scope.points = {
            covd: 0,
            osdi: 0,
            osdi_matching: []
        }

        $scope.active_cylinder = 'minus'

        $scope.active_cylinder_contactlens = 'minus'

        $scope.correction = {
            plus: {
                dist: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                near: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            },
            minus: {
                dist: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                near: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            },
            matches: {
                dist: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                near: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            },
            contactlens: {
                plus: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                },
                minus: {
                    r: {sph: 0, cyl: 0, axs: 'brak'},
                    l: {sph: 0, cyl: 0, axs: 'brak'}
                }
            }
        }

        $scope.visus_values = ['-', '0,01', '0,1', '0,2', '0,3', '0,4', '0,5', '0,6', '0,7', '0,8', '0,9', '1,0', '1,2', '1,5', '2,0']

        $scope.notes = []

        $http.get('api/optometrist/interview/' + $state.params.id).then(res => {
            $scope.interview = res.data
            window.interview = $scope.interview

            $http.get('api/patient/previouslenses/all/fromPatientId/' + $scope.interview.patient.id).then(res => {
                $scope.previouslenses = res.data.previouslenses
                $scope.contactlenses = res.data.contactlenses

                $scope.loading = 'hide'

                init()
            }, () => {
                $scope.loading = 'error'
            })

            $http.get('api/optometrist/interview/' + $scope.interview.id + '/notes').then(res => {
                $scope.notes = res.data
            }, () => {
                $scope.loading = 'error'
            })
        }, () => {
            $scope.loading = 'error'
        })

        $scope.task_checked = {}

        let init = () => {
            window.interview = $scope.interview

            breadcrumb.data[0].args = [
                'state',
                $scope.interview.patient.firstName + ' ' + $scope.interview.patient.lastName,
                $scope.interview.patient.id
            ]

            breadcrumb.data[1].args = [
                null,
                $scope.interview.id,
                $scope.interview.previous_interview_id
            ]

            $scope.block = () => {
                return [0, 2, 3].includes($scope.interview.status) || !uac.permission('optometrist.edit')
            }

            $scope.block2 = () => {
                return [0, 2, 3].includes($scope.interview.status)
            }

            $scope.block3 = () => { // przy odsyłaniu specjalisty
                return [0, 3].includes($scope.interview.status) || !uac.permission('optometrist.edit')
            }

            $scope.blockSurvey = () => {
                return !!($scope.block() || $scope.interview.autorefraktometr.r_r_es === -999 || $scope.interview.autorefraktometr.r_l_es === -999 ||
                    ($scope.interview.autorefraktometr.r_r_cyl && $scope.interview.autorefraktometr.r_r_cyl !== -999 && $scope.interview.autorefraktometr.r_r_axs === 'brak') ||
                    ($scope.interview.autorefraktometr.r_l_cyl && $scope.interview.autorefraktometr.r_l_cyl !== -999 && $scope.interview.autorefraktometr.r_l_axs === 'brak'))
            }

            let points = 0,
                checked = true

            $scope.interview.questionnaire.forEach(questionnaire => {
                if (questionnaire.pivot.option !== -1) {
                    points += questionnaire.pivot.option
                } else {
                    checked = false
                }
            })

            $scope.points.covd = checked ? points : -1

            $scope.$watch('interview.contactLens.questionnaire', questionnaires => {
                let points = 0,
                    checked = true

                angular.forEach(questionnaires, questionnaire => {
                    if (questionnaire.pivot.option !== -1) {
                        points += questionnaire.pivot.option
                    } else {
                        checked = false
                    }
                })

                $scope.points.osdi = checked ? points * 2.083333 : -1
            }, true)

            $scope.$watch('interview.patient.birthdate', birthdate => {
                $scope.age = ageGroup.calculate(birthdate).age
            })

            $scope.$watch('interview.diseases', diseases => {
                angular.forEach(diseases, disease => {
                    if(!disease.pivot.checked) {
                        disease.pivot.description = ''
                    }
                })
            }, true)

            angular.forEach($scope.interview.patient.tasksDone, task => {
                $scope.task_checked[task.id] = false
            })

            angular.forEach($scope.previouslenses, previousLens => {
                if(previousLens.task_id > 0) {
                    $scope.task_checked[previousLens.task_id] = true
                }
            })

            $scope.previous = {}

            if($scope.interview.previous_interview_id) {
                angular.forEach($scope.interview.previous, previous => {
                    if($scope.interview.previous_interview_id === previous.id) {
                        $scope.previous = previous
                    }
                })
            }
        }

        $scope.mainSavePending = false

        $scope.$watch('interview._autosave._state', state => {
            if(state === 'error') {
                $scope.$emit('error', 'optometrist.notify.error.SAVE')
                $state.reload()
            }

            $scope.mainSavePending = state !== 'init'
        })

        $scope.edit = () => {
            return $http.put('api/optometrists/edit', $scope.interview)
        }

        $scope.results = {
            maddox: {
                r: {
                    prism: 0,
                    angle: 0
                },
                l: {
                    prism: 0,
                    angle: 0
                },
                selected: []
            }
        }

        $scope.previouslens = {
            checked_task: {},
            dist: false,
            near: false,
            dist_cant_read_powers: false,
            allowSave: true,
            contactlens: false
        }

        $scope.updateNotes = notes => {
            $scope.notes = notes
        }

        $scope.updateCyl = c => {
            $scope.active_cylinder = c
        }

        $scope.updateCylContactLens = c => {
            $scope.active_cylinder_contactlens = c
        }

        $scope.updateInterview = interview => {
            $scope.interview = interview
        }

        $scope.updatePoints = points => {
            $scope.points = points
        }
    }
])