angular.module('eOpti.app.layout').controller('layoutRequestController', [
    '$scope', '$http', '$state', '$filter', 'breadcrumb', 'formValidate',
    function($scope, $http, $state, $filter, breadcrumb, formValidate) {
        breadcrumb.data = [{
            last: 2
        }, {
            functions: 'layoutList',
            args: ['state']
        }, {
            functions: 'layoutDetails',
            args: [$state.params.layout_id, null, 'state']
        }, {
            name: 'layout.breadcrumb.REQUESTS',
            id: null
        }]

        $scope.formValidate = formValidate

        $scope.loading = 'loading'
        $scope.item = null

        $scope.request = {
            layout_item_id: parseInt($state.params.id),
            change: 0
        }

        $http.get('api/layout/item/' + $state.params.id).then(res => {
            $scope.item = res.data
            breadcrumb.data[2].args[1] = $scope.item.layout.department.login
            breadcrumb.data[3].id = $scope.item.category_name + ' (' + $filter('translate')($scope.item.product_type_name) + ')'

            $scope.loading = 'hide'
        }, () => {
            $scope.loading = 'error'
        })

        $scope.addRequest = () => {
            $scope.loading = 'loading'

            $http.post('api/layout/item/request/add', $scope.request).then(res => {
                $scope.item = res.data

                $scope.showRequest = false
                $scope.request.change = 0
                $scope.$emit('success', 'layout.request.notify.SAVE')
                $scope.loading = 'hide'
            }, () => {
                $scope.$emit('error', 'layout.request.notify.error.SAVE')
                $scope.loading = 'error'
            })
        }

        $scope.editRequest = () => {
            $scope.loading = 'loading'

            $http.post('api/layout/item/request/edit', {
                layout_item_id: $state.params.id,
                change: $scope.item.requests[0].change
            }).then(res => {
                $scope.item = res.data

                $scope.$emit('success', 'layout.request.notify.EDIT')
                $scope.loading = 'hide'
            }, () => {
                $scope.$emit('error', 'layout.request.notify.error.EDIT')
                $scope.loading = 'error'
            })
        }

        $scope.adminRequest = status => {
            $scope.loading = 'loading'

            $http.post('api/layout/item/request/admin', {
                layout_item_id: $state.params.id,
                change: $scope.item.requests[0].change,
                status: status
            }).then(res => {
                $scope.item = res.data

                $scope.$emit('success', 'layout.request.notify.ADMIN')
                $scope.loading = 'hide'
            }, () => {
                $scope.$emit('error', 'layout.request.notify.error.ADMIN')
                $scope.loading = 'error'
            })
        }
}])